import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["filterWrapper", "channelSelectWrapper"];

  channelChanged(e) {
    const value = e.currentTarget.value;
    if (_.isEmpty(value)) {
      // hide the filter
      this.filterWrapperTarget.classList.add("hidden");
      this.channelSelectWrapperTarget.classList.add("no-margin-bottom");
    } else {
      // show the filter
      this.filterWrapperTarget.classList.remove("hidden");
      this.channelSelectWrapperTarget.classList.remove("no-margin-bottom");
    }
  }
}
