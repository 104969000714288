import $ from "jquery";
import Clipboard from "clipboard";
import Handlebars from "handlebars";

class App.Views.UploadsView extends Backbone.View
  bytesToSize = (bytes, precision) ->
    kilobyte = 1024;
    megabyte = kilobyte * 1024;
    gigabyte = megabyte * 1024;
    terabyte = gigabyte * 1024;

    if ((bytes >= 0) && (bytes < kilobyte))
      return bytes + ' B';
    else if ((bytes >= kilobyte) && (bytes < megabyte))
      return (bytes / kilobyte).toFixed(precision) + ' KB';
    else if ((bytes >= megabyte) && (bytes < gigabyte))
      return (bytes / megabyte).toFixed(precision) + ' MB';
    else if ((bytes >= gigabyte) && (bytes < terabyte))
      return (bytes / gigabyte).toFixed(precision) + ' GB';
    else if (bytes >= terabyte)
      return (bytes / terabyte).toFixed(precision) + ' TB';
    else
      return bytes + ' B';

  toHHMMSS = (seconds) ->
    sec_num = parseInt(seconds, 10)

    hours = Math.floor(sec_num / 3600)
    minutes = Math.floor((sec_num - (hours * 3600)) / 60)
    seconds = sec_num - (hours * 3600) - (minutes * 60)

    components = []
    if hours > 0
      components.push("#{hours}h")
    if hours > 0 || minutes > 0
      components.push("#{minutes}m")
    if hours == 0
      components.push("#{seconds}s")
    
    components.join(' ')
    
  initialize: (options) ->
    this.setupUploaders()
    this.setupClipboardCopy()
    this.$el = options.el
    this.$el.data('view', this)

  events:
    "click #uploads .no-content .button": "uploadFirst"
  
  uploadFirst: ->
    $('#fileupload').click()

  setupClipboardCopy: ->
    if $('.copy-link').length > 0
      clipboard = new Clipboard(".copy-link");
      clipboard.on('success', (e) ->
        UI.showFlash($(e.trigger).data('message'))
      )

  setupUploaders: ->
    $(document).on 'click', ".copy-link", (e) ->
      e.preventDefault()

    media_upload_template = Handlebars.compile($('#new-upload-template').html())

    if $('body.media #fileupload').length > 0
      el = $('body.media #fileupload')
      view = this

      tearDown = ->
        if el.data('blueimpFileupload')?
          el.fileupload('destroy')
        document.removeEventListener("turbolinks:before-cache", tearDown)
      document.addEventListener("turbolinks:before-cache", tearDown)

      el.fileupload(
        dataType: 'xml'
        multipart: false
        sequentialUploads: true
        processData: false,
        limitConcurrentUploads: 1
        dropzone: $('#main')
        type: 'PUT'

        add: (e, data) ->
          that = $(this).data('fileupload');
          file = data.files[0]

          tmpl = $(media_upload_template({ upload: {name: file.name, size: bytesToSize(file.size, 1) }}))
          tmpl.css('display','none')
          tmpl.addClass('uploading')
          tmpl.find('.progress div').progressbar()

          if $('.no-content').length > 0
            $('.no-content').remove()
            $('.file-list-wrapper').show()

          data.context = tmpl
            .insertAfter($('.file-list .legend')).fadeIn('slow', (e) ->
              # Fix for IE7 and lower:
              $(this).show();
            ).data('data', data)

          prefix = new Date().getTime().toString()
          filename = prefix + '/' + file.name

          sign_uploads_url = el.data('sign-uploads-url')

          $.ajax(
            type: 'POST'
            url: sign_uploads_url
            data: { filename: filename, content_type: file.type }
            success: (response) ->
              data.filename = response.filename
              data.url = response.url
              data.formData = response.formData
              data.headers = response.headers
              data.submit()
          )

        progress: (e, data) ->
          if (data.context)
            data.context.find('.ui-progressbar').progressbar(
              'value',
              parseInt(data.loaded / data.total * 97, 10) # compensate for margin so make it not grow to 100%
            )
            if data.bitrate > 0
              secondsRemaining = (data.total - data.loaded) * 8 / data.bitrate;
              data.context.find('.progress-label').html(toHHMMSS(secondsRemaining) + " remaining")

        send: (e, data) ->
          if (data.context && data.dataType &&
                data.dataType.substr(0, 6) == 'iframe')
            # Iframe Transport does not support progress events.
            # In lack of an indeterminate progress bar, we set
            # the progress to 100%, showing the full animated bar:
            data.context.find('.ui-progressbar').progressbar(
              'value',
              parseInt(97, 10)
            )

        always: (e, data) ->
          # let's create this thing in the database
          $.ajax(
            type: 'POST'
            url: el.data('callback-url')
            data: { s3_upload_key: data.filename }
            success: (response) ->
              context = data.context
              context.removeClass('uploading')
              context.find('.delete a').attr('href',response[0].delete_url)
              context.find('.name a').attr('href',response[0].delete_url)
              context.find('.link a').attr('href',response[0].url)
              context.find('.link a').attr('data-clipboard-text',response[0].url)
              context.find('.link a').data('clipboard-text',response[0].url)
              context.find('.make-shared a').attr('href', response[0].make_shared_url)
              context.find('.ui-progressbar').progressbar(
                  'value',
                  parseInt(97, 10)
                ).fadeOut()
              context.find('.progress-label').fadeOut()
            dataType: "JSON"
          )

          view.setupClipboardCopy()
        )
