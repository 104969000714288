import $ from "jquery";

class App.Views.SetupAppsView extends Backbone.View      
  initialize: ->
    @$("input:file, input:checkbox, input:radio").each (el) ->
      parent = $(this).closest('div')
      if not (parent.hasClass('selector') || parent.hasClass('checker'))
        $(this).uniform()

  events:
    "click *[data-toggle]"         : "toggleBox"
        
  toggleBox: (e) ->        
    el = $(e.currentTarget)
    $($(el).data('toggle')).toggle()
