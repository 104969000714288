import $ from "jquery";
import _ from "underscore";

class App.Views.ShowChannelView extends Backbone.View
  initialize: ->
    _.bindAll(this, 'clickedLinkInRow', 'clickedRow', 'switchColumns');

  events:
    "click td a:not([data-confirm])" : "clickedLinkInRow"
    "click td" : "clickedRow"
    "click .select-columns a.column-selector" : "switchColumns"

  clickedRow: (e) ->
    target = $(e.target)
    if !target.is('a') && !target.is('i')
      path = target.closest('tr').data('show-path')
      Turbolinks.visit(path)

  clickedLinkInRow: (e) ->
    e.stopPropagation()

  switchColumns: (e) -> 
    target = $(e.currentTarget)
    count = @$("th:not(.invisible-column):not(.select-column-header)").length

    if target.hasClass('visible-field')
      if count > 4 # minimum of 4 visible columns      
        target.removeClass('visible-field')
        target.addClass('invisible-field')
        # remove the column from the view
        field_id = target.data('field-id')

        @$("th[data-field-id=#{field_id}]").addClass('invisible-column')
        @$("td[data-field-id=#{field_id}]").addClass('invisible-column')
    else
      target.removeClass('invisible-field')
      target.addClass('visible-field')
      # add the column from the view
      field_id = target.data('field-id')

      @$("th[data-field-id=#{field_id}]").removeClass('invisible-column')
      @$("td[data-field-id=#{field_id}]").removeClass('invisible-column')

    #recount the number of shown fields
    count = @$("th:not(.invisible-column):not(.select-column-header)").length
    @$("th:not(.invisible-column):not(.select-column-header)").css("width", 100.0 / count + '%')
    ids = _.map(@$("th:not(.invisible-column):not(.select-column-header)"), (el)-> $(el).data('field-id'))
    url = target.closest('table').data('preferences-path')
    $.post(url, { columns: ids } );

    e.preventDefault()
    e.stopPropagation() 
