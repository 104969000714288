import $ from "jquery";
import _ from "underscore";
import Handlebars from "handlebars";

class App.Views.ProductVariantView extends Backbone.View
  initialize: (options) ->
    @options = options
    _.bindAll(this, "showVariantSettingsModal", "destroyVariant");
    UI.tooltips()

  events:
    "click .remove > a"                 : "destroyVariant"
    "click .edit-variant-link"         : "showVariantSettingsModal"

  showVariantSettingsModal: ->
    inverse = (trueFalse) ->
      if trueFalse == "false"
        "true"
      else
        "false"

    template = Handlebars.compile($('#edit-variant-settings-template').html());
    element = $(@el)

    product_shipping   = ($('#product_requires_shipping').val() == "true")
    product_taxes      = ($('#product_charge_taxes').val() == "true")
    product_tax_scheme = $('#product_tax_scheme_id').val()

    variant_name = element.find('.input.label input').val()
    variant_name = if !variant_name? || variant_name == ""
      element.data('default-name')
    else
       new Handlebars.SafeString("<strong>#{variant_name}</strong>")

    variant_id          = element.data('variant-id')
    variant_taxes       = inverse(element.find('input.no-taxes').val())
    variant_shipping    = inverse(element.find('input.no-shipping').val())
    variant_custom_rate = element.find('input.custom-tax-rate').val()
    variant_tax_scheme  = element.find('input.tax-scheme-id').val()

    if variant_custom_rate == "false"
      variant_tax_scheme = product_tax_scheme

    else if product_taxes && variant_taxes == "false"
      variant_custom_rate = "true"
      variant_tax_scheme = "no-taxes"

    data = {
      id:               variant_id,
      name:             variant_name
      no_taxes:         variant_taxes
      no_shipping:      variant_shipping
      custom_tax_rate:  variant_custom_rate
      show_tax_rates:   variant_custom_rate == "true"
      tax_scheme_id:    variant_tax_scheme
      product_shipping: product_shipping
      product_taxes:    product_taxes
    }
    code = template(data)

    callback = (modal) ->
      new App.Views.EditProductVariantSettingsView(
        el: $(modal)
        data: data
      )

    UI.showModal(code, callback)

  destroyVariant: (e) ->
    $(this.el).addClass('hidden')
    link = e.target
    $(link).prev().find("input[type=hidden]").val("1")
    $(link).closest(".condition").hide()
    @options.parent.hideOrShowProductVariantDestroyButtons()
