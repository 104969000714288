/* ============================================================
 * bootstrap-dropdown.js v2.0.0
 * http://twitter.github.com/bootstrap/javascript.html#dropdowns
 * ============================================================
 * Copyright 2012 Twitter, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ============================================================ */

!(function ($) {
  "use strict";

  /* DROPDOWN CLASS DEFINITION
   * ========================= */

  var toggle = '[data-toggle="dropdown"]',
    Dropdown = function (element) {
      var $el = $(element).on("click.dropdown.data-api", this.toggle);
      $("html").on("click.dropdown.data-api", function () {
        $el.parent().removeClass("open");
      });
    };

  Dropdown.prototype = {
    constructor: Dropdown,

    toggle: function (e) {
      var $this = $(this),
        selector = $this.attr("data-target"),
        $parent,
        isActive;

      if (!selector) {
        selector = $this.attr("href");
        selector = selector && selector.replace(/.*(?=#[^\s]*$)/, ""); //strip for ie7
      }

      if (selector === "#") {
        $parent = $this.parent();
      } else {
        $parent = $(selector);
      }

      $parent.length || ($parent = $this.parent());

      isActive = $parent.hasClass("open");

      clearMenus();
      !isActive && $parent.toggleClass("open");

      return false;
    },
  };

  function clearMenus() {
    $(toggle).parent().removeClass("open");
  }

  /* DROPDOWN PLUGIN DEFINITION
   * ========================== */

  $.fn.dropdown = function (option) {
    return this.each(function () {
      var $this = $(this),
        data = $this.data("dropdown");
      if (!data) $this.data("dropdown", (data = new Dropdown(this)));
      if (typeof option == "string") data[option].call($this);
    });
  };

  $.fn.dropdown.Constructor = Dropdown;

  /* APPLY TO STANDARD DROPDOWN ELEMENTS
   * =================================== */

  $(function () {
    $("html").on("click.dropdown.data-api", clearMenus);
    $("html").on("click.dropdown.data-api", toggle, Dropdown.prototype.toggle);
  });
})(jQuery);
