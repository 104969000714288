import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import Clipboard from "clipboard";

export default class extends Controller {
  connect() {
    const copyToken = $(this.element).find(".copy-token");
    if (copyToken.length > 0) {
      const clipboard = new Clipboard(copyToken[0]);
      clipboard.on("success", (e) => UI.showFlash($(e.trigger).data("message")));

      copyToken.on("click", (e) => e.preventDefault());
    }
  }
}
