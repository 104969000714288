import $ from "jquery";

class App.Views.EditShippingRateView extends Backbone.View
  initialize: ->
    # store the original values:
    @view = $(@el)

  events:
    "change #shipping_rate_criteria"   : "changedShippingRateCriteria"
    "change #shipping_rate_restricted" : "changedRestricted"

  changedShippingRateCriteria: (e) ->
    val = @$("#shipping_rate_criteria").val()
    if val == "weight" || val == "variable"
      @$(".input.shipping_rate_order_min").hide()
      @$(".input.shipping_rate_weight_min").show()
    else
      @$(".input.shipping_rate_order_min").show()
      @$(".input.shipping_rate_weight_min").hide()

  changedRestricted: (e) ->
    @$('.zipcode-selection-subsection').toggle()
    UI.resizeModal()

    if @$("#shipping_rate_restricted").val() == "true"
      @$("#shipping_rate_zipcodes").focus()
