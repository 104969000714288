import $ from "jquery";

class App.Views.EditCustomFieldSelectItemView extends Backbone.View
  events:
    "click .add-category"         : "addCategory"
    "click .destroy-category > a"     : "destroyCategory"

  addCategory: (e) ->
    debug.log "addCategory"

  destroyCategory: (e) ->
    debug.log "removeCategory"
    link = e.currentTarget
    $(link).prev().val("1")
    $(link).closest(".category-field").hide()
