import $ from "jquery";
import _ from "underscore";
import insert from "underscore.string/insert";
import endsWith from "underscore.string/endsWith";
import startsWith from "underscore.string/startsWith";
import ltrim from "underscore.string/ltrim";

class App.Views.EditProductAttachmentView extends Backbone.View      
  initialize: (options) ->
    _.bindAll(this, "changeVariantSpecifics", "submitForm");
    @data = options.data

  events:
    "submit form"       : "submitForm"
    "change input#attachment_variant_specific" : "changeVariantSpecifics"

  changeVariantSpecifics: ->
    @$('.variant-specifics-subsection').toggle()

    UI.resetModal()

  submitForm: (e) ->
    e.preventDefault()
    selectize_api = @$('#variant-specifics-selector')[0].selectize
    variants_specifics_enabled = $('#attachment_variant_specific').val() == "true"
    variant_ids = @$('#variant-specifics-selector').val()

    if variants_specifics_enabled && _.isEmpty(variant_ids)
      return false

    variant_names = _.map(variant_ids, (item) -> selectize_api.options[item].text).join(', ')

    # get the value of the form
    new_path = @$('#attachment_path').val()
    attachment_id = $(@el).find('form').data('attachment-id')

    # slugify the path (but with underscore instead of a dash) and chop first slash
    new_path = UI.slugify_with_slashes(new_path, {
        separator: "_"
        preserveCase: true
    })
    new_path = insert(new_path, new_path.length, "/") unless endsWith(new_path, "/")
    new_path = insert(new_path, 0, "/") unless startsWith(new_path, "/")

    # trim the first slash away for the UI
    new_path_for_link = ltrim(new_path,"/")

    # store it in the real product form
    $("##{attachment_id}").find('.change-path-input').val(new_path)
    $("##{attachment_id}").data('attachment-path',new_path)
    $("##{attachment_id}").find('span.directory').html(new_path_for_link)
    if variants_specifics_enabled
      $("##{attachment_id}").find('.variant-specific-input').val(variant_ids)
      $("##{attachment_id}").find('.variant-specific-info').html(
        @$('.variant_specific_translation').html().replace('__VARIANT_NAMES__', variant_names)
      )
    else
      $("##{attachment_id}").find('.variant-specific-input').val("")
      $("##{attachment_id}").find('.variant-specific-info').html("")

    # close the modal window
    UI.closeModal()
