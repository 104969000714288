import $ from "jquery";
import _ from "underscore";
import { setupPhoneInput } from "../../utils";

class App.Views.CustomerEditAddressView extends Backbone.View
  initialize: ->
    window.Nimbu.inlineInputs()
    _.bindAll(this, "changeDefaults", "setDefaults");

    setupPhoneInput(@$('#address_phone'))
  
  events:
    "change #defaults_selector": "changeDefaults"

  changeDefaults: (e) ->
    selected = @$('#defaults_selector option:selected').val()
    switch selected
      when "billing_and_shipping" then this.setDefaults(true, true)
      when "shipping" then this.setDefaults(true, false)
      when "billing" then this.setDefaults(false, true)
      else this.setDefaults(false, false)

  setDefaults: (shipping, billing) ->
    @$('#address_default_shipping').attr("value", shipping)
    @$('#address_default_billing').attr("value", billing)
