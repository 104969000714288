import $ from "jquery";
import _ from "underscore";
import moment from "moment-timezone";
import escapeRegExp from "lodash/escapeRegExp";
import Autogrow from "./vendor/textarea-autogrow.js";
import accounting from "accounting";

window.js ||= {}
window.UI ||= {}

window.debug =
  log: (message) =>
    console.log(message) if window.console? && console.log?

window.App =
  Models: {}
  Collections: {}
  Controllers: {}
  Views: {}

window.Nimbu =
  Models: {}
  Collections: {}
  Views: {}
  Routers: {}

  initialize: (data) ->
    debug.log "Oh! You like to look under the hood? Why not help us build the engine? https://www.zenjoy.be/jobs"

    $(document).data('original-body', document.body.cloneNode(true))
    this.el = $('#main');
    this.setupEventListeners()
    app = this

    $ ->
      app.onDocumentReady()
  
  setupEventListeners: () ->
    app = this
    $(document)
      .on('turbolinks:request-start', ->
        $('#loading').fadeIn()
        $("#loading").spin({ lines: 13, length: 3, width: 2, radius: 3, hwaccel: true, color: '#afb1b6' })
        $('#spinner').fadeIn('fast')
        $("#spinner .wheel").spin({ lines: 14, length: 5, width: 2, radius: 5, hwaccel: true, color: '#7c8088' })
      ).on('turbolinks:load', ->
        if $('html').data("pusher")
          app.setLiveIndicator()

        app.uniformInputs()
        app.inlineInputs()
        app.setupUI()
        app.setupViews()
        app.setupRedactor()
        app.setupShortcuts()
        app.hideSpinners()

        UI.fixSelectizePlaceholders()
      ).on('turbolinks:render', (e) ->
        app.uniformInputs()
      ).on('turbolinks:before-cache', ->
        app.cleanup()
      ).ajaxStart( ->
        $('#loading').fadeIn()
        $("#loading").spin({ lines: 13, length: 3, width: 2, radius: 3, hwaccel: true, color: '#afb1b6' })
      ).ajaxStop( ->
        $('#loading').fadeOut( ->
          $("#loading").spin(false)
        )
      )

  onDocumentReady: () ->
    # Mage datefields easy selectable
    $(document).on('click', ".submit-form", (e) ->
      $(this).closest('form').submit()
    )

    this.setUpScrolling()
    this.enablePush()

    if (navigator.userAgent.toLowerCase().indexOf("chrome") >= 0) || (navigator.userAgent.toLowerCase().indexOf("safari") >= 0)
      $("input:-webkit-autofill").each ->
        text = $(this).val()
        name = $(this).attr("name")
        $(this).after(@outerHTML).remove()
        $("input[name=\"#{name}\"]").val(text)
        $("input[name=\"#{name}\"]").closest('div.input').addClass('hastext').addClass('prefilled')

  hideSpinners: ->
    $('#loading').hide()
    $("#loading").spin(false)
    $('#spinner').hide()
    $("#spinner .wheel").spin(false)

  cleanup: ->
    this.hideSpinners()
    UI.restoreTooltipTitles()
    Mousetrap.reset()

    if $('textarea.wysiwyg').length > 0
      UI.destroyRedactor()

    $('select').each((i, el) ->
      $el = $(el)
      if $el.length > 0 && $el[0].selectize?
        $el[0].selectize.destroy()
    )

    $(".check_element").each( ->
      $(this).removeClass('.initialized')
    )

  enablePush: ->
    app = this
    pusher_token = $('meta[name="pusher-key"]').attr('content');
    pusher_site_id = $('meta[name="pusher-channel"]').attr('content');
    if pusher_token?
      pusher = new Pusher(pusher_token, { encrypted: true, authEndpoint: '/admin/pusher/auth' })
      pusher.connection.bind('connected', ->
        $('html').data("pusher",true)
        app.setLiveIndicator()
      )

      channel = pusher.subscribe(pusher_site_id)
      channel.bind('alert', (data) ->
        alert(data.message)
      )

      channel.bind('hit', (data) ->
        app.showHit()
        app.updateStats(data) if $('body.dashboard').length > 0
      )
      @rt =
        pusher: pusher
        channel: channel

  getPusher: ->
    if @rt?
      @rt
    else
      this.enablePush()

  setLiveIndicator: ->
    $('.status-indicator').addClass('live')
    $('.status-indicator').attr('title','You are seeing live updates!')

  showHit: ->
    $('.status-indicator').addClass('hit').delay(100).queue( (next) ->
      $(this).removeClass("hit")
      next()
    )
    true

  increaseStat: (type) ->
    selector = "a.kpi[data-kpi=\"#{type}\"] .number"
    el = $(selector)
    if el.length > 0
      number = parseInt(el.html())
      el.html(number + 1)

  updateStats: (data) ->
    this.increaseStat('pageviews')
    this.increaseStat('visits') if data.new_session
    this.increaseStat('new')    if data.first_visit

  setupRedactor: ->
    UI.setupRedactor()

  setupUI: ->
    UI.fixSelectizePlaceholders()
    UI.activateTabs()
    UI.enableSpinners()
    UI.enableImagePreviews()

    $('#force_primary_admin_form').on('change', 'input', (e) ->
      $(this).closest('form').submit()
    )

    $('#sidebar ul > li:not(.inactive)').click (e) ->
      $('#sidebar ul > li').removeClass("active")
      $(this).addClass("active")

    $(window).resize( (e) ->
      # Do we have a modal window open?
      if $('.active-modal').length > 0
        UI.resizeModal()
    )
    $('a[data-show-loader="true"]').on 'click', (e) ->
      timeout = $(e.currentTarget).data('loader-timeout')
      UI.showPreloader(timeout)

    $(".alert-box").delegate "a.close", "click", (event) ->
      event.preventDefault()
      $(this).closest(".alert-box").fadeOut (event) ->
        $(this).remove()

    UI.showFlash()
    UI.tooltips()
    UI.autoGrowTextareas()
  
  setupShortcuts: ->
    if $('body[data-staffmode-capable]').length > 0
      Mousetrap.bind ["command+shift+s", "ctrl+shift+s", "d e b u g enter"], (e) ->
        $.cookie('staffmode', 'true', { path: '/' })
        Turbolinks.visit(window.location.href)
        
    $('[data-keyboard-shortcut]').each(() ->
      $el = $(this)
      shortcuts = $el.data('keyboard-shortcut').split(',')
      for shortcut in shortcuts
        Mousetrap.bind(shortcut, (e) ->
          $el[0].click()
        )
    )
    $('table.widescreen-table').each(() ->
      $table = $(this)
      Mousetrap.bind('j', (e) ->
        # go down  
        $selected = $table.find('tr.jk-selected').first()
        if $selected.length == 0
          $selected = $table.find('td').first().closest('tr')
          $selected.addClass('jk-selected')
        else
          $next = $selected.next()
          if ($next.length > 0)
            $selected.removeClass('jk-selected')
            $next.addClass('jk-selected')
      )
      Mousetrap.bind('k', (e) ->
        # go up
        $selected = $table.find('tr.jk-selected').first()
        if $selected.length == 0
          $selected = $table.find('td').last().closest('tr')
          $selected.addClass('jk-selected')
        else
          $prev = $selected.prev()
          if ($prev.length > 0 && $prev.find('th').length == 0)
            $selected.removeClass('jk-selected')
            $prev.addClass('jk-selected')
      )
      Mousetrap.bind('enter', (e) ->
        # open selected item
        $selected = $table.find('tr.jk-selected')
        if $selected.length > 0
          dataLink = $selected.data('show-path')
          if dataLink?
            Turbolinks.visit(dataLink)
          else
            $link = $selected.find('a').first()
            if $link.length > 0
              $link[0].click()
      )
      Mousetrap.bind('esc', (e) ->
        # open selected item
        $selected = $table.find('tr.jk-selected')
        if $selected.length > 0
          $selected.removeClass('jk-selected')
      )
    )

  setupViews: ->
    $("body.themes, body.layouts, body.assets, body.templates, body.snippets").each ->
      new App.Views.EditThemeView({el: $(this)})

    $("body.products-edit").each ->
      new App.Views.EditProductView({el: $(this)})

    $("section.search").each ->
      new App.Views.SearchListView({el: $(this)})

    $("body.collections-edit").each ->
      new App.Views.EditCollectionView({el: $(this)})

    $("body.coupons-edit").each ->
      new App.Views.CouponsEditView({el: $(this)})

    $("body.pages-index").each ->
      new App.Views.PagesIndexView({el: $(this)})

    $("body.pages-edit").each ->
      new App.Views.EditPageView({el: $(this)})

    $("body.site-dashboard").each ->
      new App.Views.DashboardView({el: $(this)})

    $("body.collections").each ->
      new App.Views.CollectionView({el: $(this)})

    $(".app-setup").each ->
      new App.Views.SetupAppsView({el: $(this)})

    $("body.links form.menu").each ->
      new App.Views.EditMenuView({el: $(this)})

    $(".customer_lists, .checkout_profiles, .products-data-model, .customers-data-model, .blog-edit").each ->
      new App.Views.EditCustomFieldsView({el: $(this)})

    $(".channels, .channel").each ->
      new App.Views.EditChannelView({el: $(this)})

    $(".customizable").each ->
      new App.Views.CustomizableView({el: $(this)})

    $("body.media").each ->
      new App.Views.UploadsView({el: $(this)})

    $("body.orders-show").each ->
      new App.Views.ShowOrderView({el: $(this)})
    
    $("body.subscriptions-show").each ->
      new App.Views.ShowSubscriptionView({el: $(this)})

    $('.channel-entries').each ->
      new App.Views.ShowChannelView({el: $(this)})

    $("body.notifications-index").each ->
      new App.Views.NotificationsView({el: $(this)})

    $("body.domains-index").each ->
      new App.Views.DomainsView({el: $(this)})

    $("body.videos-index").each ->
      new App.Views.VideosIndexView({el: $(this)})

    $("body.settings-shop").each ->
      new App.Views.ShopSettingsView({el: $(this)})

    $("body.settings-general").each ->
      new App.Views.GeneralSettingsView({el: $(this)})

    $("body.settings-user").each ->
      new App.Views.UserSettingsView({el: $(this)})

    $("body.settings-checkout").each ->
      new App.Views.PaymentMethodsView({el: $(this)})

    $("body.settings-retail").each ->
      new App.Views.PaymentMethodsView({el: $(this)})

    $("body.settings-integrations").each ->
      new App.Views.IntegrationsView({el: $(this)})

    $("body.taxes").each ->
      new App.Views.TaxSettingsView({el: $(this)})

    $("body.shipping").each ->
      new App.Views.ShippingSettingsView({el: $(this)})

    $("body.oauth-applications-index").each ->
      new App.Views.AppsIndexView({el: $(this)})

    $("body.articles-edit").each ->
      new App.Views.EditArticleView({el: $(this)})

    $("body.exception-tester-index").each ->
      new App.Views.ExceptionTesterView({el: $(this)})

    $("#custom-field-authenticatable").each ->
      new App.Views.EditCustomFieldAuthenticatableView({ el: $(this) })

    $("body.orders-new").each ->
      new App.Views.BackendOrderView({el: $(this)})

    scrollToError = ->
      offset = $('.input.error').first().offset().top
      if offset > 400
        $("html, body").animate({ scrollTop: offset - 300 }, 1500, 'easeOutExpo');

    if $('.input.error').length > 0
      setTimeout((-> scrollToError()),250)

  setUpScrolling: ->
    setTimeout((-> windowDidScroll()),500)
    setTimeout((-> windowDidScroll()),1000)
    _window = $(window)
    _isScrolling = false
    _animating = false
    _scrollEndTimeout = null
    _loadWhileScrolling = true
    _slideUpTimeout = null
    _beginShadow = 3
    _endShadow = 35

    windowDidScroll = ->
      scrollTop = $(window).scrollTop()

      # regular headers
      if scrollTop > 20
        $('.page-header').addClass('scrolling')
      else
        $('.page-header').removeClass('scrolling')
      if scrollTop > 0
        if scrollTop > _endShadow
          $('.page-header').setBoxShadow('0 0 8px rgba(0,0,0,0.5)')
        else
          value = (1-(_endShadow - scrollTop) / _endShadow) * 8
          $('.page-header').setBoxShadow("0 0 #{value}px rgba(0,0,0,0.5)")
      else
        $('.page-header').setBoxShadow('0 0 0px rgba(0,0,0,0.5)')

      # remove fadeout on bottom if fully scrolled
      # if(scrollTop + $(window).height() >= $(document).height())
      #   unless _animating
      #     _animating = true
      #     $("#fadeout").stop(true,false).fadeOut('2000', ->
      #       _animating = false
      #     )
      # else
      #   unless _animating
      #     _animating = true
      #     $("#fadeout").stop(true,false).fadeIn('2000', ->
      #       _animating = false
      #     )

    _window.on "scroll", windowDidScroll

  formatPriceField: (target) ->
    if !_.isNaN(parseFloat(target.val()))
      value = String(target.val()).replace(/[^\d.,]/, "")
      result = accounting.formatMoney(value,"$",2,"",".","%v")
      target.val(result)
    else
      if target.hasClass('optional')
        target.val('')
      else
        result = accounting.formatMoney(0,"$",2,"",".","%v")
        target.val(result)

  uniformInputs: ->
    UI.selectize($("div:not('.template') select.selectizable:not(.selectized)"))
    UI.selectizeWithCreate($("div:not('.template') select.selectizable-with-create:not(.selectized)"))
    UI.selectizeWithSearch($("div:not('.template') select.selectizable-with-search:not(.selectized)"))

    # First enable all check_element
    UI.enableCheckedElement()

    # Uniform all form inputs
    $("div:not('.template') input:file:not('.inline, .styled, .uniformed'), div:not('.template') input:checkbox:not('.switch'), div:not('.template') input:radio").each (el) ->
      parent = $(this).closest('div')
      if not (parent.hasClass('selector') || parent.hasClass('checker'))
        options = {}
        if $(this).data('cache-filename')
          options.fileDefaultHtml = $(this).data('cache-filename')
        $(this).uniform(options)
        $(this).addClass('uniformed')

    $('.only-floats').each () ->
      new Cleave($(this)[0], {
        numeral: true,
        numeralPositiveOnly: true,
        numeralThousandsGroupStyle: 'none'
      })

    $('.only-integers').numeric({ negative: false, decimal : false })
    $('.only-signed-floats').each () ->
      new Cleave($(this)[0], {
        numeral: true,
        numeralThousandsGroupStyle: 'none'
      })
    $('.only-signed-integers').numeric({ negative: true, decimal : false })

    if $('input.price-textfield').length > 0
      $(document).on "blur", 'input.price-textfield', ->
        Nimbu.formatPriceField($(this))


    # Mage datefields easy selectable
    $("input.date-field").each (e) ->
      $(this).datepicker(
        showAnim: 'fadeIn',
        dateFormat: 'dd-mm-yy',
        firstDay: 1
      )

    $('.trigger-olark-chat').click (e) ->
      olark('api.box.expand')
      e.preventDefault()
      return false

    # Mage datefields easy selectable
    $("input.time-field").each (e) ->
      $(this).timeEntry(
        show24Hours: true
        separator: ':'
        spinnerImage: false
      )

    $("input.date-field.date-time-partial, input.time-field.date-time-partial").change (e) ->
      wrapper = $(this).parents('.date-time')
      date = wrapper.find("input.date-field")
      time = wrapper.find("input.time-field")
      timezone = $(body).data("timezone")
      datetime = wrapper.find('input[type="hidden"].date-time-field')
      date_obj = date.datepicker("getDate")
      time_obj = time.timeEntry("getTime")
      time_obj_or_now = time_obj || new Date()
      datetime_obj = moment(date_obj || new Date()).hours(time_obj_or_now.getHours()).minutes(time_obj_or_now.getMinutes())
      offset = moment.tz(datetime_obj || new Date(), timezone).format('Z')

      if date_obj
        formatted_date = $.datepicker.formatDate("yy-mm-dd", date_obj)
        if time_obj
          formatted_date += "T" + time_obj.strftime('%H:%M:%S') + offset
        datetime.val(formatted_date)
      else
        datetime.val("")

    $(".uploader input").change ->
      $(this).closest('.wrapper').find('.warning').remove()
      file_extension = $($(this).val().split('.')).last()[0]
      if ["jpg",'png','svg','gif'].indexOf(file_extension) != -1
        if this.files? and this.files[0].size > 524288
          $uploadWrapper = $(this).closest('.uploader-wrapper')
          $uploadWrapper.append("<div class='warning'>" + $uploadWrapper.data('warning') + "</div>")

    $("input.latitude-field.geo-point-partial, input.longitude-field.geo-point-partial").change (e) ->
      wrapper = $(this).parents('.geo-point')
      latitude = wrapper.find("input.latitude-field")
      longitude = wrapper.find("input.longitude-field")
      geojson = wrapper.find('input[type="hidden"].geo-point-field')

      lat = parseFloat(latitude.val())
      lng = parseFloat(longitude.val())

      json = JSON.stringify({type: "Point", coordinates: [lng, lat]})
      geojson.val(json)

  inlineInputs: ->
    # Make inline labels nicely fade away
    $("form.inline input[type=text], form.inline input[type=password], form.inline textarea, .inline-form input[type=text], .inline-form input[type=password], .inline-form textarea").each (e) ->
      if not _.isEmpty $(this).val() || $(this).closest('div.input').hasClass('error')
        $(this).closest('div.input').addClass('hastext')

    selector = "form.inline input[type=text], form.inline input[type=password], form.inline textarea, .inline-form input[type=text], .inline-form input[type=password], .inline-form textarea"
    $(document).on "focus", selector, (e) ->
      $(this).closest('div.input').addClass('focus')

    $(document).on "keypress", selector, (e) ->
      if not _.isEmpty $(this).val()
      then $(this).closest('div.input').addClass('hastext')

    $(document).on "change", selector, (e) ->
      if not _.isEmpty $(this).val()
      then $(this).closest('div.input').addClass('hastext')

    $(document).on "keyup", selector, (e) ->
      if not _.isEmpty $(this).val()
      then $(this).closest('div.input').addClass('hastext')

    $(document).on "blur", selector, (e) ->
      $(this).closest('div.input').removeClass('focus')
      if _.isEmpty $(this).val()
      then $(this).closest('div.input').removeClass('hastext')

window.UI =
  KEY_CODES:
    BACKSPACE: 8
    TAB: 9
    RETURN: 13
    ENTER: 13
    SHIFT: 16
    ESC: 27
    LEFT: 37
    UP: 38
    RIGHT: 39
    DOWN: 40
    COMMA: 188
    SPACE: 32

  fixSelectizePlaceholders: ->
    # until https://github.com/selectize/selectize.js/issues/1498 is fixed
    $('.selectize-input > input').each((i,el) ->
      $(el).css("width", "");
    )
  showModal: (code, callback, stack) ->
    Mousetrap.pause();

    tearDown = (modal) ->
      $(modal).find('select').each((i, el) ->
        $el = $(el)
        if $el.length > 0 && $el[0].selectize?
          $el[0].selectize.destroy()
      )
      $(modal).remove()
      Mousetrap.unpause()

    animation = 'fade'
    replacing = false

    if $('.active-modal').length > 0 && !stack
      previous_style = $('.active-modal').attr('style')
      tearDown($('.active-modal'))
      animation = 'none'
      replacing = true

    modal = $( code )

    if replacing
      modal.attr('style', previous_style)
      modal.addClass('is-replaced')

    modal.appendTo('body').reveal(
      animation: animation,
      animationspeed: 300,
      closeonbackgroundclick: false,
      dismissmodalclass: 'close-modal'
      stack: stack
    ).bind('reveal:closed', ->
      tearDown($(this));
    ).addClass('active-modal')

    # Uniform all form inputs
    $("div:not('.template') input:file:not('.inline, .styled, .dropzone, .custom'), div:not('.template') input:checkbox:not('.switch'), div:not('.template') input:radio").each (el) ->
      parent = $(this).closest('div')
      if not (parent.hasClass('selector') || parent.hasClass('checker'))
        $(this).uniform()
    
    $select_elements = modal.find("select.selectizable")
    $select_elements_with_create = modal.find("div:not('.template') select.selectizable-with-create")
    $select_elements_with_search = modal.find("div:not('.template') select.selectizable-with-search")
    $select_elements_for_pages = modal.find("div:not('.template') select.selectizable-for-pages")

    $select_elements.on("change", ->
        UI.resetModal()
        $('.active-modal').find("div:not('.template') select.selectizable").each (i, select) ->
          $(select)[0].selectize.positionDropdown()
      )

    UI.selectize($select_elements, {dropdownParent: 'body'})
    UI.selectizeWithCreate($select_elements_with_create, {dropdownParent: 'body'})
    UI.selectizeWithSearch($select_elements_with_search, {dropdownParent: 'body'})
    UI.selectizeForPages($select_elements_for_pages, {dropdownParent: 'body'})

    modal.find('.only-floats').each () ->
      new Cleave($(this)[0], {
        numeral: true,
        numeralPositiveOnly: true,
        numeralThousandsGroupStyle: 'none'
      })

    modal.find('.only-integers').numeric({ negative: false, decimal : false })
    modal.find('.only-signed-floats').each () ->
      new Cleave($(this)[0], {
        numeral: true,
        numeralThousandsGroupStyle: 'none'
      })
    modal.find('.only-signed-integers').numeric({ negative: true, decimal : false })

    UI.enableCheckedElement()

    if modal.find('input.price').length > 0
      modal.find('input.price').on "change", ->
        Nimbu.formatPriceField($(this))

    modal.find('.selectize-input input').on 'keydown', (e) ->
      if e.which == UI.KEY_CODES.ESC
        e.stopPropagation()
        e.preventDefault()

    modal.find('.body').on 'scroll', (e) ->
      modal.find('select.selectizable').each (i, select) ->
        $(select)[0].selectize.positionDropdown()

    # Make inline labels nicely fade away
    modal.find("form.inline input:text, form.inline input:password, form.inline textarea").each (e) ->
      if not _.isEmpty $(this).val() || $(this).closest('div.input').hasClass('error')
        $(this).closest('div.input').addClass('hastext')
    
    if replacing
      UI.resizeModal()
    
    $autofocusElement = modal.find('[autofocus]').first()
    if $autofocusElement?
      $autofocusElement.focus()

    if callback?
      callback($('.active-modal'))

  closeModal: ->
    if $('.active-modal').length > 0
      modal = $('.active-modal').first()
      modal.trigger('reveal:close')

  resizeModal: ->
    if $('.active-modal').length > 0
      modal = $('.active-modal').first()
      modalbody = modal.find('.body')

      modal.css('margin-top', -( modal.height() / 2 ) + 'px');
      modal.css('margin-left', -( modal.width() / 2 ) + 'px');

      if _.isUndefined(modal.data('originalHeight'))
        modal.data('originalHeight',modalbody.height())

      windowHeight = $(window).height()

      if (windowHeight - 300 <= modal.data('originalHeight'))
        modalbody.css('height', (windowHeight - 300 + 'px'))
                          .css('overflow-y','scroll')
        modal.css('margin-top',-modal.height() / 2 + 'px')

      else
        modalbody.css('height', (modal.data('originalHeight') + 'px')).css('overflow-y','scroll')
        modal.css('margin-top',-modal.height() / 2 + 'px')

  resetModal: ->
    if $('.active-modal').length > 0
      modal = $('.active-modal').first()
      modalbody = modal.find('.body')
      modalbody.css({'height' : 'auto'})
      modal.data('originalHeight',null)
      modal.data('originalHeight',modalbody.height())
      UI.resizeModal()

  showFlash: (msg, type) ->
    el = $('div.notification').last()

    if(msg != undefined)
      if(el.length > 0)
        $('div.notification').remove();
      if(type != undefined)
        el = $("<div>").html(msg).addClass('inner').wrap("<div class=\"notification #{type} remote\" />").parent();
        el.appendTo('body')
        UI.showFlash();
        return true
      else
        el = $("<div>").html(msg).addClass('inner').wrap('<div class="notification success remote" />').parent();
        el.appendTo('body')
        UI.showFlash();
        return true

    if(el.length > 0)
      # We have a notification to show
      $('div.notification').transition({ bottom: '0px', duration: 300, easing: 'out' })
                        .delay(4000)
                        .transition({ bottom: '-87px', duration: 300, easing: 'out' }, -> $(this).remove())

      # allow to click on notification to hide it!
      $('div.notification').click((e) ->
        $(e.currentTarget).stop(true, true).transition({ bottom: '-87px', duration: 300, easing: 'out' }, -> $(this).remove())
      )

  showPreloader: (timeout) ->
    that = this
    modalBG = $('.reveal-modal-bg:not(.is-closing)')
    if modalBG.length == 0
      modalBG = $('<div class="reveal-modal-bg" />').appendTo($('body'))
    modalBG.css({'opacity' : 0, 'display' :'block'})
           .transition({ opacity: 1 })
           .data('preloaded', true)

    modalLoader = $('.reveal-modal-loader')
    switch $(document).find('html').attr('lang')
      when "en" then pleaseWait = "Please wait..."
      when "nl" then pleaseWait = "Even geduld aub..."
      when "fr" then pleaseWait = "Veuillez patienter..."
      else pleaseWait = "Please wait..."
    if modalLoader.length == 0
      modalLoader = $("<div class='reveal-modal-loader'>#{pleaseWait}</div>").appendTo($('body'))
    modalLoader.css({'opacity' : 0, 'display' :'block'}).transition({ opacity: 1 })

    if timeout? && timeout > 0
      setTimeout( ->
        if $('.nimbu-modal').length == 0
          that.closePreloader(true)
      , timeout)

  closePreloader: (force) ->
    $('.reveal-modal-loader').remove()

    if(force != undefined)
      modalBG = $('.reveal-modal-bg')
      modalBG.transition({ opacity: 0 }, ->
        $(this).remove()
      )

  setupSelectizeOptions: (el, options) ->
    options = {} if !options?
    if el.hasClass('selectizable-with-draggable-items')
      options.plugins = ['drag_drop','remove_button']
    else if el.hasClass('references_many')
      options.plugins = ['remove_button']

    if el.data('selectize-disable-search')
      options.onInitialize = () ->
        this.$control_input.attr('readonly', true);
    
    return options

  selectize: ($selector, options) ->
    options = {} if !options?
    $selector.each (i, el) ->
      el = $(el)
      selectize_options = UI.setupSelectizeOptions(el, {dropdownParent: options.dropdownParent || null})
      el.selectize(selectize_options)

  selectizeWithCreate: ($selector, options) ->
    options = {} if !options?
    $selector.each (i, el) ->
      el = $(el)
      selectize_options = UI.setupSelectizeOptions(el, {create: true, persist: false: options.dropdownParent || null})
      el.selectize(selectize_options)

  selectizeForPages: ($selector, options) ->
    options = {} if !options?
    renderPageDepth = (depth) ->
      str = '<div class="page-depth">'
      for i in [0...depth]
        str += '──'
      str += '</div>'
      return str
    $selector.each (i, el) ->
      el = $(el)
      selectize_options = 
        dropdownParent: options.dropdownParent
        dataAttr: 'data-json'
        render: 
          option: (item, escape) ->
            "<div class='option page-select-option'>#{renderPageDepth(item.json.depth)}<div class='option-title-wrapper'><div class='option-title'>#{escape(item.json.title)}</div><div class='option-url'>#{escape(item.json.url)}</div></div></div>";
          item: (item, escape) ->
            "<div class='page-select-item'><div class='option-title'>#{escape(item.json.title)}</div><div class='option-url'>#{escape(item.json.url)}</div></div>";
      
      el.selectize(selectize_options)

  selectizeWithSearch: ($selector, options) ->
    options = {} if !options?
    $selector.each (i, el) ->
      el = $(el)
      select_options = []
      el.find("option").each (i,el2) ->
        data = $(el2).data('data')
        if data
          select_options.push(data)
      selectize_options = {
        valueField: 'id',
        labelField: 'name',
        searchField: ['name'],
        create: false,
        dropdownParent: options.dropdownParent || null,
        options: select_options,
        render:
          option: (item, escape) ->
            '<div data-json=\'' + JSON.stringify(item) + '\' data-selectable="" class="option">' + escape(item.name) + '</div>';
        load: (query, callback) ->
          return callback() unless query.length
          $.getJSON(el.data('search-url'), search: query, (res) ->
            callback res
          )
      }

      selectize_options = UI.setupSelectizeOptions(el, selectize_options)
      el.selectize(selectize_options)

  enableCheckedElement: (parent) ->
    if parent?
      $checkElements = $(parent).find(".check_element")
    else
      $checkElements = $(".check_element")

    $checkElements.each (i,element) ->
      $el = $(element)
      input = $el.find('input')
      $el.addClass('initialized')
      if input.val() == "true"
        $el.addClass('active')
      if $el.find(".field-description").length == 0 || $el.find(".field-description").html() ==  ""
        $el.addClass('without-description')
      $el.off()
      $el.click () ->
        if !$el.hasClass('disabled')
          $input = $el.find('input')
          if $input.val() == "true"
            $input.val("false")
            $input.triggerNative("change")
            $el.removeClass('active')
          else
            $input.val("true")
            $input.triggerNative("change")
            $el.addClass('active')

  autoGrowTextareas: ->
    $('textarea.autogrow').each () ->
      autogrowTextArea = new Autogrow($(this)[0])
      autogrowTextArea.autogrowFn()

  tooltips: ->
    $('#globalheader [title]').tipsy(
      gravity: 'w',
      offset: 5,
      html: false,
      live: false,
      delayIn: 100,
    )
    $('[title]:not(.remove):not(.skip-tipsy)').each(() ->
      $el = $(this)
      options = {
        gravity: $el.data('gravity') || $el.data('tipsy-gravity') || 's',
        html: $el.data('html') == true || $el.data('tipsy-html') == true,
        className: $el.data('tipsy-classname'),
        fade: true,
        live: false,
        delayIn: 50,
      }
      if $el.data('manual-tipsy')
        options.trigger = 'manual'

      $el.tipsy(options)
    )

  restoreTooltipTitles: ->
    $('[original-title]').each(() ->
      $el = $(this)
      $el.attr('title', $el.attr('original-title'))
    )
  
  browserSupportsPushState: ->
    window.history and window.history.pushState and window.history.replaceState and window.history.state != undefined

  enableSpinners: ->
    $(".spinner").spin({ lines: 14, length: 5, width: 2, radius: 5, hwaccel: true, color: '#7c8088' })

  activateTabs: ->
    if $('.content-tabs').data('active-tab')?
      $('.content-tabs').tabs(".content-panes > div", {initialIndex: parseInt($('.content-tabs').data('active-tab'))})
    else
      $('.content-tabs').tabs(".content-panes > div")

    loadTab = ($link) ->
      if $link.data('load-async')? && !$link.data('load-async-loaded')?
        $.ajax({
          url: $link.data('load-async'),
          dataType: "script"
        });
        $link.data('load-async-loaded', true)

    $('.content-tabs .tab a').on('click', (e) ->
      loadTab($(e.currentTarget))
    )
    $('.content-tabs .tab a.current').each ->
      loadTab($(this))


  enableImagePreviews: ->
    $('.fancy-image-link').magnificPopup
      type: 'image'
      mainClass: 'my-mfp-zoom-in'
      image:
        markup: '<div class="mfp-figure">' + '<div class="mfp-close"></div>' + '<div class="mfp-img"></div>' + '<div class="mfp-bottom-bar">' + '<div class="mfp-title"></div>' + '<div class="mfp-counter"></div>' + '</div>' + '</div>'
        cursor: 'mfp-zoom-out-cur'
        titleSrc: 'title'
        verticalFit: true
        tError: '<a href="%url%">The image</a> could not be loaded.'
      zoom:
        enabled: true
        duration: 300
        easing: 'ease-in-out'
        opener: (openerElement) ->
          # openerElement is the element on which popup was initialized, in this case its <a> tag
          # you don't need to add "opener" option if this code matches your needs, it's defailt one.
          if openerElement.is('img') then openerElement else openerElement.find('img')

  setupRedactor: ($selection) ->
    $selection = $('textarea.wysiwyg:not(.redactor-initialized):not(.manual)') unless $selection?

    consentApps = $('[data-consent-apps]').data('consent-apps') || []

    $selection.redactor(
      autoresize: true,
      deniedTags: ['html', 'head', 'link', 'body', 'meta', 'applet'],
      removeStyles: true,
      overlay: true,
      buttonSource: true,
      minHeight: 150,
      linkSize: 100,
      linkEmail: true,
      linkAnchor: true,
      linkNewTab: true,
      linkValidation: false,
      replaceDivs: true,
      paragraphize: true,
      removeWithoutAttr: ['span'],
      toolbarFixedTopOffset: 67,
      imageFloatMargin: '30px',
      imageResizable: true,
      imagePosition: true,
      path: '/assets/redactor',
      plugins: ['alignment', 'definedlinks', 'fontcolor', 'fontsize', 'fontfamily', 'table', 'imagemanager', 'filemanager',
        'video', 'widget', 'textdirection', 'specialchars', 'properties', 'clearformatting', 'fullscreen', 'counter', 'inlinestyle', 'more'],
      buttons: ['html', 'format', 'inline', 'bold', 'italic', 'underline', 'deleted', 'alignment', 'lists', 'table', 'link', 'file', 'image', 'video',
        'fontcolor', 'fontsize', 'specialchars'],
      buttonsHide: ['textdirection', 'properties', 'widget'],
      imageManagerJson: "/admin/media/images.json",
      imageUpload: "/admin/media/uploads",
      fileUpload: "/admin/media/uploads",
      fileManagerJson: "/admin/media/other.json",
      definedlinks: "/admin/pages.json",
      consentApps: consentApps,
      fontcolors: ["#000", "#5E1916", "#630A3A", "#300D5C", "#1A0E4F", "#10144A", "#082B61", "#013A66", "#004345", "#002E26", "#0F3311", "#214213", "#47420D", "#AD5910", "#A34700", "#993600", "#752107", "#291A17",
                  "#333", "#7D221D", "#880e4f", "#4a148c", "#311b92", "#1a237e", "#0d47a1", "#01579b", "#006064", "#004d40", "#1b5e20", "#33691e", "#827717", "#f57f17", "#ff6f00", "#e65100", "#bf360c", "#3e2723",
                  "#555", "#a82d26", "#ad1457", "#6a1b9a", "#4527a0", "#283593", "#1565c0", "#0277bd", "#00838f", "#00695c", "#2e7d32", "#558b2f", "#9e9d24", "#f9a825", "#ff8f00", "#ef6c00", "#d84315", "#4e342e",
                  "#777", "#c23e38", "#c2185b", "#7b1fa2", "#512da8", "#303f9f", "#1976d2", "#0288d1", "#0097a7", "#00796b", "#388e3c", "#689f38", "#afb42b", "#fbc02d", "#ffa000", "#f57c00", "#e64a19", "#5d4037",
                  "#999", "#d3483e", "#d81b60", "#8e24aa", "#5e35b1", "#3949ab", "#1e88e5", "#039be5", "#00acc1", "#00897b", "#43a047", "#7cb342", "#c0ca33", "#fdd835", "#ffb300", "#fb8c00", "#f4511e", "#6d4c41",
                  "#aaa", "#e15241", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548",
                  "#bbb", "#dd5e56", "#ec407a", "#ab47bc", "#7e57c2", "#5c6bc0", "#42a5f5", "#29b6f6", "#26c6da", "#26a69a", "#66bb6a", "#9ccc65", "#d4e157", "#ffee58", "#ffca28", "#ffa726", "#ff7043", "#8d6e63",
                  "#ccc", "#d67976", "#f06292", "#ba68c8", "#9575cd", "#7986cb", "#64b5f6", "#4fc3f7", "#4dd0e1", "#4db6ac", "#81c784", "#aed581", "#dce775", "#fff176", "#ffd54f", "#ffb74d", "#ff8a65", "#a1887f",
                  "#eee", "#e39e9c", "#f48fb1", "#ce93d8", "#b39ddb", "#9fa8da", "#90caf9", "#81d4fa", "#80deea", "#80cbc4", "#a5d6a7", "#c5e1a5", "#e6ee9c", "#fff59d", "#ffe082", "#ffcc80", "#ffab91", "#bcaaa4",
                  "#f4f4f4", "#f7cfd3", "#f8bbd0", "#e1bee7", "#d1c4e9", "#c5cae9", "#bbdefb", "#b3e5fc", "#b2ebf2", "#b2dfdb", "#c8e6c9", "#dcedc8", "#f0f4c3", "#fff9c4", "#ffecb3", "#ffe0b2", "#ffccbc", "#d7ccc8",
                  "#ffffff", "#fcecee", "#fce4ec", "#f3e5f5", "#ede7f6", "#e8eaf6", "#e3f2fd", "#e1f5fe", "#e0f7fa", "#e0f2f1", "#e8f5e9", "#f1f8e9", "#f9fbe7", "#fffde7", "#fff8e1", "#fff3e0", "#fbe9e7", "#efebe9"]
    );

    $selection.each ->
      $(this).addClass("redactor-initialized")

  destroyRedactor: ($selection) ->
    $selection = $('textarea.wysiwyg') unless $selection?

    if $selection.length > 0
      $selection.each( ->
        if $(this).data('redactor')
          $(this).redactor('core.destroy')
          $(this).removeClass("redactor-initialized")
        $(".redactor_dropdown").remove()
        $(".ui-datepicker-div").remove()
      )

  slugify_with_slashes: (str, options) ->
    options = {} unless options?
    dashchar = options.separator || "-"
    preserveCase = options.preserveCase

    defaultToWhiteSpace = (characters) ->
      unless characters?
        "\\s"
      else if characters.source
        characters.source
      else
        "[" + escapeRegExp(characters) + "]"

    return ""  unless str?
    from = "ÀÁÂÃÄÅàáâãäåĀāĂăĄąÇçĆćĈĉĊċČčÐðĎďĐđÈÉÊËèéêëĒēĔĕĖėĘęĚěĜĝĞğĠġĢģĤĥĦħÌÍÎÏìíîïĨĩĪīĬĭĮįİıĴĵĶķĸĹĺĻļĽľĿŀŁłÑñŃńŅņŇňŉŊŋÒÓÔÕÖØòóôõöøŌōŎŏŐőŔŕŖŗŘřŚśŜŝŞşŠšſŢţŤťŦŧÙÚÛÜùúûüŨũŪūŬŭŮůŰűŲųŴŵÝýÿŶŷŸŹźŻżŽž'"
    to = "AAAAAAaaaaaaAaAaAaCcCcCcCcCcDdDdDdEEEEeeeeEeEeEeEeEeGgGgGgGgHhHhIIIIiiiiIiIiIiIiIiJjKkkLlLlLlLlLlNnNnNnNnnNnOOOOOOooooooOoOoOoRrRrRrSsSsSsSssTtTtTtUUUUuuuuUuUuUuUuUuUuWwYyyYyYZzZzZz-"
    regex = new RegExp(defaultToWhiteSpace(from), "g")
    unless preserveCase
      str = String(str).toLowerCase()
    str = String(str).replace(regex, (c) ->
      index = from.indexOf(c)
      to.charAt(index) or "-"
    )
    str = str.replace(/[^\w\s-_\/]/g, "")
    return str
  
  slugify: (str, options) ->
    options = {} unless options?
    
    text = UI.slugify_with_slashes(str)

    # replace characters
    text = text.replace(/[^-a-zA-Z0-9,&\s]+/ig, '')
    text = text.replace(/\s/gi, "-")
    text = text.replace(/-+/g,'-')
    text = text.replace(/^-/g,'')

    # allow to type spaces or hypens while editing
    unless options.allowEndingHyphen
      text = text.replace(/-$/g,'')

    text = text.toLowerCase()
    text

  queryParams: ->
    parts = window.location.search.substr(1).split('&')
    params = {}
    i = 0
    while i < parts.length
      p = parts[i].split('=', 2)
      if p.length == 1
        params[p[0]] = ''
      else
        params[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' '))
      ++i
    return params

Nimbu.initialize()
