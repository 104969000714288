import $ from "jquery";

class App.Views.EditNotificationView extends Backbone.View
  initialize: (options) ->
    @options = options
    # store the original values:
    @view = $(@el)

    @original_subject = @view.find('.mail-subject').val()
    @original_text = @view.find('.mail-text').val()
    @original_enable_html = @view.find('.mail-html-enabled').is(':checked')
    @original_html = @view.find('.mail-html').val()
    @original_element = @view.clone()

    UI.enableCheckedElement()

    @view.data('view',this)

  events:
    "click .email-template-name" : "toggleEditEmails"
    "click .cancel" : "closeEditEmailsAndRestore"
    "change .mail-html-enabled" : "changeHtmlEmail"
    "click .preview-link" : "previewEmail"


  toggleEditEmails: (e) ->
    e.preventDefault()

    # close all other mail forms
    $(@options.parent).find(".email-template:not(##{$(@el).attr('id')}) .edit-email-template").hide()
    @$('.edit-email-template').toggle()

  closeEditEmailsAndRestore: (e) ->
    e.preventDefault()

    @view.find('.mail-subject').val(@original_subject)
    @view.find('.mail-text').val(@original_text)
    @view.find('.mail-html-enabled').attr('checked',@original_enable_html)
    @view.find('.mail-html').val(@original_html)
    $.uniform.update();

    @$('.edit-email-template').hide()

    if @options.original?
      @view.replaceWith(@options.original)
      new App.Views.EditNotificationView({
        el: @options.original,
        parent: @options.parent
      })

    @$('.edit-email-template').hide()

  changeHtmlEmail: (e) ->
    if @$('input.mail-html-enabled').is(':checked') == true || @$('input.mail-html-enabled').val() == "true"
      @$(".html-email-wrapper").show()
    else
      @$(".html-email-wrapper").hide()

  previewEmail: (e) ->
    e.preventDefault()

    link = $(e.currentTarget)
    url = link.data('preview-url')

    data = { subject: @$('.mail-subject').val() }

    if @$('.mail-name').length > 0
      data.name = @$('.mail-name').val()

    if link.hasClass('preview-txt-email')
      data.text = @$('.mail-text').val()
      data.type = 'text'
    else
      data.text = @$('.mail-text').val()
      data.html = @$('.mail-html').val()
      data.type = 'html'

    $.post(
      url,
      data,
      (data, textStatus, jqXHR) -> {},
      'script'
    )
