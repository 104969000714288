import $ from "jquery";
import _ from "underscore";

class App.Views.EditPaymentMethodView extends Backbone.View
  initialize: (options) ->
    @options = options
    _.bindAll(this, "toggleConfiguration", "toggleTestMode");

    $(@el).data('view', this)
    UI.enableCheckedElement()
    UI.selectize(@$('select.selectizable:not(.selectized)'))

    if @$('.input.error').length > 0
      $(@el).find('.configuration').show()

  events:
    "click .configure-link"     : "toggleConfiguration"
    "change .payment_test_mode" : "toggleTestMode"

  toggleConfiguration: (e) ->
    el = $(e.currentTarget)
    pm = el.closest('.payment-method')
    id = pm.attr('id')
    @options.parent.hideAllConfiguration(id)
    pm.find('.configuration').toggle()

  toggleTestMode: (e) ->
    el = $(e.currentTarget)
    pm = el.closest('.payment-method')
    pm.toggleClass('test-mode')
