import $ from "jquery";
import _ from "underscore";
import unescapeHTML from "underscore.string/unescapeHTML";

class App.Views.EditCustomFieldItemView extends Backbone.View
  initialize: (options) ->
    @options = options
    UI.enableCheckedElement()
    @$("select").selectize()

    @$(".category-field").each ->
      new App.Views.EditCustomFieldSelectItemView({el: $(this)})

    @$(".category-list").sortable(
      zIndex: 100,
      axis: "y",
      forcePlaceholderSize: true,
      item: 'li',
      update: (event, ui) ->
        cnt = 0
        $(this).find('> li').each ->
          el = $(this).find(".position-field").attr('value',cnt)
          cnt++
    )

    @label_value = @$('.field-label').val()
    @slug_value = @$('.field-alias').val()

    if @slug_value? && @slug_value.length > 0
      @$('.field-alias').closest("div").addClass('manually')

  events:
    "keyup input.field-label"  : "setAlias"
    "keyup input.field-alias"  : "slugifyAlias"
    "change select.field-kind" : "changedKind"
    "confirm:complete .remove > a" : "removeField"
    "click .settings"          : "toggleSettings"
    "click .required-icon"     : "toggleRequired"
    "click .translated-icon"   : "toggleTranslated"
    "click .field-required-wrapper"  : "toggleRequiredIcon"
    "click .field-localized-wrapper" : "toggleTranslatedIcon"
    "click .edit-categories .add-categories > a"       : "addCategory"
    "click .edit-multi-categories .add-categories > a" : "addMultiCategory"

  toggleSettings: (e) ->
    @$(".options.sub-section").toggle()

  toggleRequired: () ->
    @$('.field-required-wrapper').click()

  toggleRequiredIcon: (e) ->
    @$('.field-required_expression-wrapper').toggle()
    @$(".required-icon").toggleClass("active")

  toggleTranslated: () ->
    @$('.field-localized-wrapper').click()

  toggleTranslatedIcon: () ->
    @$(".translated-icon").toggleClass("active")

  setAlias: (e) ->
    obj = @$('.field-label')
    unless obj.val() == @label_value
      target = @$('.field-alias')
      if !target.closest("div").hasClass("manually")
        slug = obj.val().replace(/\s+/g,'_').replace(/[^a-zA-Z0-9\_]/g,'').toLowerCase()
        target.val(slug)
        if not _.isEmpty(slug)
          target.closest('.input').addClass('hastext')
        else
          target.closest('.input').removeClass('hastext')

  slugifyAlias: (e) ->
    if e.keyCode in [16,17,18,91,93,37,38,39,40,224] # shift, ctrl, alt, cmd, arrows
      return

    obj = @$('.field-alias')
    value = @$('.field-alias').val()
    unless value == @slug_value
      obj.closest('div').addClass('manually')
      caret_position = obj.caret()
      slug = obj.val().replace(/\s+/g,'_').replace(/[^a-zA-Z0-9\_]/g,'').toLowerCase()
      obj.val(slug)
      obj.caret(caret_position)

  changedKind: (e) ->
    obj = @$('.field-kind')
    kind = obj.val()
    switch kind
      when "category", "select"                then @selectedSingleSelect(kind)
      when "multi_category","multi_select"     then @selectedMultiSelect(kind)
      when "belongs_to", "belongs_to_many"     then @selectedReferenceTo(kind)
      when "calculated"                        then @selectedCalculated(kind)
      when "geo"                               then @selectedGeo(kind)
      when "file"                              then @selectedFile(kind)
      else
        @resetUI(kind)


  resetUI: (kind) ->
    @$('.field-auto_expand-wrapper').hide()
    @$('.field-required').closest('div.input').show()
    @$('.field-calculated').closest('div.input').hide()
    @$('.field-calculation-type').closest('div.input').hide()
    @$('.field-localized').closest('div.input').show()
    @$('.field-target').closest('div.input').hide()
    @$('.field-geo-type').closest('div.input').hide()
    if(kind in ["string", "text", "email", "json"])
      @$('.field-encrypted').closest('div.input').show()
    else
      @$('.field-encrypted').closest('div.input').hide()
      if !@$('.field-encrypted').hasClass('disabled')
        @$('.field-encrypted').closest('div.input').removeClass('active')
        @$('.field-encrypted').val('false')
    @$('.field-file-private-storage').closest('div.input').hide()
    @$('.edit-categories-wrapper').hide()
    @$('.edit-multi-categories-wrapper').hide()

  selectedSingleSelect: (kind = "select") ->
    @resetUI(kind)
    @$('.edit-categories-wrapper').show()
    @$(".options.sub-section").show()
    @$('.field-auto_expand-wrapper').show()

  selectedMultiSelect: (kind = "multi_select") ->
    @resetUI(kind)
    @$('.edit-multi-categories-wrapper').show()
    @$(".options.sub-section").show()
    @$('.field-auto_expand-wrapper').show()

  selectedReferenceTo: (kind = "belongs_to") ->
    @resetUI(kind)
    @$('.field-target').closest('div.input').show()
    @$(".options.sub-section").show()

  selectedCalculated: (kind = "calculated") ->
    @resetUI(kind)
    @$('.field-calculated').closest('div.input').show()
    @$('.field-calculation-type').closest('div.input').show()
    @$('.field-required').closest('div.input').hide()
    @$(".options.sub-section").show()

  selectedGeo: (kind = "geo") ->
    @resetUI(kind)
    @$('.field-geo-type').closest('div.input').show()
    @$(".options.sub-section").show()

  selectedFile: (kind = "file") ->
    @resetUI(kind)
    @$('.field-file-private-storage').closest('div.input').show()
    @$(".options.sub-section").show()

  removeField: (e, confirmed) ->
    if confirmed
      link = e.currentTarget
      $(link).prev().find("input[type=hidden]").val("1")
      $(link).closest(".custom-field-wrapper").hide().addClass('hidden')
      @options.parent.hideOrShowDestroyButtons()

  addField: (e) ->
    link = e.target
    tpl = $("#new-field-template").html()
    new_id = new Date().getTime()
    regexp = new RegExp("new_custom_field", "g")
    el = $(unescapeHTML(tpl.replace(regexp, new_id))).appendTo(this._parent.$("#custom_field_list"))
    new App.Views.EditCustomFieldItemView({el: $(el)})

  addCategory: (e) ->
    link = e.target
    tpl = $("#new-category-template").html()
    new_id = new Date().getTime()
    regexp = new RegExp("new_category_item", "g")
    parent_id = $(@el).attr('data-child-index')
    regexp2 = new RegExp("new_custom_field", "g")
    el = $(unescapeHTML(tpl.replace(regexp, new_id).replace(regexp2, parent_id))).appendTo(@$(".single-category-list"))
    @$(el).find(".position-field").attr('value',@$(".single-category-list").find('li').length)
    new App.Views.EditCustomFieldSelectItemView({el: $(el)})

  addMultiCategory: (e) ->
    link = e.target
    tpl = $("#new-multi-category-template").html()
    new_id = new Date().getTime()
    regexp = new RegExp("new_category_item", "g")
    parent_id = $(@el).attr('data-child-index')
    regexp2 = new RegExp("new_custom_field", "g")
    el = $(unescapeHTML(tpl.replace(regexp, new_id).replace(regexp2, parent_id))).appendTo(@$(".multi-category-list"))
    @$(el).find(".position-field").attr('value',@$(".multi-category-list").find('li').length)
    new App.Views.EditCustomFieldSelectItemView({el: $(el)})
