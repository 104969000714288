import $ from "jquery";

class App.Views.PaymentMethodsView extends Backbone.View
  initialize: ->
    view = this
    $(@el).data('view', this)

    @$('.payment-method').each ->
      new App.Views.EditPaymentMethodView({el: $(this), parent: view })

    @$('#change_single_setting_admin_form').on('change', 'input', (e) ->
      $(this).closest('form').submit()
    )

  hideAllConfiguration: (current) ->
    @$(".payment-method:not(##{current}) .configuration").hide()
