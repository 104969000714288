import $ from "jquery";

class App.Views.VideosIndexView extends Backbone.View
  initialize: ->
    view = this
    $(@el).data('view',view)
    this.initVideoUploader()
    this.subscribeToPush()

  subscribeToPush: ->
    Nimbu.getPusher().channel.bind('video-processing', (data) ->
      if $("##{data.element_id}").length > 0
        $("##{data.element_id}").replaceWith(data.html)
    )

  updateStatusNow: ->
    url = @$('#new_video').data('refresh-url')
    $.get(url,null,null,'script')

  # We need a simple hashing function to turn the filename into a
  # numeric value for the nginx session ID. See:
  #
  #   http://pmav.eu/stuff/javascript-hashing-functions/index.html
  hash = (s, tableSize) ->
    b = 27183
    h = 0
    a = 31415

    for i in [0...s.length]
      h = (a * h + s[i].charCodeAt()) % tableSize
      a = ((a % tableSize) * (b % tableSize)) % (tableSize)
    h

  sessionId = (filename) ->
    hash(filename, 16384)

  initVideoUploader: ->
    numberOfFiles = 0
    crntUpload = 0
    view = this
    el = @$('input#new_video')
    callback = el.data('callback-url')

    tearDown = ->
      if el.data('blueimpFileupload')?
        el.fileupload('destroy')
      document.removeEventListener("turbolinks:before-cache", tearDown)
    document.addEventListener("turbolinks:before-cache", tearDown)

    el.fileupload(
      # pasteZone: $('#image_fields')
      # dropZone: $('#image_fields')

      # nginx's upload module responds to these requests with a simple
      # byte range value (like "0-2097152/3892384590"), so we shouldn't
      # try to parse that response as the default JSON dataType
      dataType: 'text',

      # very importantly, the nginx upload module *does not allow*
      # resumable uploads for a Content-Type of "multipart/form-data"
      multipart: false,

      sequentialUploads: true
      limitConcurrentUploads: 1
      url: el.data('upload-url')
      type: 'POST'

      add: (e, data) ->
        # The example input, doesn't have to be part of the upload form:
        file = data.files[0]

        this.acceptFileTypes = /(\.|\/)(mov|mpe?g|avi|mp4|wmv|mkv|m4v)$/i
        if(!(this.acceptFileTypes.test(file.type) || this.acceptFileTypes.test(file.name)))
          return false

        data.filename = file.name
        data.headers or= {}
        data.headers['Session-Id'] = sessionId(data.files[0].name)

        numberOfFiles++
        data.submit()

      send: (e, data) ->
        view.updateStatusNow()
        crntUpload++

      start: ->
        widget = $(this)

        modalBG = $('.uploader-bg')
        if modalBG.length == 0
          modalBG = $('<div class="uploader-bg" />').appendTo($('body'))
        modalBG.css({'opacity' : 0, 'display' :'block'})
               .transition({ opacity: 1 })
               .data('preloaded', true)

        modalLoader = $('.uploader-progress')
        if modalLoader.length == 0
          modalLoader = $("<div class='uploader-progress'><div class='label'>Please Wait while uploading</div><div class='progressbar-wrapper'><div class='progressbar' style='width:0%;'></div></div><div class='progress-info'></div></div>").css({'opacity' : 0, 'display' :'block', 'scale': 0.95 }).appendTo($('body'))
        modalLoader.transition({ opacity: 1, duration: 400, scale: 1, easing: 'out'})

        progressElement = $('.uploader-progress .progress-info')
        progressBar = $('.uploader-progress .progressbar')
        interval = 100
        total = 0
        loaded = 0
        loadedBefore = 0
        crntfile = ""
        progressTimer = undefined

        progressHandler = (e, data) ->
          loaded = data.loaded
          total = data.total

        stopHandler = ->
          view.updateStatusNow()

          numberOfFiles = 0
          crntUpload = 0

          modalBG = $('.uploader-bg')
          modalLoader = $('.uploader-progress')

          modalBG.delay(1000).transition
            opacity: 0
            duration: 800
          , ->
            Turbolinks.visit(callback)
            $(this).css(
              display: "none"
              opacity: 0
            ).remove()

          modalLoader.delay(1000).transition
            opacity: 0
            scale: 0.8
            duration: 500
          , ->
            $(this).css(
              display: "none"
              opacity: 0
            ).remove()

          window.setTimeout(stopIntervalHandler, 2000)

        formatTime = (seconds) ->
          date = new Date(seconds * 1000)
          ("0" + date.getUTCHours()).slice(-2) + ":" + ("0" + date.getUTCMinutes()).slice(-2) + ":" + ("0" + date.getUTCSeconds()).slice(-2)

        formatBytes = (bytes) ->
          return (bytes / 1000000000).toFixed(2) + " GB"  if bytes >= 1000000000
          return (bytes / 1000000).toFixed(2) + " MB"  if bytes >= 1000000
          return (bytes / 1000).toFixed(2) + " KB"  if bytes >= 1000
          bytes + " B"

        formatPercentage = (floatValue) ->
          (floatValue * 100).toFixed(2) + "%"

        updateProgressElement = (file,loaded, total, bps) ->
          progressElement.html("Uploading file #{crntUpload} / #{numberOfFiles} at " + formatBytes(bps) + "ps | " + formatPercentage(loaded / total) + " done)")
          progressBar.css('width',formatPercentage(loaded / total))

        intervalHandler = ->
          diff = loaded - loadedBefore
          return  unless diff
          loadedBefore = loaded
          updateProgressElement(crntfile,loaded, total, (diff / interval)*10)

        stopIntervalHandler = ->
          widget.unbind("fileuploadprogressall", progressHandler)
                .unbind "fileuploadstop", stopHandler
          window.clearInterval progressTimer

        widget.bind("fileuploadprogressall", progressHandler)
              .bind("fileuploadstop", stopHandler)

        progressTimer = window.setInterval(intervalHandler, interval)
    )
