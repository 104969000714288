import $ from "jquery";
import _ from "underscore";
import accounting from "accounting";
import unescapeHTML from "underscore.string/unescapeHTML";
import { setupPhoneInput } from "../../utils";

class App.Views.BackendOrderView extends Backbone.View
  initialize: ->
    view = this

    this.initLineItems()
    this.updateTotals()

    setupPhoneInput(@$('#order_billing_address_attributes_phone'))
    setupPhoneInput(@$('#order_shipping_address_attributes_phone'))

    @sameBillingAsShipping = $('#order_use_billing_false').is(":checked")
    @orderWithShipping     = ($("#order_add_shipping").val() == "true")
    @orderWithInvoice      = ($("#order_needs_invoice").val() == "true")
    @customerProfileUrl    = $('.backend-order-customer-info .link a').attr('href')
    @addresses             = {}

    this.updateAddressVisibility()

  events:
    "change #order_use_billing_false"   : "hideBilling"
    "change #order_use_billing_true"    : "showBilling"
    "change #order_add_shipping"        : "changedShipping"
    "change #order_needs_invoice"       : "changedInvoice"
    "change #order_retail_payment_id"   : "changedPaymentMethod"
    "change #order_customer_id"         : "changedCustomerId"
    "change #billing_address_dropdown"  : "chosenBillingAddress"
    "change #shipping_address_dropdown" : "chosenShippingAddress"

  changedShipping: (e) ->
    @orderWithShipping = ($("#order_add_shipping").val() == "true")
    this.updateAddressVisibility()

  changedInvoice: (e) ->
    @orderWithInvoice = ($("#order_needs_invoice").val() == "true")
    this.updateAddressVisibility()

  changedCustomerId: (e) ->
    customer_id = $('#order_customer_id').val()
    if customer_id? && customer_id != ""
      view = this
      url = @customerProfileUrl.replace('__customer_id__', customer_id)
      $.getJSON(url, (result) ->
        $('.backend-order-customer-info .firstname').html(result.firstname)
        $('.backend-order-customer-info .lastname').html(result.lastname)
        $('.backend-order-customer-info .email').html(result.email)
        $('.backend-order-customer-info .language').html(result.language)
        $('.backend-order-customer-info .link a').attr('href', view.customerProfileUrl.replace('__customer_id__', customer_id))
        $('.backend-order-customer-info').show()

        address_types = ['shipping', 'billing']
        view.addresses = {}
        if _.isArray(result.addresses)
          _.each address_types, (type) ->
            select = $("##{type}_address_dropdown")[0].selectize
            select.clearOptions()
            _.each result.addresses, (address) ->
              data = _.compact([address.company,
                      address.vat,
                      "#{address.firstname} #{address.lastname}",
                      "#{address.street} #{address.number}",
                      address.number_extra,"#{address.zipcode} #{address.city}",
                      address.country
                    ]).join(', ')
              view.addresses[address.id] = address
              select.addOption({value: address.id, text: data, data: address})
            $(".prefill-#{type}").show()
        else
          _.each address_types, (type) ->
            $(".prefill-#{type}").hide()
      )
    else
      $('.backend-order-customer-info').hide()

  chosenBillingAddress: (e) ->
    selected_id = $('#billing_address_dropdown').val()
    if selected_id? && selected_id != ""
      value = $('#billing_address_dropdown')[0].selectize.getValue()
      address = @addresses[value]
      @$("#order_billing_address_attributes_vat").val(address.vat)
      if address.title_id == "mrs" || address.title_id == "miss" || address.title_id == "mr"
        @$("#order_billing_address_attributes_title_id")[0].selectize.setValue(address.title_id)
      @$("#order_billing_address_attributes_firstname").val(address.firstname)
      @$("#order_billing_address_attributes_lastname").val(address.lastname)
      @$("#order_billing_address_attributes_company").val(address.company)
      @$("#order_billing_address_attributes_number").val(address.number)
      @$("#order_billing_address_attributes_number_extra").val(address.number_extra)
      @$("#order_billing_address_attributes_street").val(address.street)
      @$("#order_billing_address_attributes_zipcode").val(address.zipcode)
      @$("#order_billing_address_attributes_city").val(address.city)
      @$("#order_billing_address_attributes_country_id")[0].selectize.setValue(address.country_id)
      @$("#order_billing_address_attributes_phone").val(address.phone)

  chosenShippingAddress: (e) ->
    selected_id = $('#shipping_address_dropdown').val()
    if selected_id? && selected_id != ""
      value = $('#shipping_address_dropdown')[0].selectize.getValue()
      address = @addresses[value]
      if address.title_id == "mrs" || address.title_id == "miss" || address.title_id == "mr"
        @$("#order_shipping_address_attributes_title_id")[0].selectize.setValue(address.title_id)
      @$("#order_shipping_address_attributes_firstname").val(address.firstname)
      @$("#order_shipping_address_attributes_lastname").val(address.lastname)
      @$("#order_shipping_address_attributes_company").val(address.company)
      @$("#order_shipping_address_attributes_number").val(address.number)
      @$("#order_shipping_address_attributes_number_extra").val(address.number_extra)
      @$("#order_shipping_address_attributes_street").val(address.street)
      @$("#order_shipping_address_attributes_zipcode").val(address.zipcode)
      @$("#order_shipping_address_attributes_city").val(address.city)
      @$("#order_shipping_address_attributes_country_id")[0].selectize.setValue(address.country_id)
      @$("#order_shipping_address_attributes_phone").val(address.phone)


  changedPaymentMethod: (e) ->
    selected_id = $('#order_retail_payment_id').val()
    if selected_id? && selected_id != ""
      value = $('#order_retail_payment_id')[0].selectize.getItem(selected_id).first().text()
      if value == "Bank Deposit" && $('#order_mark_as_paid').val() == "true"
        $('#order_mark_as_paid').closest('.check_element').click()

  updateAddressVisibility: ->
    if @orderWithShipping
      $('.shipping-address-section').show()
      $('.billing-address-section').show()
      $('.backend-order-use-billing').show()
      if @sameBillingAsShipping
        $('.billing-selection').hide()
      else
        $('.billing-selection').show()
    else if @orderWithInvoice
      $('.shipping-address-section').hide()
      $('.billing-address-section').show()
      $('.billing-selection').show()
      $('.backend-order-use-billing').hide()
    else
      $('.shipping-address-section').hide()
      $('.billing-address-section').hide()
      $('.backend-order-use-billing').hide()
      if @sameBillingAsShipping
        $('.billing-selection').hide()
      else
        $('.billing-selection').show()

  hideBilling: (e) ->
    @sameBillingAsShipping = $('#order_use_billing_false').is(":checked")
    @$('.billing-selection').stop( true , false ).slideUp()

  showBilling: (e) ->
    @sameBillingAsShipping = $('#order_use_billing_false').is(":checked")
    @$('.billing-selection').stop( true , false ).slideDown()

  addLineItem: ->
    return if _.isEmpty(@$('.line-item select.product-id-selector').last().val())

    tpl = $("#new-line-item-template").html()
    new_id = new Date().getTime()
    regexp = new RegExp("new_line_item", "g")
    el = $(unescapeHTML(tpl.replace(regexp, new_id))).appendTo(@$("#line_items"))
    new App.Views.BackendOrderItemView({ el: $(el), parent: this })

  updateTotals: ->
    subtotal = 0
    @$('.line-item').each ->
      subtotal = subtotal + $(this).data('view').subtotal if $(this).data('view')?
    formatted_total = accounting.formatMoney(subtotal, "€", 2, ".", ",") # FIXME: allow to use with other currencies
    @$('.total.price').html(formatted_total)

  initLineItems: ->
    view = this
    @$('.line-item').each ->
      new App.Views.BackendOrderItemView({ el: $(this), parent: view })
