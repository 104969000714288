(function($R) {
  $R.add('plugin', 'more', {
    init: function(app) {
      this.app = app;

      // define toolbar service
      this.toolbar = app.toolbar;
      this.inline = app.inline;
      this.inspector = app.inspector;
      this.selection = app.selection;
    },
    onbutton: {
      more: {
        observe: function(button) {
          this._showBlockdata(button);
        },
      },
    },
    ondropdown: {
      more: {
        observe: function(button) {
          this._enableButtons(button);
        },
      },
    },
    start: function() {
      // create dropdown object
      // prettier-ignore
      var dropdownData = {
        observe: 'more',
        'widget': {
          title: '<i class="re-icon-widget"></i> Insert Embed Code',
          api: 'plugin.widget.open',
        },
        'line': {
          title: '<i class="re-icon-line"></i> Insert Horizontal Line',
          api: 'module.line.insert',
        },
        'properties': {
          title: '<i class="re-icon-properties"></i> Edit HTML Properties',
          api: 'plugin.properties.open',
          observe: 'more',
        },
        'ltr': {
          title: '<i class="fas fa-arrow-from-left"></i> Text Left-to-Right',
          api: 'plugin.textdirection.set',
          args: 'ltr',
        },
        'rtl': {
          title: '<i class="fas fa-paragraph-rtl"></i> Text Right-to-Left',
          api: 'plugin.textdirection.set',
          args: 'rtl',
        },
        'undo': {
          title: '<i class="re-icon-undo"></i> Undo Last Edit',
          api: 'module.buffer.undo',
        },
        'redo': {
          title: '<i class="re-icon-redo"></i> Redo Last Edit',
          api: 'module.buffer.redo',
        },
      };

      this.$button = this.toolbar.addButton('more', {
        title: 'Other functions',
        observe: 'more',
      });
      this.$button.setIcon('<i class="fas fa-ellipsis-h"></i>');
      this.$button.setDropdown(dropdownData);
    },
    _showBlockdata: function(_button) {
      var block = this.selection.getBlock();
      var data = this.inspector.parse(block);

      if (block && !data.isComponent()) {
        var blockData = this.app.api('plugin.properties._getData', block);
        this.app.api('plugin.properties._showData', block, blockData);
      } else {
        this.app.api('plugin.properties._hideLabel');
      }
    },
    _enableButtons: function(toolbar) {
      var current = this.selection.getCurrent();
      var data = this.inspector.parse(current);
      var block = this.selection.getBlock();
      var data = this.inspector.parse(block);
      var propertiesButton = toolbar.getItem('properties');
      var widgetButton = toolbar.getItem('widget');

      if (propertiesButton) {
        if (block && !data.isComponent()) propertiesButton.enable();
        else propertiesButton.disable();
      }

      if (widgetButton) {
        if (data.isComponentType('table')) widgetButton.disable();
        else widgetButton.enable();
      }
    },
  });
})(Redactor);
