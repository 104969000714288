import { Controller } from "@hotwired/stimulus";

export class TaggableController extends Controller {
  connect() {
    window.$(this.element).selectize({
      plugins: ["drag_drop", "remove_button"],
      delimiter: ",",
      persist: false,
      create: (input) => ({
        value: input,
        text: input,
      }),
    });
  }
}

export default TaggableController;
