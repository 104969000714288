import $ from "jquery";
import _ from "underscore";

class App.Views.EditPageRepeatableView extends Backbone.View
  initialize: (options) ->
    @parent = options.parent
    _.bindAll(this, "duplicateRepeatable", "destroyRepeatableItem", "moveUpRepeatableItem", "moveDownRepeatableItem", "toggleRepeatableContent")

    @$("input:file, input:checkbox, input:radio").each (el) ->
      parent = $(this).closest('div')
      if not (parent.hasClass('selector') || parent.hasClass('checker'))
        $(this).uniform()

    UI.selectize(@$('select.selectizable:not(.selectized)'))
    UI.selectizeWithSearch(@$('select.selectizable-with-search:not(.selectized)'))

    @$('.canvas-wrapper textarea.wysiwyg').addClass('skip-auto-redactize')
    @$('.open textarea.wysiwyg').removeClass('skip-auto-redactize')

    UI.setupRedactor(@$('textarea.wysiwyg:not(.skip-auto-redactize):not(.redactor-initialized)'))
    UI.enableCheckedElement($(@el))

  events:
    "confirm:complete .remove > a" : "destroyRepeatableItem"
    "click .move-up > a"           : "moveUpRepeatableItem"
    "click .move-down > a"         : "moveDownRepeatableItem"
    "click .clone > a"             : "duplicateRepeatable"
    "click .repeatable-header > label" : "toggleRepeatableContent"
    "click .repeatable-header > .content-summary" : "toggleRepeatableContent"
    "click .repeatable-header > .repeatable-collapse-icons" : "toggleRepeatableContent"

  duplicateRepeatable: (e) ->
    e.stopImmediatePropagation()
    $target = @$(e.currentTarget)
    repeatableId = $target.data('repeatable-id')
    canvasTemplateId = $target.data('canvas-template-id')
    canvasUUID = $target.data('canvas-uuid')

    $repeatable = $(e.currentTarget).closest("li.repeatable-wrapper")
    position = $repeatable.index()

    @parent.addRepeatable(e, {
      cloneFrom: @el, 
      repeatableId: repeatableId
      canvasTemplateId: canvasTemplateId
      canvasUUID: canvasUUID
      position: position + 1
    })

  destroyRepeatableItem: (e, confirmed) ->
    link = e.currentTarget
    $(link).prev().find("input[type=hidden]").val("1")
    $(link).closest("li.repeatable-wrapper").hide().addClass('hidden')

  scrollToElement: ($element) ->
    # only if the element got ouside the view window
    headerHeight = $('.page-header').height()
    docViewTop = $(window).scrollTop() - headerHeight
    docViewBottom = docViewTop + $(window).height()

    elemTop = $element.offset().top
    elemBottom = elemTop + $element.height()

    if (elemBottom > docViewBottom) || (elemTop < docViewTop)
      $('html, body').animate { scrollTop: elemTop - headerHeight - 50}, 1000

  moveUpRepeatableItem: (e) ->
    debug.log "moveUpRepeatableItem"

    # Stop event here.
    e.stopImmediatePropagation()

    link = e.target
    $repeatable = $(link).closest("li.repeatable-wrapper")
    current_canvas_index = $repeatable.data('current-canvas')
    $list = $repeatable.parent()
    element = $repeatable[0]

    if element.previousElementSibling
      element.parentNode.insertBefore(element, element.previousElementSibling);
      this.scrollToElement($repeatable)
      cnt = 0
      $list.find('.repeatable-wrapper').each ->
        item = $(this)
        item.find("input.position-tracker[data-current-canvas=#{current_canvas_index}]").attr('value',cnt)
        cnt++

    @highlightMoved(e)

  moveDownRepeatableItem: (e) ->
    debug.log "moveDownRepeatableItem"

    # Stop event here.
    e.stopImmediatePropagation()

    link = e.target
    $repeatable = $(link).closest("li.repeatable-wrapper")
    current_canvas_index = $repeatable.data('current-canvas')
    $list = $repeatable.parent()
    element = $repeatable[0]
    debug.log "this: " + element.id

    if element.nextElementSibling
      debug.log "this: " + element.nextElementSibling.id
      element.parentNode.insertBefore(element.nextElementSibling, element);
      this.scrollToElement($repeatable)
      cnt = 0
      $list.find('.repeatable-wrapper').each ->
        item = $(this)
        item.find("input.position-tracker[data-current-canvas=#{current_canvas_index}]").attr('value',cnt)
        cnt++

    @highlightMoved(e)

  toggleRepeatableContent: (e) ->
    e.stopImmediatePropagation()
    parent = $(e.target).closest('.repeatable-content')
    if parent.hasClass('open')
      parent.removeClass('open')
      UI.destroyRedactor(@$('textarea.wysiwyg.manual'))
    else
      UI.setupRedactor(@$('textarea.wysiwyg.manual'))
      parent.addClass('open')
    @parent.saveBlockVisibility()

  highlightMoved: (e) ->
    debug.log "highlightMoved"
    header = $(e.target).closest('.repeatable-header')
    header.addClass('moved')
    setTimeout(->
      header.removeClass('moved')
    ,
      1000
    )
