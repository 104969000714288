import $ from "jquery";
import { setupPhoneInput } from "../../utils";

class App.Views.ShopSettingsView extends Backbone.View
  initialize: ->
    view = $(@el)
    view.data('view',this)

    setupPhoneInput(@$('#site_address_attributes_phone'))

  events:
    "change input#site_watermark_enabled"              : "changeWatermarkSetting"
    "change input#site_enable_cart_expiration"         : "changeCartExpiration"
    "change input#site_enable_credits"                 : "changeCreditsSetting"
    "change input#site_enable_referral_reward_credits" : "changeReferralSystem"
    "change input#site_enable_cart_recovery"           : "changeAbandonedCartRecovery"
    "change input#site_reservations_enabled"           : "changeReservations"
    "change .site-address-selector select"             : "changeCountry"

  changeWatermarkSetting: ->
    @$('.watermark-code-subsection').toggle()

  changeCartExpiration: ->
    @$('.cart-expiration-subsection').toggle()

  changeAbandonedCartRecovery: ->
    @$('.abandoned-cart-recovery-subsection').toggle()

  changeCreditsSetting: ->
    if @$('#site_enable_referral_reward_credits').val() == "true"
      @$('.input.site_enable_referral_reward_credits.check_element').click()

  changeReferralSystem: ->
    @$('.referral-rewards-subsection').toggle()

  changeReservations: =>
    @$('.reservations-subsection').toggle()

  changeCountry: ->
    select_wrapper = @$('#site_state_code_wrapper')
    $('select', select_wrapper).attr('disabled', true)
    country_code = @$('.site-address-selector select').val()
    url = "#{select_wrapper.data('subregion-url')}?parent_region=#{country_code}"
    select_wrapper.load(url, ->
      $('#site_state_code_wrapper .selectizable').selectize()
    )
