import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import Clipboard from "clipboard";

export default class extends Controller {
  connect() {
    const copyURL = $(this.element).find(".copy-url");
    if (copyURL.length > 0) {
      const clipboard = new Clipboard(copyURL[0]);
      clipboard.on("success", (e) => UI.showFlash($(e.trigger).data("message")));

      copyURL.on("click", (e) => e.preventDefault());
    }
  }
}
