import $ from "jquery";
import Clipboard from "clipboard";

class App.Views.EditThemeView extends Backbone.View
  initialize: ->
    $("form.code-form").each ->
      new App.Views.CodeFormView({el: $(this)})

    if $('.copy-token').length > 0
      clipboard = new Clipboard(".copy-token");
      clipboard.on('success', (e) ->
        UI.showFlash($(e.trigger).data('message'))
      )

      $(".copy-token").click (e) ->
        e.preventDefault()

  events:
    "click .asset-type" : "toggleAssetList"
    "click .asset-directory .dir" : "toggleAssetDirectory"

  toggleAssetList: (e) ->
    title = $(e.currentTarget)
    target = title.next()
    if target.is('ul')
      if target.is(':hidden')
        title.addClass("open")
        title.removeClass("closed")
      else
        title.addClass("closed")
        title.removeClass("open")
      target.slideToggle('slow','easeOutExpo', (e) ->
        if $(this).is(':hidden')
          state = "closed"
        else
          state = "open"
        url = title.data('state-url')
        element = title.data('asset-type')
        data =
          preferences: {}
        data.preferences[element] = state
        $.post(url,data)
      )
  toggleAssetDirectory: (e) ->
    title = $(e.currentTarget)
    debug.log title
    target = title.next()
    if target.is('ul')
      if title.parent().hasClass('open')
        title.parent().removeClass('open')
        title.parent().addClass('closed')
      else
        title.parent().removeClass('closed')
        title.parent().addClass('open')
      target.slideToggle('slow','easeOutExpo', (e) ->
        if $(this).is(':hidden')
          state = "closed"
        else
          state = "open"
        url = title.data('state-url')
        element = title.data('asset-type')
        data =
          preferences: {}
        data.preferences[element] = state
        $.post(url,data)
      )
