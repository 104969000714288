import $ from "jquery";
import _ from "underscore";

class App.Views.EditMenuView extends Backbone.View
  initialize: ->
    @$("input:file, input:checkbox, input:radio").each (el) ->
      parent = $(this).closest('div')
      if not (parent.hasClass('selector') || parent.hasClass('checker'))
        $(this).uniform()

    view = this
    @$(".menu-item").each ->
      new App.Views.EditMenuItemView({el: $(this), parent: view})

    this.hideOrShowDestroyButtons()

    sortableOptions =
      tabSize: 30
      maxLevels: 0
      cursor: "move"
      forcePlaceholderSize: true
      handle: ".drag-handle"
      items: "li"
      opacity: .6
      listType: "ol"
      revert: 80
      revertOnError: true
      tolerance: "pointer"
      # disableNesting: "no-nesting-yet"
      toleranceElement: "> *"
      update: (event, ui) ->
        view.updateItemPositions()

    # userAgent = navigator.userAgent.toLowerCase()
    # if userAgent.match(/firefox/)
    #   options["start"] = (event, ui) ->
    #     ui.item.css('margin-top', $(window).scrollTop() )
    #   options["beforeStop"] = (event, ui) ->
    #     ui.item.css('margin-top', 0 )

    @$("#menu_item_list .item-list").nestedSortable(sortableOptions)

  events:
    "keypress input.field-label" : "setAlias"
    "keypress input.field-alias" : "slugifyAlias"
    "change select.field-kind"   : "changedKind"
    "click .add-item > a"        : "addMenuItem"

  setAlias: (e) ->
    obj = @$('.field-label')
    target = @$('.field-alias')
    if !target.closest("div").hasClass("manually")
      slug = obj.val().replace(/\s+/g,'_').replace(/[^a-zA-Z0-9\_]/g,'').toLowerCase()
      target.val(slug)
      if not _.isEmpty(slug)
        target.closest('.input').addClass('hastext')
      else
        target.closest('.input').removeClass('hastext')

  slugifyAlias: (e) ->
    if e.keyCode in [16,17,18,91,93,37,38,39,40,224] # shift, ctrl, alt, cmd, arrows
      return
      
    obj = @$('.field-alias')
    obj.closest('div').addClass('manually')
    slug = obj.val().replace(/\s+/g,'_').replace(/[^a-zA-Z0-9\_]/g,'').toLowerCase()
    obj.val(slug)

  changedKind: (e) ->
    obj = @$('.field-kind')
    if obj.val() == "category"
      @$('.edit-categories').show()
      @$('.field-auto-expand').show()
    else
      @$('.edit-categories').hide()
      @$('.category-field').remove()
      @$('.field-auto-expand').hide()
    if obj.val() == "multi_category"
      @$('.edit-multi-categories').show()
    else
      @$('.edit-multi-categories').hide()
      @$('.category-field').remove()

  updateItemPositions: ->
    cnt = 0
    @$('.menu-item-wrapper').each ->
      item = $(this)
      id = item.attr('id')
      item.find("input.position-#{id}").attr('value',cnt)
      if item.find('ol li').length > 0
        item.find('ol li').each ->
          subitem = $(this)
          subitem_id = subitem.attr('id')
          subitem.find("input.parent-id-#{subitem_id}").attr('value',id)
      cnt++

  addMenuItem: (e) ->
    view = this
    link = e.target
    debug.log link
    tpl = $("#new-menu-item-template").html()
    new_id = new Date().getTime()
    regexp = new RegExp("new_menu_item", "g")
    el = $(tpl.replace(regexp, new_id)).appendTo(@$("#menu_item_list .item-list"))
    new App.Views.EditMenuItemView({el: $(el), parent: view })
    this.hideOrShowDestroyButtons()
    this.updateItemPositions()

  hideOrShowDestroyButtons: ->
    if @$("#menu_item_list .menu-item:not(.hidden)").length < 2
      @$("#menu_item_list .menu-item").each ->
        $(this).find('.remove').hide()
    else
      @$("#menu_item_list .menu-item").each ->
        $(this).find('.remove').show()
