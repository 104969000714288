(function($R) {
  $R.add('plugin', 'clearformatting', {
    init: function(app) {
      this.app = app;

      // define toolbar service
      this.toolbar = app.toolbar;
      this.inline = app.inline;
    },
    start: function() {
      // set up the button
      var buttonData = {
        title: 'Clear Formatting',
        api: 'plugin.clearformatting.cleanHtml',
      };

      // add the button to the toolbar
      var $button = this.toolbar.addButton('clearformatting', buttonData);
      $button.setIcon('<i class="fas fa-remove-format"></i>');
    },
    cleanHtml: function() {
      this.inline.clearFormat();
      this.inline.clearAttr();
      this.inline.clearClass();

      return;
    },
  });
})(Redactor);
