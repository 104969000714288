import $ from "jquery";
import _ from "underscore";

class App.Views.OrderEditInvoiceView extends Backbone.View      
  initialize: ->
    window.Nimbu.inlineInputs()
    _.bindAll(this, "fieldAddedOrRemoved");

  events:
    "nested:fieldAdded":     "fieldAddedOrRemoved"
    "nested:fieldRemoved":     "fieldAddedOrRemoved"

  fieldAddedOrRemoved: (e) ->
    window.UI.resetModal()
    window.Nimbu.inlineInputs()
