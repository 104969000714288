import $ from "jquery";

class App.Views.GeneralSettingsView extends Backbone.View
  initialize: ->
    view = $(@el)
    view.data('view',this)

  events:
    "change input#site_private_mode"           : "changePrivateMode"
    "change input#site_ip_restriction"         : "changeIpRestriction"
    "change input#site_settings_attributes_enable_hsts" : "changeHSTS"

  changePrivateMode: ->
    @$('.private-mode-subsection').toggle()

  changeIpRestriction: ->
    @$('.ip-restriction-subsection').toggle()

  changeHSTS: ->
    @$('.enable-hsts-subsection').toggle()
