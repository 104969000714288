import $ from "jquery";
import Clipboard from "clipboard";

class App.Views.AppsIndexView extends Backbone.View
  initialize: ->
    if $('.copy-token').length > 0
      clipboard = new Clipboard(".copy-token");
      clipboard.on('success', (e) ->
        UI.showFlash($(e.trigger).data('message'))
      )

      $(".copy-token").click (e) ->
        e.preventDefault()
