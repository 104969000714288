import $ from "jquery";

class App.Views.EditCollectionCriteriaView extends Backbone.View
  initialize: (options) ->
    @options = options
    @$('select').selectize(
      dataAttr: "data-json",
      onChange: (value) ->
        # if (!value.length) return;
        selectize = this;
        data = selectize.options[value];
        console.log(data);
    )

  events:
    "click .remove > a" : "destroyCondition"
    "change .type-selector select"                    : "changedType"
    "change .special-selector select"                 : "changedSpecialSelector"

  destroyCondition: (e) ->
    $(this.el).addClass('hidden')
    link = e.target
    $(link).prev().find("input[type=hidden]").val("1")
    $(link).closest(".condition").hide()
    @options.parent.hideOrShowDestroyButtons()

  changedType: (e) ->
    selector = $(e.target)
    selectize = selector[0].selectize
    value = selectize.getValue()

    if value?
      selectedOption = selectize.getItem(value)
      console.log('selectedOption', selectedOption)
      console.log('data', selectedOption.data.type)
      console.log('data', selectedOption.type)

    if value? && @$(".special-selector.#{value}-selector").length > 0
      @$(".special-selector").hide()
      @$(".#{value}-selector").show()
      @$(".regular-selector").hide()
      @$(".regular-selector").addClass("hastext")
      @$(".regular-selector input").val(@$(".#{value}-selector").find('option:selected').text())
      @$(".special-field-value-field").val(@$(".#{value}-selector").find('option:selected').val())
    else
      @$(".special-field-value-field").val("")
      @$(".special-selector").hide()
      @$(".regular-selector").show()

  changedSpecialSelector: (e) ->
    selector = $(e.target)
    text = selector.find('option:selected').text()
    value = selector.find('option:selected').val()

    @$(".regular-selector").addClass("hastext")
    @$(".regular-selector input").val(text)
    @$(".special-field-value-field").val(value)
