import { Controller } from "@hotwired/stimulus";
import { Dropzone } from "dropzone";

import "dropzone/dist/dropzone.css";

// TODO: localize these strings
// Dropzone.prototype.defaultOptions.dictDefaultMessage = "Drop files here to upload";
// Dropzone.prototype.defaultOptions.dictFallbackMessage = "Your browser does not support drag'n'drop file uploads.";
// Dropzone.prototype.defaultOptions.dictFallbackText = "Please use the fallback form below to upload your files like in the olden days.";
// Dropzone.prototype.defaultOptions.dictFileTooBig = "File is too big ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB.";
// Dropzone.prototype.defaultOptions.dictInvalidFileType = "You can't upload files of this type.";
// Dropzone.prototype.defaultOptions.dictResponseError = "Server responded with {{statusCode}} code.";
// Dropzone.prototype.defaultOptions.dictCancelUpload = "Cancel upload";
// Dropzone.prototype.defaultOptions.dictCancelUploadConfirmation = "Are you sure you want to cancel this upload?";
// Dropzone.prototype.defaultOptions.dictRemoveFile = "Remove file";
// Dropzone.prototype.defaultOptions.dictMaxFilesExceeded = "You can not upload any more files.";

export default class extends Controller {
  static targets = ["dropzone"];

  connect() {
    let dropzone = new Dropzone("#dropzone", {
      url: this.element.action,
      maxFiles: 1,
      maxFilesize: 10,
      paramName: "file",
      disablePreviews: true,
      acceptedFiles:
        "text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      headers: {
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        Accept: "application/javascript",
      },
    });
    // use legacy js.erb response
    dropzone.on("success", (file, response) => {
      eval(response);
    });
  }
}
