import React from "react";
import { Controller } from "@hotwired/stimulus";
import { JsonViewer } from "@textea/json-viewer";
import { render } from "react-dom";

export default class extends Controller {
  static targets = ["container"];
  static values = {
    json: String,
    id: String,
    collapsed: Boolean,
  };

  connect() {
    if (this.hasContainerTarget) {
      this.render(this.containerTarget.dataset.triggerModalResize === "true");
    }
  }

  render(renderedInModal = false) {
    const WrapperWithCallback = ({ children }) => {
      React.useEffect(() => {
        if (renderedInModal) {
          UI.resetModal();
          UI.resizeModal();
        }
      }, []);

      return children;
    };

    render(
      <WrapperWithCallback>
        <JsonViewer
          value={this.json()}
          rootName={false}
          collapseStringsAfterLength={100}
          displayDataTypes={false}
          defaultInspectDepth={this.collapsedValue ? 0 : undefined}
        />
      </WrapperWithCallback>,
      this.containerTarget
    );
  }

  json() {
    try {
      return JSON.parse(this.jsonValue);
    } catch (e) {
      return {};
    }
  }
}
