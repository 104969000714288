import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export class LightboxController extends Controller {
  connect() {
    $(this.element).magnificPopup({
      type: "image",
      mainClass: "my-mfp-zoom-in",
      image: {
        markup:
          '<div class="mfp-figure">' +
          '<div class="mfp-close"></div>' +
          '<div class="mfp-img"></div>' +
          '<div class="mfp-bottom-bar">' +
          '<div class="mfp-title"></div>' +
          '<div class="mfp-counter"></div>' +
          "</div>" +
          "</div>",
        cursor: "mfp-zoom-out-cur",
        titleSrc: "title",
        verticalFit: true,
        tError: '<a href="%url%">The image</a> could not be loaded.',
      },
      zoom: {
        enabled: true,
        duration: 300,
        easing: "ease-in-out",
        opener: function (openerElement) {
          if (openerElement.is("img")) {
            return openerElement;
          } else {
            return openerElement.find("img");
          }
        },
      },
    });
  }
}

export default LightboxController;
