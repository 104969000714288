import $ from "jquery";
import _ from "underscore";
import { setupPhoneInput } from "../../utils";

class App.Views.OrderEditAddressView extends Backbone.View
  initialize: ->
    window.Nimbu.inlineInputs()
    _.bindAll(this, "hideBilling", "showBilling");

    setupPhoneInput(@$('#order_billing_address_attributes_phone'))
    setupPhoneInput(@$('#order_shipping_address_attributes_phone'))

  events:
    "change #order_use_billing_false":     "hideBilling"
    "change #order_use_billing_true" :     "showBilling"

  hideBilling: (e) ->
    @$('.billing-selection').stop( true , false ).slideUp()
    UI.resetModal()

  showBilling: (e) ->
    @$('.billing-selection').stop( true , false ).slideDown()
    UI.resetModal()
