import $ from "jquery";
import _ from "underscore";

class App.Views.NotificationPreviewView extends Backbone.View
  initialize: (options)->
    @el = options.el
    _.bindAll(this, "changedViewport", "refreshPreviewData", "changedPreviewObject");
    doc = @$('#previewFrame')[0].contentWindow.document;
    content = @$('#previewContent').html()
    doc.open();
    doc.write(content);
    doc.close();

  events:
    "click .responsive-switcher button": "changedViewport"
    "click .refresh-data": "refreshPreviewData"
    "change .preview-object select":     "changedPreviewObject"

  changedViewport: (e) ->
    e.preventDefault()
    $target = $(e.currentTarget)
    @$('.responsive-switcher button.active').removeClass('active')
    $target.addClass('active')

    if $target.hasClass('mobile')
      @$('.mail-body iframe').css('width', '320px')
    else
      @$('.mail-body iframe').css('width', '100%')
  
  changedPreviewObject: (e) ->
    data = JSON.parse(@$('#previewData').html())
    object_id = @$('.preview-object select').val()
    data.preview_object = object_id
    url = @el.data('preview-url')

    $.post(
      url,
      data,
      (data, textStatus, jqXHR) -> {},
      'script'
    )
  
  refreshPreviewData: (e) ->
    data = JSON.parse(@$('#previewData').html())

    IsJsonString = (str) ->
      try
        JSON.parse str
      catch e
        return false
      true

    @$('.preview-object-json input').removeClass('error')
    json = @$('.preview-object-json input').val()
    url = @el.data('preview-url')

    if IsJsonString(json)
      @$('.refresh-data').addClass('fa-spin')
      data.preview_object_json = json

      $.post(
        url,
        data,
        (data, textStatus, jqXHR) -> {},
        'script'
      )

    else
      @$('.preview-object-json input').addClass('error')
