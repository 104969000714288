import $ from "jquery";

class App.Views.EditChannelView extends Backbone.View
  initialize: (options) ->
    new App.Views.EditCustomFieldsView({el: options.el})

  events:
    "change #channel_submittable": "changedSubmittable"
    "change #channel_rss_enabled": "changedRSSEnabled"
    "change #channel_submittable_notifications": "changedNotificationsEnabled"
    "change #channel_submittable_confirmations": "changedConfirmationsEnabled"

  changedSubmittable: (e) ->
    @$('.submittable-fields').toggle()

  changedNotificationsEnabled: (e) ->
    @$('.submittable-notifications').toggle()
  
  changedConfirmationsEnabled: (e) ->
    @$('.submittable-confirmations').toggle()

  changedRSSEnabled: (e) ->
    @$('.rss-fields').toggle()
