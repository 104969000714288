import $ from "jquery";
import { setupPhoneInput } from "../../utils";

class App.Views.UserSettingsView extends Backbone.View
  initialize: ->
    view = $(@el)
    view.data('view',this)

    @iti = setupPhoneInput(@$('#authy-phone-number'))

  events:
    "change input#permissions_limited_access" : "changePermissions"
    "keyup input#authy-phone-number"          : "changedPhoneNumber"

  changePermissions: ->
    @$('.limited-access-subsection').toggle()

    # only needed for the invitation modal
    UI.resizeModal()

  changedPhoneNumber: ->
    country = @iti.getSelectedCountryData()
    cleanData = @iti.getNumber()

    if country? && cleanData? && cleanData != ""
      phoneNumber = cleanData.replace("+#{country.dialCode}","")

      @$('#authy-countries').attr('value', country.dialCode)
      @$('#authy-cellphone').attr('value', phoneNumber)
    else
      @$('#authy-countries').attr('value', '')
      @$('#authy-cellphone').attr('value', '')
