import $ from "jquery";

class App.Views.ShippingSettingsView extends Backbone.View
  initialize: ->
    view = $(@el)
    view.data('view',this)

  events:
    "change #general_shipping_settings_form input" : "shippingSettingsChanged"

  shippingSettingsChanged: (e) ->
    @$('#general_shipping_settings_form').submit()
