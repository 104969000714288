import $ from "jquery";
import _ from "underscore";
import accounting from "accounting";
import Cleave from "cleave.js";

class App.Views.BackendOrderItemView extends Backbone.View
  initialize: (options) ->
    @options = options
    view = this
    $(this.el).data('view',view)

    @parent = @options.parent
    @price = 0
    @quantity = parseInt(@$('.quantity > input').val())
    @subtotal = 0

    this.initProductSelector()

    if @$('.override-unit-price').val()? && @$('.override-unit-price').val() != ''
      @price = parseFloat(@$('.override-unit-price').val())
    else
      this.updatedProduct()
      this.updatedVariant()

    cleave = new Cleave($('.override-unit-price')[0], {
      numeral: true,
      numeralPositiveOnly: true,
      numeralThousandsGroupStyle: 'none'
    });

    selected_variant = @$('.variant-id-selector').data('selected-variant')
    if selected_variant?
      @$('.variant-id-selector')[0].selectize.setValue(selected_variant)

    this.updateSubtotals()

  events:
    "change .quantity > input"   : "quantityChanged"
    "click .remove > a"          : "removeItem"
    "click .price > span"        : "showUnitPriceInput"
    "keyup .override-unit-price" : "changeUnitPrice"
    "blur  .override-unit-price" : "formatUnitPrice"

  removeItem: (e) ->
    $(this.el).remove()
    @parent.updateTotals()

  showUnitPriceInput: (e) ->
    @$('.price > span').hide()
    @$('.override-unit-price').show()
    @$('.override-unit-price').removeClass('hidden')
    @$('.override-unit-price').focus()
  
  formatUnitPrice: (e) ->
    Nimbu.formatPriceField($(e.target))
  
  changeUnitPrice: (e) ->
    @price = parseFloat(@$('.override-unit-price').val()) 
    @updateSubtotals()
  
  resetUnitPrice: (e) ->
    @$('.price > span').show()
    @$('.price > span').removeClass('hidden')
    @$('.override-unit-price').hide()
    @$('.override-unit-price').val('')

  quantityChanged: (e) ->
    this.quantity = parseInt(@$('.quantity > input').val())
    this.updateSubtotals()

  updateSubtotals: ->
    @subtotal = @price * @quantity

    formatted_price = accounting.formatMoney(@price, "€", 2, ".", ",") # FIXME: allow to use with other currencies
    formatted_subtotal = accounting.formatMoney(@subtotal, "€", 2, ".", ",") # FIXME: allow to use with other currencies

    @$('td.price > span').html(formatted_price)
    @$('td.subtotal').html(formatted_subtotal)
    @parent.updateTotals()

  updatedProduct: (value) ->
    view = this
    element = $(@el)
    product_selector = @$('.product-id-selector')[0].selectize
    variant_selector = @$('.variant-id-selector')[0].selectize

    value = product_selector.getValue() unless value?
    json = product_selector.getOption(value).data("json")
    json = product_selector.options[value] unless json?
    return unless json?

    if json.variants? && json.variants.length > 0
      element.find('.product').addClass('with-variants')
      variant_selector.clearOptions()
      for variant in json.variants
        variant_selector.addOption(variant)
      variant_selector.setValue(json.variants[0].id)
    else
      variant_selector.clearOptions()
      element.find('.product').removeClass('with-variants')

    view.price = json.effective_price
    if value?
      view.resetUnitPrice()
    view.updateSubtotals()
    view.$('.remove').show()

  updatedVariant: (value) ->
    view = this
    element = $(@el)
    variant_selector = @$('.variant-id-selector')[0].selectize

    value = variant_selector.getValue() unless value?
    json = variant_selector.getOption(value).data("json")
    json = variant_selector.options[value] unless json?
    return unless json?

    view.price = json.effective_price
    if value?
      view.resetUnitPrice()
    view.updateSubtotals()
    view.$('.remove').show()

  initProductSelector: ->
    view = this
    element = $(@el)
    options = []
    ids = []
    variant_options = []
    variant_ids = []

    parseUri = (str) ->
      o = parseUri.options
      m = o.parser[(if o.strictMode then "strict" else "loose")].exec(str)
      uri = {}
      i = 14
      uri[o.key[i]] = m[i] or ""  while i--
      uri[o.q.name] = {}
      uri[o.key[12]].replace o.q.parser, ($0, $1, $2) ->
        uri[o.q.name][$1] = $2  if $1
        return

      uri

    parseUri.options =
      strictMode: false
      key: [
        "source"
        "protocol"
        "authority"
        "userInfo"
        "user"
        "password"
        "host"
        "port"
        "relative"
        "path"
        "directory"
        "file"
        "query"
        "anchor"
      ]
      q:
        name: "queryKey"
        parser: /(?:^|&)([^&=]*)=?([^&]*)/g

      parser:
        strict: /^(?:([^:\/?#]+):)?(?:\/\/((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?))?((((?:[^?#\/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/
        loose: /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/


    thumb = (url) ->
      components = parseUri(url)

      return "#{components.protocol}://#{components.host}/w_50,h_50,g_west,c_fill#{components.path}"

    @$('.product-id-selector option').each (i, el) ->
      options.push($(el).data('data'))

    @$('.product-id-selector').selectize
      theme: "products"
      valueField: "id"
      labelField: "name"
      searchField: ["name","description"]
      options: options
      create: false

      render:
        option: (item, escape) ->
          json = JSON.stringify(item)

          '<div class="sub-product-wrapper" data-json=\'' + escape(json).replace(/'/g, "&#39;") + '\'>' +
            ( if item.image? && item.image.url? then ('<img src="' + escape(thumb(item.image.url)) + '" alt="">') else ('<img src="' + escape(item.image) + '" alt="">')) +
            '<span class="title">' +
              '<span class="name">' + escape(item.name) + '</span>' +
            '</span>' +
            '<span class="description">' + item.description + '</span>' +
          '</div>'

      load: (query, callback) ->
        return callback()  unless query.length
        $.getJSON($('.product-id-selector').data('search-url'), { status: "active", search: query }, (res) ->
            callback res
          )

    @$('.variant-id-selector option').each (i, el) ->
      variant_options.push($(el).data('data'))
      variant_ids.push($(el).attr('value'))

    @$('.variant-id-selector').selectize
      theme: "product-variants"
      valueField: "id"
      labelField: "name"
      searchField: "name"
      options: variant_options
      create: false

      render:
        option: (item, escape) ->
          json = JSON.stringify(item)
          '<div class="sub-variants-wrapper" data-json=\'' + escape(json).replace(/'/g, "&#39;") + '\'>' +
            '<span class="title">' +
              '<span class="name">' + escape(item.name) + '</span>' +
            '</span>' +
          '</div>'

    product_selector = @$('.product-id-selector')[0].selectize
    variant_selector = @$('.variant-id-selector')[0].selectize

    product_selector.on('change', (value) ->
      view.updatedProduct(value)
      view.parent.addLineItem()
    )

    variant_selector.on('change', (value) ->
      view.updatedVariant(value)
    )
