import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
import { isMobile } from "./support/is-mobile";
import { v4 as uuid } from "uuid";

var Turbolinks = require("turbolinks");

import "underscore";
import "backbone";

import $ from "jquery";
import "@selectize/selectize";

window.$ = $;
window.jQuery = $;

// Load all our dependencies
import "./vendor.js";
import "./utils.js";

import "./support/add-box-shadow.js";

import "./nimbu.coffee";
import "./support/peek.coffee";
import "./views/apps/apps_index_view.coffee";
import "./views/apps/showtime/videos_index_view.coffee";
import "./views/blogs/edit_article_view.coffee";
import "./views/channels/edit_channel_view.coffee";
import "./views/channels/show_channel_view.coffee";
import "./views/collections/edit_collection_criteria_view.coffee";
import "./views/collections/edit_collections_view.coffee";
import "./views/coupons/coupons_edit_view.coffee";
import "./views/customers/customer_edit_address_view.coffee";
import "./views/dashboard/dashboard_view.coffee";
import "./views/links/edit_menu_item_view.coffee";
import "./views/links/edit_menu_view.coffee";
import "./views/media/uploads_view.coffee";
import "./views/orders/backend_order_item_view.coffee";
import "./views/orders/backend_order_view.coffee";
import "./views/orders/export_order_view.coffee";
import "./views/orders/order_edit_address_view.coffee";
import "./views/orders/order_edit_invoice_view.coffee";
import "./views/orders/show_order_view.coffee";
import "./views/orders/show_subscription_view.coffee";
import "./views/pages/edit_page_repeatable_view.coffee";
import "./views/pages/edit_page_view.coffee";
import "./views/pages/add_page_item_view.coffee";
import "./views/pages/pages_index_view.coffee";
import "./views/products/edit_product_attachment_view.coffee";
import "./views/products/edit_product_variant_settings_view.coffee";
import "./views/products/edit_product_view.coffee";
import "./views/products/product_attachment_view.coffee";
import "./views/products/product_pricing_scheme_price_point_view.coffee";
import "./views/products/product_pricing_scheme_view.coffee";
import "./views/products/product_variant_view.coffee";
import "./views/settings/domains_view.coffee";
import "./views/settings/edit_custom_field_authenticatable_view.coffee";
import "./views/settings/edit_domain_view.coffee";
import "./views/settings/edit_integration_view.coffee";
import "./views/settings/edit_notification_view.coffee";
import "./views/settings/edit_payment_method_view.coffee";
import "./views/settings/edit_shipping_address_view.coffee";
import "./views/settings/edit_shipping_rate_view.coffee";
import "./views/settings/general_settings_view.coffee";
import "./views/settings/integrations_view.coffee";
import "./views/settings/notification_preview_view.coffee";
import "./views/settings/notifications_view.coffee";
import "./views/settings/payment_methods_view.coffee";
import "./views/settings/retail_location_view.coffee";
import "./views/settings/shipping_settings_view.coffee";
import "./views/settings/shop_settings_view.coffee";
import "./views/settings/site_import_view.coffee";
import "./views/settings/tax_settings_view.coffee";
import "./views/settings/user_settings_view.coffee";
import "./views/themes/code_form_view.coffee";
import "./views/themes/edit_theme_view.coffee";
import "./views/various/collection_view.coffee";
import "./views/various/customizable_view.coffee";
import "./views/various/edit_custom_field_item_view.coffee";
import "./views/various/edit_custom_field_select_item_view.coffee";
import "./views/various/edit_custom_fields_view.coffee";
import "./views/various/exception_index_view.coffee";
import "./views/various/search_list_view.coffee";
import "./views/various/setup_apps_view.coffee";

import "./views/util/data-confirm-modal.js";

// Monkey-patch isHTML function so browsing cloud code works with Turbolinks
Turbolinks.Location.prototype.isHTML = function () {
  return this.getExtension().match(/^(?:|\.(?:htm|html|xhtml|js|coffee))$/);
};
Turbolinks.start();

window.Stimulus = Application.start();
const context = require.context("./controllers", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));

if (isMobile()) {
  var root = document.documentElement;
  root.className += " is-mobile";
} else {
  var root = document.documentElement;
  root.className += " is-desktop";
}

// hack to allow triggering both native and Jquery events
// based on https://stackoverflow.com/a/72105530
$.fn.triggerNative = function (type, data) {
  let event;
  this.each(function () {
    let thisEvent = new CustomEvent(type, {
      bubbles: true,
      cancelable: true,
    });
    if (!event) event = thisEvent;
    Object.assign(thisEvent, data);
    this.dispatchEvent(thisEvent);
  });
  return event;
};

window.Nimbu.uuid = uuid;
