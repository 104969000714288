import $ from "jquery";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submit"];

  connect() {
    UI.resizeModal();
    this.checkMappings();
  }

  checkMappings() {
    let ok = true;
    let $element = $(this.element);

    $element.find(".mapping").each((_, mapping) => {
      if (!$(mapping).hasClass("target-selected") && !$(mapping).hasClass("skipped")) {
        ok = false;
      }
    });

    if ($element.find(".mapping.skipped").length == $element.find(".mapping").length) {
      ok = false;
    }

    if (ok) {
      $(this.submitTarget).attr("disabled", false);
    } else {
      $(this.submitTarget).attr("disabled", true);
    }
  }

  select(event) {
    let $mapping = $(event.target).closest(".mapping");
    $mapping.removeClass("skipped");
    $mapping.addClass("importing");
    $mapping.find(".is-enabled").val("1");
    this.checkMappings();
  }

  deselect(event) {
    let $mapping = $(event.target).closest(".mapping");
    $mapping.addClass("skipped");
    $mapping.removeClass("importing");
    $mapping.find(".is-enabled").val("0");
    this.checkMappings();
  }

  deselect_unmapped(event) {
    $(this.element)
      .find(".mapping")
      .each((_, mapping) => {
        let $mapping = $(mapping);
        if (!$mapping.hasClass("target-selected")) {
          $mapping.addClass("skipped");
          $mapping.removeClass("importing");
          $mapping.find(".is-enabled").val("0");
        }
      });
    this.checkMappings();
  }

  mapped(event) {
    let $mapping = $(event.target).closest(".mapping");
    let selectedValue = event.target.value;
    if (selectedValue != null && selectedValue != "") {
      $mapping.addClass("target-selected");
    } else {
      $mapping.removeClass("target-selected");
    }

    this.checkMappings();
  }
}
