import $ from "jquery";
import _ from "underscore";
import Handlebars from "handlebars";

class App.Views.ProductAttachmentView extends Backbone.View
  initialize: ->
    _.bindAll(this, "showChangePathModal");

  events:
    "click .change-path-link"       : "showChangePathModal"

  showChangePathModal: ->
    template = Handlebars.compile($('#edit-attachment-template').html());

    variants_enabled = $("#product_variants_enabled").val() == "true"
    variants_specific_ids = $(@el).find('.variant-specific-input').val()
    data =
      id: $(@el).data('attachment-id'),
      path: $(@el).data('attachment-path')
      selected_variants: new String(variants_specific_ids != "")
      variants_specific_ids: variants_specific_ids
      variants_enabled: variants_enabled

    if variants_enabled
      data.variants = $('#variants_list .variant').map(
        (i,val) ->
          item = $(val)
          item_id = item.data('variant-id')
          re = new RegExp(item_id)
          return {
            id: item_id
            name: item.find('.label input').val()
            variant_uuid: item.find('input.variant-uuid').val()
            selected: variants_specific_ids.match(re)?
          }
      )

    code = template(data)
    callback = (modal) -> new App.Views.EditProductAttachmentView({el: $(modal), data: data})

    UI.showModal(code, callback)
