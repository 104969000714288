import $ from "jquery";

class App.Views.EditMenuItemView extends Backbone.View
  initialize: (options) ->
    @options = options
    @$("input:file, input:checkbox, input:radio").each (el) ->
      parent = $(this).closest('div')
      if not (parent.hasClass('selector') || parent.hasClass('checker'))
        $(this).uniform()

    this.changeTargetType()
    
    UI.selectize($("div:not('.template') select.selectizable:not(.selectized)"))

  events:
    "confirm:complete .remove > a" : "destroyMenuItem"
    "change .field-kind"    : "changeTargetType"

  changeTargetType: ->
    switch @$(".field-kind").val()
      when "page"
        @$(".target-field:not(.field-target-page)").each ->
          $(this).closest('.input').hide()
        @$(".field-target-page").closest('.input').show()
      when "url"
        @$(".target-field:not(.field-target-url)").each ->
          $(this).closest('.input').hide()
        @$(".field-target-url").closest('.input').show()
      when "channel"
        @$(".target-field:not(.field-target-channel)").each ->
          $(this).closest('.input').hide()
        @$(".field-target-channel").closest('.input').show()
        @$(".field-target-page").closest('.input').show()
      else
        @$(".target-field").each ->
          $(this).closest('.input').hide()

  destroyMenuItem: (e, confirmed) ->
    if confirmed
      link = e.currentTarget
      $(link).prev().find("input[type=hidden]").val("1")
      $(link).closest("li.inline").hide().addClass('hidden')
      @options.parent.hideOrShowDestroyButtons()
