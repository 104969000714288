class App.Views.AddPageItemView extends Backbone.View
  initialize: (options) ->
    @parent = options.parent
    @position = options.position
    @canvasUUID = options.canvasUUID

    $('.add-repeatable-item').data('canvas-uuid', @canvasUUID)
    $('.add-repeatable-item').attr('data-canvas-uuid', @canvasUUID)

    @$('[title]').each(() ->
      $el = $(this)
      options = {
        gravity: $el.data('gravity') || $el.data('tipsy-gravity') || 'w',
        html: $el.data('html') == true || $el.data('tipsy-html') == true,
        className: $el.data('tipsy-classname'),
        fade: true,
        live: false,
        delayIn: 50,
      }

      $el.tipsy(options)
    )

  events:
    "click .add-repeatable-item" : "addRepeatable"

  addRepeatable: (e) ->
    @parent.addRepeatable(e, position: @position)
