import Handlebars from "handlebars";
import $ from "jquery";

App.Views.CollectionView = Backbone.View.extend(
  initialize: ->
    input = @$('.search-product-wrapper input')
    template = @$('#product-autocomplete')
    if input.length > 0 && template.length > 0
      list_tmpl = Handlebars.compile(template.html())
      url = input.data('autocomplete-url')
      input.autocomplete(
        minLength: 0
        source: url
        appendTo: input.parent()
        position:
          my: "center top"
          at: "center bottom"
          collision: "none"
        focus: (event, ui) ->
          input.val(ui.item.name)
          false
        select: (event, ui) ->          
          input.val('')
          $.ajax(
            url: input.data('autocomplete-callback').replace('_id_', ui.item._id)
            type: 'POST'
            dataType: "script"
          )
          false
      ).data("autocomplete")._renderItem = (ul, item) ->
        if /http/.test(item.cover.mini)
          thumb = item.cover.icon
        else
          thumb = "/assets/" + item.cover.icon
        
        crnt_search = input.val()
        search_pattern = new RegExp("(#{crnt_search})","i")

        li = list_tmpl(
          thumb: thumb
          name: item.name.replace(search_pattern, "<span class='match'>$1</span>")
          description: item.description.replace(search_pattern, "<span class='match'>$1</span>")
        )
        
        $(li).data( "item.autocomplete", item ).appendTo ul
)
