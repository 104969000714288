import $ from "jquery";
import _ from "underscore";
import Handlebars from "handlebars";
import unescapeHTML from "underscore.string/unescapeHTML";

class App.Views.EditProductView extends Backbone.View
  initialize: ->
    view = this

    this.initPriceWatch()
    this.initAttachments()
    this.initImageUploader()
    this.initTaggable()
    this.initSubproductsSelector()
    this.initAttachmentUploaders()

    options =
      zIndex: 1000,
      items: '.product-image',
      axis: 'x',
      tolerance: 'pointer',
      update: (event, ui) ->
        cnt = 0
        $(this).find('.product-image').each ->
          el = $(this).find("input.position-field").attr('value',cnt)
          cnt++

    userAgent = navigator.userAgent.toLowerCase()
    if userAgent.match(/firefox/)
      options["start"] = (event, ui) ->
        ui.item.css('margin-top', $(window).scrollTop() )
      options["beforeStop"] = (event, ui) ->
        ui.item.css('margin-top', 0 )

    @$(".images-list").sortable(options)
    @$(".images-list").disableSelection();

    @$("#variants_list .variant").each ->
      new App.Views.ProductVariantView({el: $(this), parent: view})
    @$("#pricing_schemes_list .pricing-scheme").each ->
      new App.Views.ProductPricingSchemeView({el: $(this), parent: view})


  events:
    "change select.auto-expanding"                : "changedDropdown"
    "confirm:complete .product-image .destroy > a": "removeImage"
    "confirm:complete .attachment .remove > a"    : "removeAttachment"
    "change #product_on_sale"                     : "changeOnSale"
    "change #product_keep_stock"                  : "changeKeepStock"
    "change #product_variants_enabled"            : "changeVariantsEnabled"
    "change #product_aggregated"                  : "changeAggregation"
    "change #product_advanced_pricing_enabled"    : "changeAdvancedPricingEnabled"
    "change #product_charge_taxes"                : "changeChargeTaxes"
    "change #product_product_type_id"             : "changeProductType"
    "change #product_product_vendor_id"           : "changeProductVendor"
    "change #product_requires_deposit"            : "changeProductDeposit"
    "click .add-variant"                          : "addProductVariant"
    "click .add-pricing-scheme"                   : "addProductPricingScheme"
    "change .auto-enable input[type=checkbox]"    : "changeAutoEnable"
    "change .auto-disable input[type=checkbox]"   : "changeAutoDisable"
    "change .auto-hide input[type=checkbox]"      : "changeAutoHide"

  hideOrShowProductVariantDestroyButtons: ->
    if @$("#variants_list .variant:not(.hidden)").length < 2
      @$("#variants_list .variant").each ->
        $(this).find('.remove').hide()
    else
      @$("#variants_list .variant").each ->
        $(this).find('.remove').show()

  hideOrShowProductPricingSchemeDestroyButtons: ->
    if @$("#pricing_schemes_list .pricing-scheme:not(.hidden)").length < 2
      @$("#pricing_schemes_list .pricing-scheme").each ->
        $(this).find('.remove').hide()
    else
      @$("#pricing_schemes_list .pricing-scheme").each ->
        $(this).find('.remove').show()

  changeProductType: (e) ->
    target = $(e.currentTarget)
    if target.val() == "create_new"
      @$('.new-product-type').show()
    else
      @$('.new-product-type').hide()

  changeProductVendor: (e) ->
    target = $(e.currentTarget)
    if target.val() == "create_new"
      @$('.new-product-vendor').show()
    else
      @$('.new-product-vendor').hide()

  addProductVariant: (e) ->
    link = e.target
    tpl = $("#new-variant-template").html()
    new_id = new Date().getTime()
    regexp = new RegExp("new_variant", "g")
    el = $(unescapeHTML(tpl.replace(regexp, new_id))).appendTo(@$("#variants_list"))
    new App.Views.ProductVariantView({el: $(el), parent: this})
    this.hideOrShowProductVariantDestroyButtons()
    this.changeKeepStock()

  addProductPricingScheme: (e) ->
    link = e.target
    tpl = $("#new-pricing-scheme-template").html()
    new_id = new Date().getTime()
    regexp = new RegExp("new_pricing_scheme", "g")
    el = $(unescapeHTML(tpl.replace(regexp, new_id))).appendTo(@$("#pricing_schemes_list"))
    new App.Views.ProductPricingSchemeView({el: $(el), parent: this})
    this.hideOrShowProductPricingSchemeDestroyButtons()

  initPriceWatch: ->
    $(document).on "change", "input.price", ->
      Nimbu.formatPriceField($(this))

  changeOnSale: (e) ->
    #@$('#product_on_sale_price').closest('.input').toggle()
    #@$('.pricing-scheme .on-sale-price').toggle()

  changeKeepStock: (e) ->
    if @$('#product_keep_stock').val() == "true"
      @$('.current-stock-subsection').show()
      @$('.sold-out-subsection').show()
      @$('.product-variants-subsection .stock').show()
      if @$('#product_variants_enabled').is(':checked') == true
        @$('.current-stock-subsection').hide()
      else
        @$('.current-stock-subsection').show()
    else
      @$('.current-stock-subsection').hide()
      @$('.sold-out-subsection').hide()
      @$('.product-variants-subsection .stock').hide()

  changeVariantsEnabled: (e) ->
    if @$('#product_variants_enabled').val() == "true"
      @$('.product-variants-subsection').show()
      @$('.pricing-scheme-product-variant-ids').show()
      if @$('#product_keep_stock').val() == "true"
        @$('.current-stock-subsection').hide()
    else
      @$('.product-variants-subsection').hide()
      @$('.pricing-scheme-product-variant-ids').hide()
      if @$('#product_keep_stock').val() == "true"
        @$('.current-stock-subsection').show()
      else
        @$('.current-stock-subsection').hide()

  changeAggregation: (e) ->
    @$('.product-aggregates-subsection').toggle()

  changeAdvancedPricingEnabled: (e) ->
    if @$('#product_advanced_pricing_enabled').val() == "true"
      @$('.product-pricing-schemes-subsection').show()
    else
      @$('.product-pricing-schemes-subsection').hide()

  changeChargeTaxes: (e) ->
    @$('.product-taxes-subsection').toggle()

  changeProductDeposit: (e) ->
    @$('.product-deposit-subsection').toggle()

  changeAutoEnable: (e) ->
    @$('.auto-enable-subsection').toggle()

  changeAutoDisable: (e) ->
    @$('.auto-disable-subsection').toggle()

  changeAutoHide: (e) ->
    @$('.auto-hide-subsection').toggle()

  removeImage: (e) ->
    link = e.target
    $(link).prev().val("1")
    $(link).closest(".product-image").hide()

    still_more_images = false
    $('.images-list .product-image').each (i, obj) ->
      if($(obj).find('span.destroy input').val() == "0")
        still_more_images = true

    unless still_more_images
      @$('.images-list .first-upload').show()

  removeAttachment: (e) ->
    link = e.target
    $(link).prev().val("1")
    $(link).closest(".attachment").hide()

    still_more_attachments = false
    $('.attachments-list .attachment').each (i, obj) ->
      if($(obj).find('.remove input').val() == "0")
        still_more_attachments = true

    unless still_more_attachments
      @$('.attachments-list .first-upload').show()

  changedDropdown: (e) ->
    obj = @$(e.target)
    if obj.val() == "add_new_item"
      # show the text input field
      url = obj.closest(".input").data('new-category-url')
      UI.showPreloader()
      $.get(url)
      obj.find('option:first').attr('selected', 'selected').parent('select')
      $.uniform.update()

  initImageUploader: ->
    if $("#image-uploaded-template").length > 0
      image_uploaded_template = Handlebars.compile($('#image-uploaded-template').html());

    numberOfFiles = 0
    crntUpload = 0
    el = @$('#new_product_image')

    tearDown = ->
      if el.data('blueimpFileupload')?
        el.fileupload('destroy')
      document.removeEventListener("turbolinks:before-cache", tearDown)
    document.addEventListener("turbolinks:before-cache", tearDown)

    el.fileupload(
      pasteZone: $('#image_fields')
      dropZone: $('#image_fields')
      dataType: 'xml'
      multipart: false
      processData: false,
      sequentialUploads: true
      limitConcurrentUploads: 1 
      type: 'PUT'

      add: (e, data) ->
        # The example input, doesn't have to be part of the upload form:
        file = data.files[0]

        this.acceptFileTypes = /(\.|\/)(gif|jpe?g|png)$/i
        if(!(this.acceptFileTypes.test(file.type) || this.acceptFileTypes.test(file.name)))
          return false

        prefix = new Date().getTime().toString()
        filename = prefix + '/' + file.name
        
        sign_uploads_url = el.data('sign-uploads-url')
        $.ajax(
          type: 'POST'
          url: sign_uploads_url
          data: { filename: filename, content_type: file.type }

          success: (response) ->
            data.filename = response.filename
            data.url = response.url
            data.key = response.key
            data.formData = response.formData
            data.headers = response.headers
            numberOfFiles++
            data.submit()
        )


      send: (e, data) ->
        crntUpload++

      start: ->
        widget = $(this)

        modalBG = $('.uploader-bg')
        if modalBG.length == 0
          modalBG = $('<div class="uploader-bg" />').appendTo($('body'))
        modalBG.css({'opacity' : 0, 'display' :'block'})
               .transition({ opacity: 1 })
               .data('preloaded', true)

        modalLoader = $('.uploader-progress')
        if modalLoader.length == 0
          modalLoader = $("<div class='uploader-progress'><div class='label'>Please Wait while uploading</div><div class='progressbar-wrapper'><div class='progressbar' style='width:0%;'></div></div><div class='progress-info'></div></div>").css({'opacity' : 0, 'display' :'block', 'scale': 0.95 }).appendTo($('body'))
        modalLoader.transition({ opacity: 1, duration: 400, scale: 1, easing: 'out'})

        progressElement = $('.uploader-progress .progress-info')
        progressBar = $('.uploader-progress .progressbar')
        interval = 100
        total = 0
        loaded = 0
        loadedBefore = 0
        crntfile = ""
        progressTimer = undefined

        progressHandler = (e, data) ->
          loaded = data.loaded
          total = data.total

        stopHandler = ->
          numberOfFiles = 0
          crntUpload = 0

          modalBG = $('.uploader-bg')
          modalLoader = $('.uploader-progress')

          modalBG.delay(1000).transition
            opacity: 0
            duration: 800
          , ->
            $(this).css(
              display: "none"
              opacity: 0
            ).remove()

          modalLoader.delay(1000).transition
            opacity: 0
            scale: 0.8
            duration: 500
          , ->
            $(this).css(
              display: "none"
              opacity: 0
            ).remove()

          window.setTimeout(stopIntervalHandler, 2000)

        formatTime = (seconds) ->
          date = new Date(seconds * 1000)
          ("0" + date.getUTCHours()).slice(-2) + ":" + ("0" + date.getUTCMinutes()).slice(-2) + ":" + ("0" + date.getUTCSeconds()).slice(-2)

        formatBytes = (bytes) ->
          return (bytes / 1000000000).toFixed(2) + " GB"  if bytes >= 1000000000
          return (bytes / 1000000).toFixed(2) + " MB"  if bytes >= 1000000
          return (bytes / 1000).toFixed(2) + " KB"  if bytes >= 1000
          bytes + " B"

        formatPercentage = (floatValue) ->
          (floatValue * 100).toFixed(2) + "%"

        updateProgressElement = (file,loaded, total, bps) ->
          progressElement.html("Uploading file #{crntUpload} / #{numberOfFiles} at " + formatBytes(bps) + "ps | " + formatPercentage(loaded / total) + " done)")
          progressBar.css('width',formatPercentage(loaded / total))

        intervalHandler = ->
          diff = loaded - loadedBefore
          return  unless diff
          loadedBefore = loaded
          updateProgressElement(crntfile,loaded, total, diff * (100 / interval))

        stopIntervalHandler = ->
          widget.unbind("fileuploadprogressall", progressHandler)
                .unbind "fileuploadstop", stopHandler
          window.clearInterval progressTimer

        widget.bind("fileuploadprogressall", progressHandler)
              .bind("fileuploadstop", stopHandler)

        progressTimer = window.setInterval(intervalHandler, interval)

      always: (e, data) ->
        if $('.images-list .first-upload').length > 0
          $('.images-list .first-upload').remove()

        new_id = new Date().getTime()
        regexp = new RegExp("new_image", "g")
        content = image_uploaded_template(
          id: "new_image"
          key: data.filename
          thumb: "#{$('meta[name="nimbu:cdn"]').attr('content')}/p_compact/" + data.key
        )
        image = $(content.replace(regexp, new_id))
        if $('.images-list').hasClass('empty')
          $('.images-list').slideDown( ->
            $(this).removeClass('empty')
          )
        image.appendTo($('.images-list'))
        image.hide().css('opacity',0).show().animate({opacity: 1})
    )

  initAttachmentUploaders: ->
    this.initAttachmentUploader(@$('#new_product_attachment_files'))
    if window.chrome?
      @$('#new_product_attachment_files').closest('.button').addClass('left')
      @$('#new_product_attachment_directory').closest('.button').show().addClass('right')
      this.initAttachmentUploader(@$('#new_product_attachment_directory'), true)

  initAttachmentUploader: (node, directory) ->
    if $("#attachment-uploaded-template").length > 0
      attachment_uploaded_template = Handlebars.compile($('#attachment-uploaded-template').html());

    numberOfFiles = 0
    crntUpload = 0
    el = node

    tearDown = ->
      node.fileupload('destroy')
      document.removeEventListener("turbolinks:before-cache", tearDown)
    document.addEventListener("turbolinks:before-cache", tearDown)

    node.fileupload(
      if directory?
        pasteZone: $('#attachment_upload')
        dropZone: $('#attachment_upload')
      else
        pasteZone: false
        dropZone: false
      dataType: 'xml'
      multipart: false
      processData: false,
      sequentialUploads: true
      limitConcurrentUploads: 1 
      type: 'PUT'

      add: (e, data) ->
        # The example input, doesn't have to be part of the upload form:
        file = data.files[0]
        filename = file.name

        unless (filename == ".DS_Store" || filename == "." || filename == ".." || filename == "thumbs.db" || filename == "Thumbs.db")
          prefixedfilename = new Date().getTime().toString() + '/' + filename

          sign_uploads_url = el.data('sign-uploads-url')
          $.ajax(
            type: 'POST'
            url: sign_uploads_url
            data: { filename: prefixedfilename, content_type: file.type }

            success: (response) ->
              data.name = filename
              data.filename = response.filename
              data.url = response.url
              data.key = response.key
              data.formData = response.formData
              data.headers = response.headers
              numberOfFiles++
              data.submit()
          )

      always: (e, data) ->
        if $('.attachments-list .first-upload').length > 0
          $('.attachments-list .first-upload').remove()

        new_id = new Date().getTime()
        regexp = new RegExp("new_attachment", "g")

        file = data.files[0]
        if file.webkitRelativePath?
          directory = file.webkitRelativePath.replace(file.name,'')
        else
          directory = ""

        content = attachment_uploaded_template(
          id: "new_attachment"
          key: data.filename
          name: data.name
          path: "/" + directory
          thumb: el.data('upload-url') + "/" + data.key
        )
        attachment = $(content.replace(regexp, new_id))
        if $('.attachments-list').hasClass('empty')
          $('.attachments-list').slideDown( ->
            $(this).removeClass('empty')
          )
        attachment.appendTo($('.attachments-list'))
        attachment.hide().css('opacity',0).show().animate({opacity: 1})

        new App.Views.ProductAttachmentView({el: $(attachment).find('.attachment')})

      send: (e, data) ->
          crntUpload++

      start: ->
        widget = $(this)

        modalBG = $('.uploader-bg')
        if modalBG.length == 0
          modalBG = $('<div class="uploader-bg" />').appendTo($('body'))
        modalBG.css({'opacity' : 0, 'display' :'block'})
               .transition({ opacity: 1 })
               .data('preloaded', true)

        modalLoader = $('.uploader-progress')
        if modalLoader.length == 0
          modalLoader = $("<div class='uploader-progress'><div class='label'>Please Wait while uploading</div><div class='progressbar-wrapper'><div class='progressbar' style='width:0%;'></div></div><div class='progress-info'></div></div>").css({'opacity' : 0, 'display' :'block', 'scale': 0.95 }).appendTo($('body'))
        modalLoader.transition({ opacity: 1, duration: 400, scale: 1, easing: 'out'})

        progressElement = $('.uploader-progress .progress-info')
        progressBar = $('.uploader-progress .progressbar')
        interval = 100
        total = 0
        loaded = 0
        loadedBefore = 0
        crntfile = ""
        progressTimer = undefined

        progressHandler = (e, data) ->
          loaded = data.loaded
          total = data.total

        stopHandler = ->
          numberOfFiles = 0
          crntUpload = 0

          modalBG = $('.uploader-bg')
          modalLoader = $('.uploader-progress')

          modalBG.delay(1000).transition
            opacity: 0
            duration: 800
          , ->
            $(this).css(
              display: "none"
              opacity: 0
            ).remove()

          modalLoader.delay(1000).transition
            opacity: 0
            scale: 0.8
            duration: 500
          , ->
            $(this).css(
              display: "none"
              opacity: 0
            ).remove()

          window.setTimeout(stopIntervalHandler, 2000)

        formatTime = (seconds) ->
          date = new Date(seconds * 1000)
          ("0" + date.getUTCHours()).slice(-2) + ":" + ("0" + date.getUTCMinutes()).slice(-2) + ":" + ("0" + date.getUTCSeconds()).slice(-2)

        formatBytes = (bytes) ->
          return (bytes / 1000000000).toFixed(2) + " GB"  if bytes >= 1000000000
          return (bytes / 1000000).toFixed(2) + " MB"  if bytes >= 1000000
          return (bytes / 1000).toFixed(2) + " KB"  if bytes >= 1000
          bytes + " B"

        formatPercentage = (floatValue) ->
          (floatValue * 100).toFixed(2) + "%"

        updateProgressElement = (file,loaded, total, bps) ->
          progressElement.html("Uploading file #{crntUpload} / #{numberOfFiles} at " + formatBytes(bps) + "ps | " + formatPercentage(loaded / total) + " done)")
          progressBar.css('width',formatPercentage(loaded / total))

        intervalHandler = ->
          diff = loaded - loadedBefore
          return  unless diff
          loadedBefore = loaded
          updateProgressElement(crntfile,loaded, total, diff * (100 / interval))

        stopIntervalHandler = ->
          widget.unbind("fileuploadprogressall", progressHandler)
                .unbind "fileuploadstop", stopHandler
          window.clearInterval progressTimer

        widget.bind("fileuploadprogressall", progressHandler)
              .bind("fileuploadstop", stopHandler)

        progressTimer = window.setInterval(intervalHandler, interval)
    )

  initAttachments: ->
    @$('.attachment').each ->
      new App.Views.ProductAttachmentView({el: $(this)})

  initSubproductsSelector: ->
    ids = []
    options = []
    @$('#product_subproducts_ids option').each (i, el) ->
      options.push($(el).data('data'))
      ids.push($(el).attr('value'))
    @$('#product_subproducts_ids').selectize
      plugins: ['remove_button'],
      theme: "subproducts"
      valueField: "id"
      labelField: "name"
      searchField: ["name", "description"]
      options: options
      create: false
      render:
        option: (item, escape) ->
          '<div class="sub-product-wrapper">' +
            ( if item.image? && item.image.url? then ('<img src="' + escape(item.image.url + '?filter=p_thumb') + '" alt="">') else ('<img src="' + escape(item.image) + '" alt="">')) +
            '<span class="title">' +
              '<span class="name">' + escape(item.name) + '</span>' +
            '</span>' +
            '<span class="description">' + item.description + '</span>' +
          '</div>';

      load: (query, callback) ->
        return callback()  unless query.length
        $.getJSON($('#product_subproducts_ids').data('search-url'), search: query, (res) ->
            callback res
          )
    @$('#product_subproducts_ids')[0].selectize.setValue(ids)


  initTaggable: ->
    options = {}
    if $("#product-available-tags").length > 0
      options.availableTags = JSON.parse($("#product-available-tags").html())
    $("#product_tags").selectize(
      plugins: ['drag_drop']
      delimiter: ','
      persist: false
      create: (input) ->
        {
            value: input,
            text: input
        }
    )
