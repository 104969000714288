import $ from "jquery";
import _ from "underscore";

class App.Views.ExportOrderView extends Backbone.View
  initialize: ->
    window.Nimbu.inlineInputs()
    _.bindAll(this, "changeExportType", "changeOrderExportRange", "changeInvoicesExportRange", "changePackingExportRange", "changeLuisterpuntExportRange");

  events:
    "change select#what":                       "changeExportType"
    "change .orders input.range-select":     "changeOrderExportRange"
    "change .invoices input.range-select":   "changeInvoicesExportRange"
    "change .packing input.range-select":    "changePackingExportRange"
    "change .adibib input.range-select":    "changeLuisterpuntExportRange"

  changeExportType: (e) ->
    select = @$(e.currentTarget)
    value = select.val()
    switch value
      when "invoices"
        @$(".invoices").show()
        @$(".orders").hide()
        @$(".packing").hide()
        @$(".adibib").hide() if @$(".adibib").length > 0
      when "orders"
        @$(".invoices").hide()
        @$(".orders").show()
        @$(".packing").hide()
        @$(".adibib").hide() if @$(".adibib").length > 0
      when "packing"
        @$(".invoices").hide()
        @$(".orders").hide()
        @$(".packing").show()
        @$(".adibib").hide() if @$(".adibib").length > 0
      when "adibib"
        @$(".invoices").hide()
        @$(".orders").hide()
        @$(".packing").hide()
        @$(".adibib").show() if @$(".adibib").length > 0
    UI.resizeModal()

  changeOrderExportRange: (e) ->
    value = @$(e.currentTarget).val()
    switch value
      when "all"
        @$('.orders .subrange.range').hide()
        @$('.orders .subrange.date').hide()
      when "range"
        @$('.orders .subrange.range').show()
        @$('.orders .subrange.date').hide()
      when "date"
        @$('.orders .subrange.range').hide()
        @$('.orders .subrange.date').show()
    UI.resizeModal()

  changeInvoicesExportRange: (e) ->
    value = @$(e.currentTarget).val()
    switch value
      when "all"
        @$('.invoices .subrange.range').hide()
        @$('.invoices .subrange.date').hide()
      when "range"
        @$('.invoices .subrange.range').show()
        @$('.invoices .subrange.date').hide()
      when "date"
        @$('.invoices .subrange.range').hide()
        @$('.invoices .subrange.date').show()
    UI.resizeModal()

  changePackingExportRange: (e) ->
    value = @$(e.currentTarget).val()
    switch value
      when "all"
        @$('.packing .subrange.range').hide()
        @$('.packing .subrange.date').hide()
      when "range"
        @$('.packing .subrange.range').show()
        @$('.packing .subrange.date').hide()
      when "date"
        @$('.packing .subrange.range').hide()
        @$('.packing .subrange.date').show()
    UI.resizeModal()

  changeLuisterpuntExportRange: (e) ->
    value = @$(e.currentTarget).val()
    switch value
      when "all"
        @$('.adibib .subrange.range').hide()
        @$('.adibib .subrange.date').hide()
      when "range"
        @$('.adibib .subrange.range').show()
        @$('.adibib .subrange.date').hide()
      when "date"
        @$('.adibib .subrange.range').hide()
        @$('.adibib .subrange.date').show()
    UI.resizeModal()
