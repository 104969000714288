import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import "intl-tel-input/build/js/utils"; // FIXME: make this a chunk and lazy load it using utilsScript
import Cleave from "cleave.js";
import { AsYouTypeFormatter } from "google-libphonenumber"; // FIXME: make this a chunk and lazy load it using utilsScript

Cleave.AsYouTypeFormatter = AsYouTypeFormatter;

const phoneInputCountries = [
  "al",
  "ad",
  "at",
  "by",
  "be",
  "ba",
  "bg",
  "hr",
  "cz",
  "dk",
  "ee",
  "fo",
  "fi",
  "fr",
  "de",
  "gi",
  "gr",
  "va",
  "hu",
  "is",
  "ie",
  "it",
  "lv",
  "li",
  "lt",
  "lu",
  "mk",
  "mt",
  "md",
  "mc",
  "me",
  "nl",
  "no",
  "pl",
  "pt",
  "ro",
  "ru",
  "sm",
  "rs",
  "sk",
  "si",
  "es",
  "se",
  "ch",
  "ua",
  "gb",
  "us",
  "ca",
];

export const setupPhoneInput = (el, field) => {
  const telInputEl = el instanceof jQuery ? el[0] : el;
  if (telInputEl == null) return;

  const iti = intlTelInput(telInputEl, {
    autoPlaceholder: "aggressive",
    initialCountry: "be",
    nationalMode: false,
    hiddenInput: field || "phone",
    onlyCountries: phoneInputCountries,
    preferredCountries: ["be", "nl"],
  });

  if (telInputEl != null && iti != null) {
    const setupCleave = () => {
      var isoCode;
      isoCode = iti.getSelectedCountryData().iso2;
      if (isoCode != null) {
        new Cleave(telInputEl, {
          phone: true,
          phoneRegionCode: isoCode.toUpperCase(),
        });
        return telInputEl.addEventListener("countrychange", function () {
          return setupCleave();
        });
      }
    };
    setupCleave();
  }

  return iti;
};
