import $ from "jquery";

class App.Views.TaxSettingsView extends Backbone.View
  initialize: ->
    view = $(@el)
    view.data('view',this)

  events:
    "change #general_tax_settings_form input"          : "taxSettingsChanged"

  taxSettingsChanged: (e) ->
    @$('#general_tax_settings_form').submit()
