import $ from "jquery";
import _ from "underscore";

class App.Views.SearchListView extends Backbone.View
  initialize: ->
    # ensure seach form is showing same value as query param
    searchString = UI.queryParams()["search"]
    obj = @$("form input[type=text]")
    if searchString? && searchString.trim() != ""
      caret_position = obj.caret()
      has_focus = obj.is(":focus")
      obj.val(searchString)
      if has_focus
        obj.focus()
        obj.caret(caret_position)

    @currentSearch = obj.val()
    @previousSearch = obj.val()

  events:
    "keyup input[type=text]" : "searchKeyUp"
    "submit form" : "submitSearch"

  searchKeyUp: ->
    @currentSearch = @$('form input[type=text]').val()
    this.performSearch()

  performSearch: _.debounce( ->
    view = this
    if @previousSearch != @currentSearch
      @previousSearch = @currentSearch
      clearTimeout @timer if @timer
      @timer = setTimeout(->
        view.submitSearch()
      , 200)
  , 250)

  submitSearch: (e) ->
    view = this
    e.preventDefault() if e?
    url = @$("form").attr("action")
    obj = @$("form input[type=text]")
    url = url + (if url.includes('?') then '&' else '?') + 'search=' + encodeURI(@currentSearch)
    caret_position = obj.caret()
    Turbolinks.visit(url)

    beforeRender = (e) ->
      obj = $(e.target).find("section.search form input[type=text]")
      @currentSearch = obj.val()
      caret_position = obj.caret()
      document.removeEventListener("turbolinks:before-render", beforeRender)

    onRender = (e) ->
      obj = $(e.target).find("section.search form input[type=text]")
      obj.val(@currentSearch)
      obj.focus()
      obj.caret(caret_position)
      document.removeEventListener("turbolinks:load", onRender)

    document.addEventListener("turbolinks:before-render", beforeRender)
    document.addEventListener("turbolinks:load", onRender)
