import $ from "jquery";

class App.Views.EditArticleView extends Backbone.View
  initialize: ->
    view = this

    this.initTaggable()

  events:
    "click input:radio[name='article[status]']"   : "changeStatus"
    "change #article_blog_id"                     : "changeBlog"

  changeStatus: ->
    value = @$("input:radio[name='article[status]']:checked").val()

    switch value
      when "scheduled"
        @$('.status-scheduled-sub-section').show()
      else
        @$('.status-scheduled-sub-section').hide()

  changeBlog: ->
    this.refreshTaggable()

  taggableOptions: ->
    {
      plugins: ['drag_drop']
      delimiter: ','
      persist: false
      create: (input) ->
        {
            value: input,
            text: input
        }
    }

  initTaggable: ->
    $("#article_tags").selectize(this.taggableOptions())
    select = $("#article_tags")[0].selectize

    if $("#article-available-tags").length > 0
      availableTags = JSON.parse($("#article-available-tags").html())
      availableTags.map((x) -> select.addOption({ value: x, text: x }))

  refreshTaggable: ->
    view = this
    select = $("#article_blog_id")
    id  = select.val()
    url = select.data("update-tags-url").replace("__blog_id__", id);
    $.getJSON url, (tags) ->
      $("#article_tags")[0].selectize.destroy()
      $("#article_tags").selectize(view.taggableOptions())
      select = $("#article_tags")[0].selectize
      tags.map((x) -> select.addOption({ value: x, text: x }))
