import $ from "jquery";
import Clipboard from "clipboard";

class App.Views.ShowSubscriptionView extends Backbone.View
  initialize: ->
    @order_comment = @$("#subscription_comments").val()

    if $('.copy-token').length > 0
      clipboard = new Clipboard(".copy-token");
      clipboard.on('success', (e) ->
        UI.showFlash($(e.trigger).data('message'))
      )

      $(".copy-token").click (e) ->
        e.preventDefault()

    $('.manual-tipsy').each () ->
      $(this).hover((e) ->
        $(this).find('.copy-token').tipsy("show")
      , (e) ->
        $(this).find('.copy-token').tipsy("hide")
      )

  events:
    "click .trigger-dropdown"            : "toggleDropdown"
    "click #show-hide-grouped-by-extras" : "toggleGroupedByExtras"
    "click .comments-form .submit"       : "saveComments"
    "change #subscription_comments"             : "changedOrderComments"
    "keyup #subscription_comments"              : "changedOrderComments"
    "paste #subscription_comments"              : "changedOrderComments"

  toggleDropdown: (e) ->
    e.preventDefault()
    link = @$(e.currentTarget)
    parent = link.closest('.history-item')
    next = parent.next()
    if next.hasClass('table-dropdown')
      next.toggleClass('active')

  toggleGroupedByExtras: (e) ->
    e.preventDefault()
    $('#grouped-by-extras').toggleClass('hidden')

  saveComments: (e) ->
    $this = $(e.currentTarget)

    if $this.hasClass("disabled")
      e.preventDefault()
    else
      $this.addClass("disabled")

  changedOrderComments: (e) ->
    value = @$("#subscription_comments").val()
    if value != @order_comment
      @$('.comments-form .submit').removeClass('disabled')
    else
      @$('.comments-form .submit').addClass('disabled')

