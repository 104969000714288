import React, { Suspense } from "react";
import { render, unmountComponentAtNode } from "react-dom";
import Spinner from "../../../components/Spinner";
import { SuperUserContext } from "../../../util/superuser";

const ConsentConfigurationView = React.lazy(() => import("./ConsentConfiguration"));

let rootEl: HTMLElement | undefined | null;

const ConsentConfiguration = ({ data }: { data: any }) => {
  return (
    <Suspense fallback={<Spinner />}>
      <ConsentConfigurationView data={data} />
    </Suspense>
  );
};

export function initialize(el: HTMLElement) {
  cleanup();
  rootEl = el.querySelector<HTMLElement>("[data-react-root]");
  const dataEl = el.querySelector<HTMLElement>("[data-react-data]");
  if (rootEl != null && dataEl != null) {
    const data = JSON.parse(dataEl.innerText);
    const superuser = rootEl.dataset.superuser != null;
    render(
      <SuperUserContext.Provider value={superuser}>
        <ConsentConfiguration data={data} />
      </SuperUserContext.Provider>,
      rootEl
    );
  }
}

export function cleanup() {
  if (rootEl != null) {
    unmountComponentAtNode(rootEl);
    rootEl = undefined;
  }
}
