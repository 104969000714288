import $ from "jquery";
import { Controller } from "@hotwired/stimulus";

import "../vendor/redactor/redactor.js";
import "../vendor/redactor/_plugins/alignment/alignment.js";
import "../vendor/redactor/_plugins/counter/counter.js";
import "../vendor/redactor/_plugins/definedlinks/definedlinks.js";
import "../vendor/redactor/_plugins/filemanager/filemanager.js";
import "../vendor/redactor/_plugins/fontcolor/fontcolor.js";
import "../vendor/redactor/_plugins/fontsize/fontsize.js";
import "../vendor/redactor/_plugins/fullscreen/fullscreen.js";
import "../vendor/redactor/_plugins/imagemanager/imagemanager.js";
import "../vendor/redactor/_plugins/properties/properties.js";
import "../vendor/redactor/_plugins/specialchars/specialchars.js";
import "../vendor/redactor/_plugins/table/table.js";
import "../vendor/redactor/_plugins/textdirection/textdirection.js";
import "../vendor/redactor/_plugins/video/video.js";
import "../vendor/redactor/_plugins/widget/widget.js";
import "../vendor/redactor/_plugins/inlinestyle/inlinestyle.js";
import "../vendor/redactor/_plugins/nimbu/clearformatting.js";
import "../vendor/redactor/_plugins/nimbu/more.js";

// prettier-ignore
export const defaultRedactorOptions = {
  autoresize: true,
  deniedTags: ['html', 'head', 'link', 'body', 'meta', 'applet'],
  removeStyles: true,
  overlay: true,
  buttonSource: true,
  minHeight: 150,
  linkSize: 100,
  linkEmail: true,
  linkAnchor: true,
  linkNewTab: true,
  linkValidation: false,
  replaceDivs: true,
  paragraphize: true,
  removeWithoutAttr: ['span'],
  toolbarFixedTopOffset: 67,
  imageFloatMargin: '30px',
  imageResizable: true,
  imagePosition: true,
  path: '/assets/redactor',
  plugins: ['alignment', 'definedlinks', 'fontcolor', 'fontsize', 'fontfamily', 'table', 'imagemanager', 'filemanager',
    'video', 'widget', 'textdirection', 'specialchars', 'properties', 'clearformatting', 'fullscreen', 'counter', 'inlinestyle', 'more'],
  buttons: ['html', 'format', 'inline', 'bold', 'italic', 'underline', 'deleted', 'alignment', 'lists', 'table', 'link', 'file', 'image', 'video',
    'fontcolor', 'fontsize', 'specialchars'],
  buttonsHide: ['textdirection', 'properties', 'widget'],
  imageManagerJson: "/admin/media/images.json",
  imageUpload: "/admin/media/uploads",
  fileUpload: "/admin/media/uploads",
  fileManagerJson: "/admin/media/other.json",
  definedlinks: "/admin/pages.json",
  fontcolors: ["#000", "#5E1916", "#630A3A", "#300D5C", "#1A0E4F", "#10144A", "#082B61", "#013A66", "#004345", "#002E26", "#0F3311", "#214213", "#47420D", "#AD5910", "#A34700", "#993600", "#752107", "#291A17",
              "#333", "#7D221D", "#880e4f", "#4a148c", "#311b92", "#1a237e", "#0d47a1", "#01579b", "#006064", "#004d40", "#1b5e20", "#33691e", "#827717", "#f57f17", "#ff6f00", "#e65100", "#bf360c", "#3e2723",
              "#555", "#a82d26", "#ad1457", "#6a1b9a", "#4527a0", "#283593", "#1565c0", "#0277bd", "#00838f", "#00695c", "#2e7d32", "#558b2f", "#9e9d24", "#f9a825", "#ff8f00", "#ef6c00", "#d84315", "#4e342e",
              "#777", "#c23e38", "#c2185b", "#7b1fa2", "#512da8", "#303f9f", "#1976d2", "#0288d1", "#0097a7", "#00796b", "#388e3c", "#689f38", "#afb42b", "#fbc02d", "#ffa000", "#f57c00", "#e64a19", "#5d4037",
              "#999", "#d3483e", "#d81b60", "#8e24aa", "#5e35b1", "#3949ab", "#1e88e5", "#039be5", "#00acc1", "#00897b", "#43a047", "#7cb342", "#c0ca33", "#fdd835", "#ffb300", "#fb8c00", "#f4511e", "#6d4c41",
              "#aaa", "#e15241", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548",
              "#bbb", "#dd5e56", "#ec407a", "#ab47bc", "#7e57c2", "#5c6bc0", "#42a5f5", "#29b6f6", "#26c6da", "#26a69a", "#66bb6a", "#9ccc65", "#d4e157", "#ffee58", "#ffca28", "#ffa726", "#ff7043", "#8d6e63",
              "#ccc", "#d67976", "#f06292", "#ba68c8", "#9575cd", "#7986cb", "#64b5f6", "#4fc3f7", "#4dd0e1", "#4db6ac", "#81c784", "#aed581", "#dce775", "#fff176", "#ffd54f", "#ffb74d", "#ff8a65", "#a1887f",
              "#eee", "#e39e9c", "#f48fb1", "#ce93d8", "#b39ddb", "#9fa8da", "#90caf9", "#81d4fa", "#80deea", "#80cbc4", "#a5d6a7", "#c5e1a5", "#e6ee9c", "#fff59d", "#ffe082", "#ffcc80", "#ffab91", "#bcaaa4",
              "#f4f4f4", "#f7cfd3", "#f8bbd0", "#e1bee7", "#d1c4e9", "#c5cae9", "#bbdefb", "#b3e5fc", "#b2ebf2", "#b2dfdb", "#c8e6c9", "#dcedc8", "#f0f4c3", "#fff9c4", "#ffecb3", "#ffe0b2", "#ffccbc", "#d7ccc8",
              "#ffffff", "#fcecee", "#fce4ec", "#f3e5f5", "#ede7f6", "#e8eaf6", "#e3f2fd", "#e1f5fe", "#e0f7fa", "#e0f2f1", "#e8f5e9", "#f1f8e9", "#f9fbe7", "#fffde7", "#fff8e1", "#fff3e0", "#fbe9e7", "#efebe9"],
}
export default class extends Controller {
  connect() {
    const consentApps = $("[data-consent-apps]").data("consent-apps") ?? [];

    const extraOptions = {};

    // detect if number of rows attr is set
    if (this.textarea.getAttribute("rows")) {
      // get rows attr from text area
      const rows = this.textarea.getAttribute("rows");

      // set min height to the rows attr
      extraOptions.minHeight = `${rows * 20}px`;
    }

    $R(this.textarea, {
      ...defaultRedactorOptions,
      ...extraOptions,
      consentApps,
      callbacks: {
        started: () => {
          window.$(".active-modal").trigger("reveal:position");
        },
        enter: () => {
          window.$(".active-modal").trigger("reveal:position");
        },
        synced: () => {
          window.$(".active-modal").trigger("reveal:position");
        },
      },
    });
  }

  disconnect() {
    $R(this.textarea, "destroy");
  }

  get $textarea() {
    return $(this.element).find("textarea");
  }

  get textarea() {
    return this.$textarea[0];
  }
}
