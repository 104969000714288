import $ from "jquery";
import _ from "underscore";

class App.Views.CodeFormView extends Backbone.View
  initialize: ->
    _.bindAll this, 'submitForm', 'toggleFullscreenEditing'

    $(@el).data('view', this)
    view = this

  events:
    "click #rename-link"         : "showRenameForm"
    "click #submit-rename-form"  : "submitRenameForm"
    "click #cancel-rename-form"  : "cancelRenameForm"
    "keypress #rename-form input"   : "keyupRenameForm"
    "keypress textarea" : "keyupTextarea"

  submitForm: (e) ->
    @$('form').submit();
    return false;

  keyupTextarea: (event) ->
    if (!(event.which == 83 && (event.ctrlKey || event.metaKey)) && !(event.which == 115 && (event.ctrlKey || event.metaKey)) && !(event.which == 19))
      return true;
    this.submitForm(event)
    return false;

  showRenameForm: (e) ->
    e.preventDefault()
    @original_name = $("#rename-form input").val()
    @$('#rename-link').hide()
    @$('#rename-form').show()

  cancelRenameForm: (e) ->
    e.preventDefault()
    @$('#rename-link').show()
    @$('#rename-form').hide()
    $("#rename-form input").val(@original_name)
    return false;

  submitRenameForm: (e) ->
    if @original_name == $("#rename-form input").val() || _.isEmpty($("#rename-form input").val())
      e.preventDefault()
      e.stopPropagation()
      this.cancelRenameForm(e)
      return false;
    else
      e.preventDefault()
      e.stopPropagation()
      @$("form").submit()

  keyupRenameForm: (e) ->
    if(window.event)
      key = window.event.keyCode; #IE
    else
      key = e.which;

    if(key == 13)
      e.preventDefault()
      e.stopPropagation()
      this.submitRenameForm(e)
      return false;


  toggleFullscreenEditing: ->
    editorDiv = $('.CodeMirror-scroll');

    if (!editorDiv.hasClass('fullscreen'))
      @beforeFullscreen = { height: editorDiv.height(), width: editorDiv.width() }
      editorDiv.addClass('fullscreen')
      editorDiv.height('100%')
      editorDiv.width('100%')
      $('body, html').css('overflow-y','hidden');
      $('body, html').css('overflow-x','hidden');
      @editor.refresh()
    else
      editorDiv.removeClass('fullscreen')
      editorDiv.height(@beforeFullscreen.height)
      editorDiv.width(@beforeFullscreen.width)
      $('body, html').css('overflow-y','auto');
      $('body, html').css('overflow-x','auto');
      @editor.refresh()
