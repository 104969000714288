import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static values = {
    url: String,
    context: String,
  };

  reveal(e) {
    e.preventDefault();
    e.stopPropagation();
    const $element = $(this.element);
    fetch(`${this.urlValue}?context=${this.contextValue}`)
      .then((response) => response.json())
      .then((data) => {
        $element.removeClass("encrypted-blurred");
        $element.removeAttr("data-action");
        $element.tipsy("disable");
        $element.html(data.value);
      });
  }
}
