import $ from "jquery";
import _ from "underscore";

class App.Views.ProductPricingSchemePricePointView extends Backbone.View
  initialize: (options) ->
    @options = options
    _.bindAll(this, "destroyPricePoint");
    UI.tooltips()

  events:
    "click .remove-pp > a"                 : "destroyPricePoint"

  destroyPricePoint: (e) ->
    $(this.el).addClass('hidden')
    link = e.target
    $(link).prev().find("input[type=hidden]").val("1")
    @options.parent.hideOrShowPricePointDestroyButtons()

