import React, { useEffect, useRef } from 'react';

const Spinner = () => {
  const ref = useRef<HTMLDivElement>(null!);
  useEffect(() => {
    if (ref.current != null) {
      (window as any).$(ref.current).spin({
        lines: 14,
        length: 5,
        width: 2,
        radius: 5,
        hwaccel: true,
        color: '#7c8088',
      });
    }
  }, []);
  return (
    <div className="no-content-wrapper">
      <div className="spinner-wrapper">
        <div ref={ref} className="spinner"></div>
      </div>
    </div>
  );
};

export default Spinner;
