import $ from "jquery";
import _ from "underscore";
import unescapeHTML from "underscore.string/unescapeHTML";

class App.Views.EditCustomFieldsView extends Backbone.View
  initialize: ->
    parent = this
    @$("#custom_field_list .custom-field-wrapper").each ->
      object = new App.Views.EditCustomFieldItemView({el: $(this), parent: parent})
      object._parent = this

    @$("#custom_field_list > ul").sortable(
        zIndex: 1000,
        axis: "y",
        forcePlaceholderSize: true,
        handle: '.drag-handle',
        item: 'li',
        update: (event, ui) ->
          cnt = 0
          $(this).find('> li').each ->
            id = $(this).attr('id')
            el = $(this).find(".position-field").attr('value',cnt)
            cnt++
    )
  events:
    "click .add-custom-field > a" : "addField"

  addField: (e) ->
    fields_count = @$("#custom_field_list .custom-field-wrapper:not(.hidden)").length
    link = e.target
    tpl = $("#new-field-template").html()
    new_id = new Date().getTime()
    regexp = new RegExp("new_custom_field", "g")
    el = $(unescapeHTML(tpl.replace(regexp, new_id))).appendTo(@$("#custom_field_list > ul"))
    new App.Views.EditCustomFieldItemView({el: $(el), parent: this})
    position_field = $(el).find('.position-field')
    position_field.val(fields_count + 1) if position_field?

    @hideOrShowDestroyButtons()

  hideOrShowDestroyButtons: ->
    return if @$("#custom_field_list").data('allow-none')?

    if @$("#custom_field_list .custom-field-wrapper:not(.hidden)").length < 2
      @$("#custom_field_list .custom-field-wrapper").each ->
        $(this).find('.remove').hide()
    else
      @$("#custom_field_list .custom-field-wrapper").each ->
        $(this).find('.remove').show()

