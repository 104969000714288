import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  connect() {
    const view = this;
    this.$el = $(this.element);

    // setup type selectize
    this.$typeSelect = this.$el.find(".type-selector select").selectize({
      onChange: function (value) {
        if (!value.length) return;
        const selectize = this;
        const data = selectize.options[value];

        view.changedType(value);
      },
    })[0].selectize;

    this.allTypes = this.$typeSelect.options;

    this.$conditionSelect = this.$el.find(".condition-selector select").selectize({
      onChange: function (value) {
        if (!value.length) return;
        view.changedType(view.$typeSelect.getValue());
      },
    })[0].selectize;

    this.allConditions = this.$conditionSelect.options;
    this.booleanConditionValue = Object.keys(this.allConditions).find(
      (key) => this.allConditions[key].type === "boolean"
    );
    this.genericConditionValue = Object.keys(this.allConditions).find(
      (key) => this.allConditions[key].type !== "boolean"
    );

    // setup other selectize
    this.$el.find("select:not(.selectized)").selectize();

    // setup condition selectize
    this.changedType(this.$typeSelect.getValue());
  }

  changedType(value) {
    const selectedOption = this.allTypes[value];
    const selectedConditionValue = this.$conditionSelect.getValue();
    const selectedCondition = this.allConditions[selectedConditionValue];

    // loop over key values and hide all where type if not boolean
    for (const key of Object.keys(this.allConditions)) {
      const option = this.allConditions[key];

      if (selectedOption.type === "boolean" && option.type === "boolean") {
        this.$conditionSelect.updateOption(key, { ...option, disabled: false });
      } else if (selectedOption.type !== "boolean" && option.type !== "boolean") {
        this.$conditionSelect.updateOption(key, { ...option, disabled: false });
      } else {
        this.$conditionSelect.updateOption(key, { ...option, disabled: true });
      }
    }

    if (selectedOption.type === "boolean" && selectedCondition.type !== "boolean") {
      // select the first boolean condition option
      this.$conditionSelect.setValue(this.booleanConditionValue);
    } else if (selectedOption.type !== "boolean" && selectedCondition.type === "boolean") {
      // select the first non-boolean condition option
      this.$conditionSelect.setValue(this.genericConditionValue);
    }

    if ($(`.special-selector.${value}-selector`).length > 0) {
      $(".special-selector").hide();
      $(`.${value}-selector`).show();
      $(".regular-selector").hide();
      $(".regular-selector").addClass("hastext");
      $(".regular-selector input").val($(`.${value}-selector`).find("option:selected").text());
      $(".special-field-value-field").val($(`.${value}-selector`).find("option:selected").val());
    } else {
      $(".special-field-value-field").val("");
      $(".special-selector").hide();

      if (selectedOption.type === "boolean" || selectedCondition.type === "valueless") {
        this.$el.find(".regular-selector").hide();
        this.$el.find(".regular-selector input").val("");
      } else {
        this.$el.find(".regular-selector").show();
      }
    }
  }
}
