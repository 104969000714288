import { Controller } from "@hotwired/stimulus";

import { closeBrackets, closeBracketsKeymap } from "@codemirror/autocomplete";
import { indentWithTab } from "@codemirror/commands";
import { css } from "@codemirror/lang-css";
import { javascript } from "@codemirror/lang-javascript";
import { liquid } from "@codemirror/lang-liquid";
import { EditorState } from "@codemirror/state";
import { EditorView, keymap, lineNumbers } from "@codemirror/view";
import { basicSetup } from "codemirror";

import { highlightSelectionMatches } from "@codemirror/search";
import { githubLight } from "@uiw/codemirror-theme-github";

function editorFromTextArea(textarea) {
  let lang;

  if (textarea.classList.contains("javascript")) {
    lang = javascript();
  } else if (textarea.classList.contains("stylesheet") || textarea.classList.contains("css")) {
    lang = css();
  } else {
    lang = liquid();
  }

  let state = EditorState.create({
    doc: textarea.value,
    extensions: [
      basicSetup,
      githubLight,
      lineNumbers(),
      closeBrackets(),
      keymap.of([...closeBracketsKeymap, indentWithTab]),
      EditorState.allowMultipleSelections.of(true),
      highlightSelectionMatches(),
      lang,
      EditorView.lineWrapping,
    ],
  });

  let view = new EditorView({ state, parent: textarea.parentNode });
  textarea.parentNode.insertBefore(view.dom, textarea);
  textarea.style.display = "none";
  if (textarea.form)
    textarea.form.addEventListener("submit", () => {
      textarea.value = view.state.doc.toString();
    });
  return view;
}

export default class extends Controller {
  static targets = ["editor"];

  connect() {
    if (!this.hasEditorTarget) return;

    this.editor = editorFromTextArea(this.editorTarget);
  }

  disconnect() {
    if (this.editor) {
      this.editor.destroy();
    }
  }
}
