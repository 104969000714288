import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import Clipboard from "clipboard";
import escapeRegExp from "lodash/escapeRegExp";

const defaultToWhiteSpace = (characters) => {
  if (characters == null) {
    return "\\s";
  } else if (characters.source) {
    return characters.source;
  } else {
    return "[" + escapeRegExp(characters) + "]";
  }
};

const slugifyWithSlashes = (str, options = {}) => {
  const dashChar = options.separator || "-";
  const preserveCase = options.preserveCase;
  if (str == null) return "";

  const from =
    "ÀÁÂÃÄÅàáâãäåĀāĂăĄąÇçĆćĈĉĊċČčÐðĎďĐđÈÉÊËèéêëĒēĔĕĖėĘęĚěĜĝĞğĠġĢģĤĥĦħÌÍÎÏìíîïĨĩĪīĬĭĮįİıĴĵĶķĸĹĺĻļĽľĿŀŁłÑñŃńŅņŇňŉŊŋÒÓÔÕÖØòóôõöøŌōŎŏŐőŔŕŖŗŘřŚśŜŝŞşŠšſŢţŤťŦŧÙÚÛÜùúûüŨũŪūŬŭŮůŰűŲųŴŵÝýÿŶŷŸŹźŻżŽž'";
  const to =
    "AAAAAAaaaaaaAaAaAaCcCcCcCcCcDdDdDdEEEEeeeeEeEeEeEeEeGgGgGgGgHhHhIIIIiiiiIiIiIiIiIiJjKkkLlLlLlLlLlNnNnNnNnnNnOOOOOOooooooOoOoOoRrRrRrSsSsSsSssTtTtTtUUUUuuuuUuUuUuUuUuUuWwYyyYyYZzZzZz-";
  const regex = new RegExp(defaultToWhiteSpace(from), "g");

  if (!preserveCase) {
    str = String(str).toLowerCase();
  }

  str = String(str).replace(regex, (c) => {
    var index;
    index = from.indexOf(c);
    return to.charAt(index) ?? dashChar;
  });

  str = str.replace(/[^\w\s-_\/]/g, "");
  return str;
};

const slugifyText = (original, options = {}) => {
  const dashChar = options.separator || "-";

  let text = slugifyWithSlashes(original, options)
    .replace(/[^-a-zA-Z0-9,&\s]+/gi, "")
    .replace(/\s/gi, dashChar)
    .replace(/-+/g, dashChar)
    .replace(/^-/g, "");

  if (!options.allowEndingHyphen) {
    text = text.replace(/-$/g, "");
  }
  text = text.toLowerCase();
  return text;
};

export class SlugifyController extends Controller {
  static targets = ["input"];

  connect() {
    const copyURL = $(this.element).find(".copy-full-url");
    if (copyURL.length > 0) {
      copyURL.on("click", (e) => e.preventDefault());

      const clipboard = new Clipboard(copyURL[0]);
      clipboard.on("success", (e) => UI.showFlash($(e.trigger).data("message")));
    }
  }

  slugifyOnPaste(e) {
    setTimeout(() => {
      this.slugify(e);
    }, 10);
  }

  slugifyOnBlur(e) {
    this.lastSlugValue = slugifyText(e.target.value, {
      allowEndingHyphen: false,
    });
    e.target.value = this.lastSlugValue;
  }

  slugify(e) {
    if (e.target.value !== this.lastSlugValue) {
      let caretPosition = e.target.selectionStart;

      this.lastSlugValue = slugifyText(e.target.value, {
        allowEndingHyphen: true,
      });
      e.target.value = this.lastSlugValue;
      e.target.setSelectionRange(caretPosition, caretPosition);
    }
  }
}

export default SlugifyController;
