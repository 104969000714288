$(document).ready(function() {
  var switched = false;
  var updateTables = function() {
    $('table.responsive').each(function(i, element) {
      if ($(element).width() < 930 && !switched) {
        switched = true;
        splitTable($(element));
      } else if (switched && $(element).width() > 930) {
        switched = false;
        unsplitTable($(element));
      }
    });
  };

  $(window).on('load', updateTables);
  $(window).bind('resize', updateTables);

  function splitTable(original) {
    original.wrap("<div class='table-wrapper' />");

    var copy = original.clone();
    copy.find('td:not(:first-child), th:not(:first-child)').css('display', 'none');
    copy.removeClass('responsive');

    original.closest('.table-wrapper').append(copy);
    copy.wrap("<div class='pinned' />");
    original.wrap("<div class='scrollable' />");
  }

  function unsplitTable(original) {
    original
      .closest('.table-wrapper')
      .find('.pinned')
      .remove();
    original.unwrap();
    original.unwrap();
  }
});
