import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["triggerSelect", "channelSelect", "channelSelectWrapper"];

  connect() {
    this.triggerSelectTarget.selectize.on("change", this.triggerChanged.bind(this));
  }

  triggerChanged(items) {
    // item starts with "channel.entries"
    if (items != null && items.filter((item) => item.startsWith("channel.entries")).length > 0) {
      // show the filter
      this.channelSelectWrapperTarget.classList.remove("hidden");
    } else {
      this.channelSelectWrapperTarget.classList.add("hidden");
    }
  }
}
