import $ from "jquery";
import _ from "underscore";
import unescapeHTML from "underscore.string/unescapeHTML";

class App.Views.EditPageView extends Backbone.View
  initialize: ->
    # initilizing the tabs
    $("ul.tabs").tabs("div.panes > div", { history: true });

    if _.isEmpty @$('#page_channel_id').val()
      @$('.slug-field').show()
    else
      @$('.slug-field').hide()

    UI.selectize(@$('select.selectizable:not(.selectized)'))
    UI.selectizeWithSearch(@$('select.selectizable-with-search:not(.selectized)'))

    this.setupRedactor()
    this.initRepeatables()
    
    view = this
    @$(".repeatables-list").each ->
      view.initCanvas($(this))

  events:
    "change select#page_channel_id"        : "changeChannelId"
    "change select#page_parent_id"         : "changeParent"
    "change input#page_templatized"        : "changeChannelSwitch"
    "change select#page_security_mechanism": "changeSecurity"
    "click span.toggle-settings"           : "toggleSettings"
    "click .add-items-trigger"             : "showRepeatablesPicker"    
    "click .add-single-repeatable-item"    : "addRepeatable"    
    "click .canvas-icons .collapse-all"    : "collapseRepeatables"
    "click .canvas-icons .open-all"        : "openRepeatables"
    "click .page-item-group-header"        : "togglePageItemGroupContent"

  showSlugEdit: (e) ->
    @$("#slug_edit_trigger").hide()
    @$("#slug_field .input").show()
    @original_slug = @$("#slug_field input").val()

  clickSlugEditOK: (e) ->
    e.preventDefault()
    text = @$("#slug_field input").val()

    # slugify the text
    text = UI.slugify(text)

    @$("#slug_edit_trigger .slug").html(text)
    @$("#slug_field input").val(text)
    @$("#slug_edit_trigger").show()
    @$("#slug_field .input").hide()

  clickSlugEditCancel: (e) ->
    e.preventDefault()
    @$("#slug_field input").val(@original_slug)
    @$("#slug_edit_trigger").show()
    @$("#slug_field .input").hide()

  toggleSettings: (e) ->
    block = @$(e.target).closest(".block")
    block.find(".fields-wrapper").each (e) ->
      if block.hasClass("settings-hidden")
        block.removeClass("settings-hidden")
        $(this).hide().stop(true,true).slideDown(
          duration: 1000,
          easing: "easeOutExpo",
        )
      else
        block.addClass("settings-hidden")
        $(this).show().stop(true,true).slideUp(
          duration: 1000,
          easing: "easeOutExpo",
        )

  changeSecurity: (e) ->
    if _.isEmpty @$('#page_security_mechanism').val()
      @$('#security_password_field').slideUp('fast')
    else
      @$('#security_password_field').slideDown('fast')

  changeChannelId: (e) ->
    if _.isEmpty @$('#page_channel_id').val()
      @$('#slug_field').slideDown('fast')
    else
      @$('#slug_field').slideUp('fast')

  changeChannelSwitch: (e) ->
    if @$("#page_templatized").attr('checked') == "checked"
      @$("#channel_id_field").slideDown();
      @$("#slug_field, #homepage_field").slideUp();
    else
      @$("#channel_id_field").slideUp();
      @$("#slug_field, #homepage_field").slideDown();

  changeParent: (e) ->
    if _.isEmpty @$('#page_parent_id').val()
      @$('#homepage_field').slideDown('fast')
    else
      @$('#homepage_field').slideUp('fast')

  setupRedactor: ->
    # find all textareas within Canvas repeatables and tag these: redactor should only be initialized when the group is open
    @$('.canvas-wrapper textarea.wysiwyg').addClass('skip-auto-redactize')
    @$('.open textarea.wysiwyg').removeClass('skip-auto-redactize')

    UI.setupRedactor(@$('textarea.wysiwyg:not(.skip-auto-redactize):not(.redactor-initialized)'))

  initRepeatables: ->
    view = this

    @$(".repeatable-wrapper").each ->
      new App.Views.EditPageRepeatableView({el: $(this), parent: view })

  addRepeatable: (e, options = {}) ->    
    view = this

    position = options.position
    cloneFrom = options.cloneFrom

    $target = @$(e.currentTarget)
    $listElement = $target.closest('.repeatable-wrapper')
    $parent = $target.parent()
    
    if !position? && !$parent.hasClass('add-repeatables')
      position = $listElement.index()

    # console.log('$target', $target)
    canvasTemplateId = options.canvasTemplateId || $target.data("canvas-template-id")
    # console.log('canvasTemplateId', canvasTemplateId)
    canvasUUID = options.canvasUUID || $target.data("canvas-uuid")
    # console.log('canvasUUID', canvasUUID)
    repeatable_id = options.repeatableId || $target.data("repeatable-id")
    selector = ".canvas-wrapper[data-canvas-uuid='#{canvasUUID}']"

    $repeatable_canvas = $(selector)
    # console.log('$repeatable_canvas', $repeatable_canvas.length)
    $repeatable_container = $repeatable_canvas.find('> .repeatables-list')

    repeatable_parent_index = $repeatable_canvas.data("parent-index")
    repeatable_canvas_index = $repeatable_canvas.data("current-canvas-index")
    repeatable_template_id = "new-repeatable-template-canvas-#{canvasTemplateId}-#{repeatable_id}"

    if !position? && $listElement.length > 0
      position = $repeatable_container.index($listElement)
    else if position?
      $listElement = $repeatable_container.find('> li').slice(position, position + 1)

    if repeatable_parent_index?
      repeatable_template_id = "#{repeatable_parent_index}-#{repeatable_template_id}"
    repeatable_template = $(this.el).find("##{repeatable_template_id}").html()
    # console.log('repeatable_template', repeatable_template)

    if repeatable_canvas_index > 0
      for n in [repeatable_canvas_index-1..0]
        regexp = new RegExp("canvas-#{n}-new-repeatable", "g")
        parent_id = $repeatable_canvas.closest(".repeatable-wrapper[data-current-canvas=#{n}]").data('child-index')
        repeatable_template = repeatable_template.replace(regexp, parent_id)

    new_id = new Date().getTime()
    regexp = new RegExp("canvas-#{repeatable_canvas_index}-new-repeatable", "g")
    repeatable_template = repeatable_template.replace(regexp, new_id)
    $newRepeatable = $(unescapeHTML(repeatable_template))

    if cloneFrom?
      # lets scan the cloneFrom element and copy all the values
      $cloneFrom = $(cloneFrom)
      $cloneFrom.find('.field').each ->
        $field = $(this)
        if $field.hasClass('canvas-wrapper')
          # we have a nested repeatable
        else
          $field.find('[data-editable-uuid]').each ->
            uuid = $(this).data('editable-uuid')
            canvas = $(this).data('canvas-uuid')
            $target = $newRepeatable.find("[data-editable-uuid='#{uuid}'][data-canvas-uuid='#{canvas}']")
            if $target.length > 0
              $target.val($(this).val())

    if !position? || position == -1 || $listElement.length == 0
      el = $newRepeatable.appendTo($repeatable_container)
    else
      el = $newRepeatable.insertBefore($listElement[0])

    $el = $(el)
    $addRepeatableTriggers = $el.find("[data-canvas-template-id=#{canvasTemplateId}]")
    $addRepeatableTriggers.data('canvas-uuid', canvasUUID)
    $addRepeatableTriggers.attr('data-canvas-uuid', canvasUUID)
    new App.Views.EditPageRepeatableView({el: $el, parent: view })
    
    if $el.find('.repeatables-list').length > 0
      $el.find('.repeatables-list').each ->
        view.initCanvas($(this))

    cnt = 0
    $repeatable_canvas.find('.repeatable-wrapper').each ->
      item = $(this)
      item.find("input.position-tracker").attr('value',cnt)
      cnt++

    UI.closeModal()

  showRepeatablesPicker: (e) ->
    view = this
    $target = @$(e.currentTarget)
    $parent = $target.parent()
    canvas_template_id = $target.data('canvas-template-id')
    canvasUUID = $target.data('canvas-uuid')
    selector = ".add-items-content[data-canvas-template-id='#{canvas_template_id}']"
    code = $(selector).html()

    if $parent.hasClass('add-repeatables')
      # append at the bottom
      position = -1
    else
      position = $parent.index()

    UI.showModal(code, (el) ->
      new App.Views.AddPageItemView({el: el, parent: view, position: position, canvasUUID: canvasUUID })
    )

  collapseRepeatables: (e) ->
    $repeatable_canvas = @$(e.target).closest(".canvas-wrapper")
    $repeatable_canvas.find(".repeatable-content").removeClass("open")
    UI.destroyRedactor($repeatable_canvas.find("textarea.wysiwyg.manual"))
    this.saveBlockVisibility()

  openRepeatables: (e) ->
    $repeatable_canvas = @$(e.target).closest(".canvas-wrapper")
    UI.setupRedactor($repeatable_canvas.find("textarea.wysiwyg.manual"))
    $repeatable_canvas.find(".repeatable-content").addClass("open")
    this.saveBlockVisibility()

  togglePageItemGroupContent: (e) ->
    e.stopImmediatePropagation()
    $group = $(e.target).closest('.page-item-group-content')
    if $group.hasClass('open')
      $group.removeClass('open')
      UI.destroyRedactor($group.find('textarea.wysiwyg.manual'))
    else
      UI.setupRedactor($group.find('textarea.wysiwyg.manual'))
      $group.addClass('open')
    this.saveBlockVisibility()

  saveBlockVisibility: ->
    url = @$("form").data("update-preferences-path")

    open_groups = @$('.page-item-group-content.open')
    open_repeatables = @$('.repeatable-content.open')

    open_container_ids = []
    open_groups.each ->
      wrapper = $(this).closest('.page-item-group-wrapper')
      if wrapper? && wrapper.attr('id')?
        open_container_ids.push(wrapper.attr('id'))

    open_repeatables.each ->
      wrapper = $(this).closest('.repeatable-wrapper')
      if wrapper? && wrapper.attr('id')?
        open_container_ids.push(wrapper.attr('id'))

    open_container_ids.push('') if open_container_ids.length == 0
    
    data =
      open: open_container_ids

    $.post(url,data)

  initCanvas: ($canvas) ->
    # $canvas is a .repeatables-list
    # to prevent dragging between canvases, let's give each canvase a unique id
    uuid = Nimbu.uuid()
    $canvas.addClass("canvas-#{uuid}")
    # console.log('canvas uuid', uuid)

    $container = $canvas.closest('.canvas-wrapper')
    $container.data('canvas-uuid', uuid)
    $container.attr('data-canvas-uuid', uuid)

    currentTemplateId = $container.data('canvas-template-id')
    $addRepeatableTriggers = $container.find("[data-canvas-template-id=#{currentTemplateId}]")
    $addRepeatableTriggers.data('canvas-uuid', uuid)
    $addRepeatableTriggers.attr('data-canvas-uuid', uuid)
    
    # console.log('currentTemplateId', currentTemplateId)
    # console.log('$addRepeatableTriggers', $addRepeatableTriggers)

    $canvas.sortable(
      tabSize: 30
      maxLevels: 0
      cursor: "move"
      forcePlaceholderSize: true
      handle: "header.repeatable-header > .repeatable-icons > .drag-handle"
      listType: "ul.canvas-#{uuid}"
      items: "li"
      opacity: .6
      axis: 'y'
      disableParentChange: true
      update: (event, ui) ->
        event.originalEvent.stopImmediatePropagation()
        cnt = 0
        $(this).find('.repeatable-wrapper').each ->
          item = $(this)
          item.find("input.position-tracker").attr('value',cnt)
          cnt++
    )
