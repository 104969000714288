import $ from "jquery";

class App.Views.EditCustomFieldAuthenticatableView extends Backbone.View
  initialize: ->
    @view = $(@el)

  events:
    "change #site_settings_attributes_custom_field_authenticatable": "changedCustomFieldAuthenticatable"

  changedCustomFieldAuthenticatable: (e) ->
    @view.find('.custom-field-authenticatable-fields').toggle()
