import $ from "jquery";
import { setupPhoneInput } from "../../utils";

class App.Views.RetailLocationView extends Backbone.View
  initialize: ->
    view = $(@el)
    view.data('view',this)

    setupPhoneInput(@$('#location_address_attributes_phone'))

  events:
    "change .location-address-selector select" : "changeCountry"

  changeCountry: ->
    select_wrapper = @$('#location_state_code_wrapper')
    $('select', select_wrapper).attr('disabled', true)
    country_code = @$('.location-address-selector select').val()
    url = "#{select_wrapper.data('subregion-url')}?parent_region=#{country_code}"
    select_wrapper.load(url, ->
      $('#location_state_code_wrapper .selectizable').selectize()
    )
