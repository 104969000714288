import { Controller } from "@hotwired/stimulus";

export class DashboardController extends Controller {
  static targets = ["json"];
  static values = {
    from: String,
    to: String,
  };

  download(event) {
    event.preventDefault();
    const data = JSON.parse(this.jsonTarget.innerHTML);

    // select all a.kpi
    const links = this.element.querySelectorAll("a.kpi");

    // get kpi from each link
    const kpis = [];
    for (const link of links) {
      kpis.push(link.dataset.kpi);
    }

    const rows = [];

    // convert the data for each kpi into a csv: data[kpi].time, data[kpi].data
    for (let i = 0; i < data[kpis[0]].time.length; i++) {
      let timeInteger = data[kpis[0]].time[i];
      // add two hours to the time to avoid timezone issues
      timeInteger += 2 * 60 * 60 * 1000;
      const timeDate = new Date(timeInteger);
      const dateString = timeDate.toISOString().split("T")[0];
      const row = [dateString];
      for (const kpi in kpis) {
        row.push(data[kpis[kpi]].data[i]);
      }
      rows.push(row);
    }

    // add the header
    const header = ["time"];
    for (const kpi of kpis) {
      header.push(kpi);
    }

    let csv = header.join(",") + "\n";

    // add the rows
    for (const row of rows) {
      csv += row.join(",") + "\n";
    }

    const link = document.createElement("a");
    link.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
    link.download = `analytics-from-${this.fromValue}-to-${this.toValue}.csv`;
    link.click();
  }
}

export default DashboardController;
