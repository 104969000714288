import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export class SelectizeController extends Controller {
  setupSelectizeOptions($el, options = {}) {
    let selectOptions;
    const searchUrl = $el.data("search-url");
    const dropDownParent = $el.data("selectize-dropdown-parent");
    const createable = $el.data("selectize-creatable");
    const disableSearch = $el.data("selectize-disable-search");

    if (searchUrl != null && searchUrl !== "") {
      // popuplate options from DOM
      selectOptions = [];
      $el.find("option").each((_, option) => {
        const data = $(option).data("json");
        if (data != null) {
          selectOptions.push(data);
        }
      });
      options.options = selectOptions;

      // set search defaults
      options.valueField = "id";
      options.labelField = "name";
      options.searchField = ["name"];
      options.render = {
        option: (item, escape) =>
          `<div data-json='${JSON.stringify(item)}' data-selectable="" class="option">${escape(item.name)}</div>`,
      };
      options.load = (query, callback) => {
        if (!query.length) return callback();

        $.getJSON(searchUrl, { search: query }, (res) => callback(res));
      };
    }

    options.plugins = [];

    if ($el.hasClass("selectizable-with-draggable-items") || $el.data("selectize-draggable")) {
      options.plugins = ["drag_drop", "remove_button"];
    } else if ($el.hasClass("references_many") || $el.data("selectize-removable")) {
      options.plugins = ["remove_button"];
    }

    if (disableSearch || disableSearch === "true") {
      options.onInitialize = function () {
        return this.$control_input.attr("readonly", true);
      };
    }

    if ($el.data("datatype") == "pages") {
      $el.addClass("custom-selectize");
      const renderPageDepth = (depth) => {
        let str = '<div class="page-depth">';
        for (let i = 0; i < depth; i++) {
          str += "──";
        }
        str += "</div>";
        return str;
      };

      options.render = {
        option: function (item, escape) {
          return `
            <div class='option page-select-option'>
              ${renderPageDepth(item.depth)}
              <div class='option-title-wrapper'>
                <div class='option-title'>${escape(item.title)}</div>
                <div class='option-url'>${escape(item.url)}</div>
              </div>
            </div>`.replace(/  +/g, "");
        },
        item: function (item, escape) {
          return `
            <div class='page-select-item'>
              <div class='option-title'>${escape(item.title)}</div>
              <div class='option-url'>${escape(item.url)}</div>
            </div>`.replace(/  +/g, "");
        },
      };
    } else if ($el.data("datatype") == "roles") {
      $el.addClass("custom-selectize");
      options.searchField = ["name", "description"];
      options.plugins = [...options.plugins, "remove_button"];
      options.render = {
        option: (item, escape) => {
          return `<div class="selectize-role-wrapper" data-json='${JSON.stringify(item)}'>
                    <span class="option-name">${escape(item.name)}</span>
                    ${
                      item.description != null && item.description.trim() !== ""
                        ? `<span class="option-description">&mdash; ${item.description}</span>`
                        : ""
                    }
                  </div>`.replace(/  +/g, "");
        },
      };
    } else if ($el.data("datatype") == "customers") {
      $el.addClass("custom-selectize");
      options.searchField = ["name", "email"];
      options.render = {
        option: (item, escape) => {
          return `<div class="selectize-customer-wrapper" data-json='${JSON.stringify(item)}'>
                    <span class="option-name">${escape(item.name)}</span>
                    <span class="option-email">&mdash; ${item.email}</span>
                  </div>`.replace(/  +/g, "");
        },
        item: function (item, escape) {
          return `
            <div class='selectize-customer-wrapper'>
              <span class='option-name'>${escape(item.name)}</span>
              <span class='option-email'>&mdash; ${escape(item.email)}</span>
            </div>`.replace(/  +/g, "");
        },
      };
    }

    if (dropDownParent != null && dropDownParent !== "") {
      options.dropdownParent = dropDownParent;
    }

    if (createable || createable === "true") {
      options.create = true;
    }

    return options;
  }

  connect() {
    const $el = $(this.element);
    const options = this.setupSelectizeOptions($el, {
      dataAttr: "data-json",
    });
    $el.selectize(options);
  }
}

export default SelectizeController;
