import $ from "jquery";

class App.Views.DashboardView extends Backbone.View
  initialize: ->
    json = @$('#visits-data').html()
    @visitData = JSON.parse(json)
    @options =
      xaxis:
        mode: "time"
        color: "#88888b"
        tickSize: [4, "day"]
        reserveSpace: true
      yaxis:
        ticks: 5
        color: "#666666"
      series:
        hoverable: true
        lines:
          show: true,
          fill: true,
          fillColor: "rgba(236, 236, 236, 0.25)"
        points:
          radius: 3.7
          lineWidth: 0.5
          fillColor: "#4caaed",
          highlightColor: "#000",
          show: true
          fill: true
          symbol: (ctx, x, y, radius, shadow, hover) ->
            if hover? && hover
              ctx.fillStyle="#fff"
              ctx.lineWidth = 3
              radius = radius * 1.1
              ctx.strokeStyle="rgba(76,170,237,0.6)";
            else
              ctx.fillStyle="#4caaed"
              ctx.strokeStyle="#fff";
            ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
      colors: [ "#4caaed" ]
      grid:
        color: "#eaeaea"
        tickColor: "#eaeaea"
        borderWidth: 1
        labelMargin: 10
        borderColor: null
        hoverable: true
        autoHighlight: true

    this.drawVisitsChart()
    this.bindChartEvents()

  events:
    "click .kpi" : "changeKpi"

  bindChartEvents: ->
    app = this
    previousPoint = null

    showTooltip = (x, y, label, time) ->
      $("<div class='chart-tooltip-wrapper'><div class='chart-tooltip' id=\"chart_tooltip\"><strong>" + label + "</strong>" + time + "</div></div>").css(
        position: "absolute"
        display: "none"
        top: y - 60
        left: x
        opacity: 0.80
      ).appendTo("body").fadeIn(200)

    @$('#chart').bind "plothover", (event, pos, item) ->
      if item
        unless previousPoint == item.dataIndex
          previousPoint = item.dataIndex
          $("#chart_tooltip").remove()
          x = item.datapoint[0].toFixed(2)
          y = item.datapoint[1].toFixed(2)
          showTooltip item.pageX, item.pageY, app.activeLabels[item.dataIndex], app.activeTimeserie[item.dataIndex]
      else
        $("#chart_tooltip").remove()
        previousPoint = null


  changeKpi: (e) ->
    target = $(e.currentTarget)
    @$(".kpi").removeClass("active")
    target.addClass("active")
    switch target.data("kpi")
      when "visits"
        @plot = $.plot(@$('#chart'), [ data: @visitData.visits.series ], @options)
        @activeLabels = @visitData.visits.labels
        @activeTimeserie = @visitData.visits.data_in_words
      when "new"
        @plot = $.plot(@$('#chart'), [ data: @visitData.new.series ], @options)
        @activeLabels = @visitData.new.labels
        @activeTimeserie = @visitData.new.data_in_words
      when "avg_depth"
        @plot = $.plot(@$('#chart'), [ data: @visitData.avg_depth.series ], @options)
        @activeLabels = @visitData.avg_depth.labels
        @activeTimeserie = @visitData.avg_depth.data_in_words
      when "avg_time"
        @plot = $.plot(@$('#chart'), [ data: @visitData.avg_time.series ], @options)
        @activeLabels = @visitData.avg_time.labels
        @activeTimeserie = @visitData.avg_time.data_in_words
      when "pageviews"
        @plot = $.plot(@$('#chart'), [ data: @visitData.pageviews.series ], @options)
        @activeLabels = @visitData.pageviews.labels
        @activeTimeserie = @visitData.pageviews.data_in_words

  drawVisitsChart: ->
    @plot = $.plot(@$('#chart'), [ data: @visitData.visits.series ], @options)
    @activeLabels = @visitData.visits.labels
    @activeTimeserie = @visitData.visits.data_in_words
