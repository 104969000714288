import $ from "jquery";

class App.Views.EditDomainView extends Backbone.View
  initialize: (options) ->
    @options = options
    # store the original values:
    @view = $(@el)
    @$('.details').disableSelection()
    @view.data('view',this)

  events:
    "click .header"   : "toggleDetails"
    "change .google-verification-input" : "submitForm"

  toggleDetails: (e) ->
    # do not toggle if we are clicking a link
    return if $(e.target).is('a')
    e.preventDefault()

    # close all other mail forms
    $(@options.parent).find(".domain:not(##{$(@el).attr('id')}) .details").hide()
    @$('.details').toggle()

  submitForm: (e) ->
    @$('.upload-google-verification').submit()
