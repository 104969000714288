import $ from "jquery";
import _ from "underscore";

class App.Views.EditIntegrationView extends Backbone.View
  initialize: (options) ->
    @options = options
    @el = options.el
    _.bindAll(this, "toggleConfiguration");
    UI.enableCheckedElement()
    UI.selectize(@$('select.selectizable:not(.selectized)'))
    
  events:
    "click .configure-link"     : "toggleConfiguration"

  toggleConfiguration: (e) ->
    el = $(e.currentTarget)
    pm = el.closest('.integration')
    id = pm.attr('id')
    @options.parent.hideAllConfiguration(id)
    pm.find('.configuration').toggle()
