import $ from "jquery";

class App.Views.IntegrationsView extends Backbone.View
  initialize: ->
    view = this
    $(@el).data('view', this)

    @$('.integration').each ->
      new App.Views.EditIntegrationView({el: $(this), parent: view })

  hideAllConfiguration: (current) ->
    @$(".integration:not(##{current}) .configuration").hide()
