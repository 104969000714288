(function($R) {
  $R.add('plugin', 'imagemanager', {
    translations: {
      en: {
        choose: 'Choose',
        prev: '< Previous',
        next: 'Next >',
      },
    },
    init: function(app) {
      this.app = app;
      this.lang = app.lang;
      this.opts = app.opts;
    },
    // messages
    onmodal: {
      image: {
        open: function($modal, $form) {
          if (!this.opts.imageManagerJson) return;
          this._load($modal);
        },
      },
    },

    // private
    _load: function($modal) {
      var $body = $modal.getBody();

      this.$wrapper = $R.dom('<div>');
      this.$wrapper.attr('data-title', this.lang.get('choose'));
      this.$wrapper.addClass('redactor-modal-tab');
      this.$wrapper.hide();

      this.$box = $R.dom('<div>');
      this.$box.css({
        overflow: 'auto',
        height: '175px',
        'line-height': 1,
      });

      this.$wrapper.append(this.$box);

      var $buttons = $R.dom('<div>');
      $buttons.addClass('redactor-image-manager-pagination');
      this.$prev = $R.dom('<button>' + this.lang.get('prev') + '</button>');
      this.$prev.on('click', this._previous.bind(this));
      $buttons.append(this.$prev);
      this.$next = $R.dom('<button>' + this.lang.get('next') + '</button>');
      this.$next.on('click', this._next.bind(this));
      $buttons.append(this.$next);
      this.$wrapper.append($buttons);

      $body.append(this.$wrapper);

      $R.ajax.get({
        url: this.opts.imageManagerJson,
        success: this._parse.bind(this),
      });
    },
    _parse: function(data) {
      this.images = [];
      for (var key in data) {
        var obj = data[key];
        if (typeof obj !== 'object') continue;
        this.images.push(obj);
        this.page = 0;
        this._show();
      }
    },
    _next: function() {
      this.page++;
      this._show();
    },
    _previous: function() {
      this.page--;
      this._show();
    },
    _show: function() {
      var page = this.page;
      var perPage = 10;
      var start = page * perPage;
      if (start < this.images.length) {
        this.$box.empty();
        var end = start + perPage;
        for (var i = start; i < end && i < this.images.length; ++i) {
          var $img = this._convert(this.images[i]);
          this.$box.append($img);
        }
      }
      if (start > 0) {
        this.$prev.css({
          visibility: 'visible',
        });
      } else {
        this.$prev.css({
          visibility: 'hidden',
        });
      }
      if (end < this.images.length) {
        this.$next.css({
          visibility: 'visible',
        });
      } else {
        this.$next.css({
          visibility: 'hidden',
        });
      }
    },
    _convert: function(obj) {
      var $img = $R.dom('<img>');
      var url = obj.thumb ? obj.thumb : obj.url;

      $img.attr('src', url);
      $img.attr('data-params', encodeURI(JSON.stringify(obj)));
      $img.css({
        width: '96px',
        height: '72px',
        margin: '0 4px 2px 0',
        cursor: 'pointer',
      });

      $img.on('click', this._insert.bind(this));

      return $img;
    },
    _insert: function(e) {
      e.preventDefault();

      var $el = $R.dom(e.target);
      var data = JSON.parse(decodeURI($el.attr('data-params')));

      this.app.api('module.image.insert', { image: data });
    },
  });
})(Redactor);
