import { Controller } from "@hotwired/stimulus";
import { initialize, cleanup } from "../../consent-manager";

export default class extends Controller {
  connect() {
    initialize(this.element);
  }

  disconnect() {
    cleanup();
  }
}
