import $ from "jquery";

class App.Views.SiteImportView extends Backbone.View
  initialize: ->
    view = this

    this.initUploader()

  initUploader: ->
    numberOfFiles = 0
    crntUpload = 0
    el = @$('#new_site_import')

    tearDown = ->
      if el.data('blueimpFileupload')?
        el.fileupload('destroy')
      document.removeEventListener("turbolinks:before-cache", tearDown)
    document.addEventListener("turbolinks:before-cache", tearDown)

    el.fileupload(
      pasteZone: $('#import_site .nimbu-body')
      dropZone: $('#import_site .nimbu-body')
      dataType: 'json'
      sequentialUploads: true
      limitConcurrentUploads: 1
      url: el.data('upload-url')
      type: 'POST'

      add: (e, data) ->
        #UI.closeModal()

        # The example input, doesn't have to be part of the upload form:
        file = data.files[0]

        this.acceptFileTypes = /(\.|\/)(zip)$/i
        if(!(this.acceptFileTypes.test(file.type) || this.acceptFileTypes.test(file.name)))
          return false

        data.key = el.data('key').replace("${filename}", file.name)
        data.filename = file.name
        data.formData =
          key: data.key
          AWSAccessKeyId: el.data('access-key-id')
          acl: el.data('access-acl')
          policy: el.data('access-policy')
          signature: el.data('access-signature')
          success_action_status: "200"
          'x-amz-server-side-encryption': 'AES256'
          'Content-Type': file.type

        numberOfFiles++
        data.submit()

      send: (e, data) ->
        crntUpload++

      start: ->
        widget = $(this)
        modalForm = $('.nimbu-modal').transition({ opacity: 0 })
        modalBG = $('.uploader-bg')
        if modalBG.length == 0
          modalBG = $('<div class="uploader-bg" />').appendTo($('body'))
        modalBG.css({'opacity' : 0, 'display' :'block'})
               .transition({ opacity: 1 })
               .data('preloaded', true)

        modalLoader = $('.uploader-progress')
        if modalLoader.length == 0
          modalLoader = $("<div class='uploader-progress'><div class='label'>Please Wait while uploading</div><div class='progressbar-wrapper'><div class='progressbar' style='width:0%;'></div></div><div class='progress-info'></div></div>").css({'opacity' : 0, 'display' :'block', 'scale': 0.95 }).appendTo($('body'))
        modalLoader.transition({ opacity: 1, duration: 400, scale: 1, easing: 'out'})

        progressElement = $('.uploader-progress .progress-info')
        progressBar = $('.uploader-progress .progressbar')
        interval = 100
        total = 0
        loaded = 0
        loadedBefore = 0
        crntfile = ""
        progressTimer = undefined

        progressHandler = (e, data) ->
          loaded = data.loaded
          total = data.total

        stopHandler = ->
          numberOfFiles = 0
          crntUpload = 0

          window.setTimeout(stopIntervalHandler, 2000)

        formatTime = (seconds) ->
          date = new Date(seconds * 1000)
          ("0" + date.getUTCHours()).slice(-2) + ":" + ("0" + date.getUTCMinutes()).slice(-2) + ":" + ("0" + date.getUTCSeconds()).slice(-2)

        formatBytes = (bytes) ->
          return (bytes / 1000000000).toFixed(2) + " GB"  if bytes >= 1000000000
          return (bytes / 1000000).toFixed(2) + " MB"  if bytes >= 1000000
          return (bytes / 1000).toFixed(2) + " KB"  if bytes >= 1000
          bytes + " B"

        formatPercentage = (floatValue) ->
          (floatValue * 100).toFixed(2) + "%"

        updateProgressElement = (file,loaded, total, bps) ->
          progressElement.html("Uploading file #{crntUpload} / #{numberOfFiles} at " + formatBytes(bps) + "ps | " + formatPercentage(loaded / total) + " done)")
          progressBar.css('width',formatPercentage(loaded / total))

        intervalHandler = ->
          diff = loaded - loadedBefore
          return  unless diff
          loadedBefore = loaded
          updateProgressElement(crntfile,loaded, total, diff * (100 / interval))

        stopIntervalHandler = ->
          widget.unbind("fileuploadprogressall", progressHandler)
                .unbind "fileuploadstop", stopHandler
          window.clearInterval progressTimer

        widget.bind("fileuploadprogressall", progressHandler)
              .bind("fileuploadstop", stopHandler)

        progressTimer = window.setInterval(intervalHandler, interval)

      always: (e, data) ->
        return unless data.filename?

        # let's create this thing in the database
        $.ajax(
          type: 'POST'
          dataType: 'script'
          url: el.data('callback-url')
          data: { import: { s3_upload_key: data.filename } }
          success: (response) ->
            UI.closeModal()

            modalBG = $('.uploader-bg')
            modalLoader = $('.uploader-progress')

            modalBG.transition
              opacity: 0
              duration: 800
            , ->
              $(this).css(
                display: "none"
                opacity: 0
              ).remove()

            modalLoader.transition
              opacity: 0
              scale: 0.8
              duration: 500
            , ->
              $(this).css(
                display: "none"
                opacity: 0
              ).remove()
        )
    )
