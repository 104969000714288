import Handlebars from "handlebars";
import $ from "jquery";

class App.Views.CustomizableView extends Backbone.View

  initialize: ->
    this.initUploaders()

    options =
      zIndex: 1000,
      items: '.gallery-image',
      tolerance: 'pointer',
      update: (event, ui) ->
        cnt = 0
        $(this).find('.gallery-image').each ->
          el = $(this).find("input.position-field").attr('value',cnt)
          cnt++

    userAgent = navigator.userAgent.toLowerCase()
    if userAgent.match(/firefox/)
      options["start"] = (event, ui) ->
        ui.item.css('margin-top', $(window).scrollTop() )
      options["beforeStop"] = (event, ui) ->
        ui.item.css('margin-top', 0 )

    @$(".images-list").sortable(options)
    @$(".images-list").disableSelection();

  events:
    "change select.auto-expanding"     : "changedDropdown"
    "click .gallery-image .destroy > a": "removeImage"

  removeImage: (e) ->
    link = e.target
    $(link).prev().val("1")
    $(link).closest(".gallery-image").hide()


  changedDropdown: (e) ->
    obj = @$(e.target)
    if obj.val() == "add_new_item"
      # show the text input field
      url = obj.closest(".input").data('new-category-url')
      UI.showPreloader()
      $.get(url)
      obj.find('option:first').attr('selected', 'selected').parent('select')
      $.uniform.update()

  initUploaders: ->
    @$('.custom-gallery').each (i,gallery) ->
      gallery = $(gallery)
      image_uploaded_template = Handlebars.compile(gallery.find('.image-upload-template').html());

      numberOfFiles = 0
      crntUpload = 0
      el = gallery.find('.new-gallery-image')

      tearDown = ->
        if el.data('blueimpFileupload')?
          el.fileupload('destroy')
        document.removeEventListener("turbolinks:before-cache", tearDown)
      document.addEventListener("turbolinks:before-cache", tearDown)

      el.fileupload(
        pasteZone: gallery
        dropZone: gallery
        dataType: 'xml'
        multipart: false
        sequentialUploads: true
        processData: false,
        limitConcurrentUploads: 1
        type: 'PUT'

        add: (e, data) ->
          # The example input, doesn't have to be part of the upload form:
          file = data.files[0]

          this.acceptFileTypes = /(\.|\/)(gif|jpe?g|png)$/i
          if(!(this.acceptFileTypes.test(file.type) || this.acceptFileTypes.test(file.name)))
            return false

          prefix = new Date().getTime().toString()
          filename = prefix + '/' + file.name

          sign_uploads_url = el.data('sign-uploads-url')

          $.ajax(
            type: 'POST'
            url: sign_uploads_url
            data: { filename: filename, content_type: file.type }
            success: (response) ->
              data.filename = response.filename
              data.key = response.key
              data.url = response.url
              data.formData = response.formData
              data.headers = response.headers
              data.submit()
          )

        always: (e, data) ->
          if gallery.find('.images-list .first-upload').length > 0
            gallery.find('.images-list .first-upload').remove()

          new_id = new Date().getTime()
          regexp = new RegExp("new_image", "g")
          content = image_uploaded_template(
            id: "new_image"
            key: data.filename
            thumb: "#{$('meta[name="nimbu:cdn"]').attr('content')}/p_compact/" + data.key
          )
          image = $(content.replace(regexp, new_id))
          if gallery.find('.images-list').hasClass('empty')
            gallery.find('.images-list').slideDown( ->
              $(this).removeClass('empty')
            )
          image.appendTo(gallery.find('.images-list'))
          image.hide().css('opacity',0).show().animate({opacity: 1})

        send: (e, data) ->
            crntUpload++

        start: ->
          widget = $(this)


          modalBG = $('.uploader-bg')
          if modalBG.length == 0
            modalBG = $('<div class="uploader-bg" />').appendTo($('body'))
          modalBG.css({'opacity' : 0, 'display' :'block'})
                 .transition({ opacity: 1 })
                 .data('preloaded', true)

          modalLoader = $('.uploader-progress')
          if modalLoader.length == 0
            modalLoader = $("<div class='uploader-progress'><div class='label'>Please Wait while uploading</div><div class='progressbar-wrapper'><div class='progressbar' style='width:0%;'></div></div><div class='progress-info'></div></div>").css({'opacity' : 0, 'display' :'block', 'scale': 0.95 }).appendTo($('body'))
          modalLoader.transition({ opacity: 1, duration: 400, scale: 1, easing: 'out'})

          progressElement = $('.uploader-progress .progress-info')
          progressBar = $('.uploader-progress .progressbar')
          interval = 100
          total = 0
          loaded = 0
          loadedBefore = 0
          crntfile = ""
          progressTimer = undefined

          progressHandler = (e, data) ->
            loaded = data.loaded
            total = data.total

          stopHandler = ->
            numberOfFiles = 0
            crntUpload = 0

            modalBG = $('.uploader-bg')
            modalLoader = $('.uploader-progress')

            modalBG.delay(1000).transition
              opacity: 0
              duration: 800
            , ->
              $(this).css(
                display: "none"
                opacity: 0
              ).remove()

            modalLoader.delay(1000).transition
              opacity: 0
              scale: 0.8
              duration: 500
            , ->
              $(this).css(
                display: "none"
                opacity: 0
              ).remove()

            window.setTimeout(stopIntervalHandler, 2000)

          formatTime = (seconds) ->
            date = new Date(seconds * 1000)
            ("0" + date.getUTCHours()).slice(-2) + ":" + ("0" + date.getUTCMinutes()).slice(-2) + ":" + ("0" + date.getUTCSeconds()).slice(-2)

          formatBytes = (bytes) ->
            return (bytes / 1000000000).toFixed(2) + " GB"  if bytes >= 1000000000
            return (bytes / 1000000).toFixed(2) + " MB"  if bytes >= 1000000
            return (bytes / 1000).toFixed(2) + " KB"  if bytes >= 1000
            bytes + " B"

          formatPercentage = (floatValue) ->
            (floatValue * 100).toFixed(2) + "%"

          updateProgressElement = (file,loaded, total, bps) ->
            progressElement.html("Uploading file #{crntUpload} / #{numberOfFiles} at " + formatBytes(bps) + "ps | " + formatPercentage(loaded / total) + " done)")
            progressBar.css('width',formatPercentage(loaded / total))

          intervalHandler = ->
            diff = loaded - loadedBefore
            return  unless diff
            loadedBefore = loaded
            updateProgressElement(crntfile,loaded, total, diff * (100 / interval))

          stopIntervalHandler = ->
            widget.unbind("fileuploadprogressall", progressHandler)
                  .unbind "fileuploadstop", stopHandler
            window.clearInterval progressTimer

          widget.bind("fileuploadprogressall", progressHandler)
                .bind("fileuploadstop", stopHandler)

          progressTimer = window.setInterval(intervalHandler, interval)
      )
