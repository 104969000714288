import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "true", "false"];
  static outlets = ["subsection"];

  connect() {
    const isTrue = (this.hasCheckboxTarget ? this.checkboxTarget.value : this.element.value) === "true";

    if (this.hasTrueTargets) {
      this.trueTargets.forEach((target) => {
        this.setVisible(target, isTrue);
      });
    }

    if (this.hasFalseTargets) {
      this.falseTargets.forEach((target) => {
        this.setVisible(target, !isTrue);
      });
    }

    if (this.hasSubsectionOutlet) {
      setTimeout(() => this.subsectionOutlets.forEach((outlet) => this.setVisible(outlet.element, isTrue)), 100);
    }
  }

  toggle(e) {
    const isTrue = e.target.value === "true";

    if (this.hasTrueTargets) {
      this.trueTargets.forEach((target) => {
        this.setVisible(target, isTrue);
      });
    }

    if (this.hasFalseTargets) {
      this.falseTargets.forEach((target) => {
        this.setVisible(target, !isTrue);
      });
    }

    if (this.hasSubsectionOutlet) {
      this.subsectionOutlets.forEach((outlet) => this.setVisible(outlet.element, isTrue));
    }
  }

  setVisible(target, visible) {
    target.style.display = visible ? "" : "none";
  }
}
