import $ from "jquery";
import _ from "underscore";

class App.Views.PagesIndexView extends Backbone.View
  initialize: ->
    # initialize the sorting tree
    this.initTree()

  events:
    "change input#page_homepage" : "changeHomepage"

  changeHomepage: (e) ->
    @$("#homepage_id").closest('form').submit()

  initTree: () ->
    saveTree = (ui) ->
      if typeof ui != "undefined" and typeof ui != "undefined"
        order = $(".cms-sortable").nestedSortable("toArray")
        for key of order
          delete order[key]["left"]
          delete order[key]["right"]
        order = _.filter(order, (item) ->
          item.item_id?
        )

      state = new Array()
      $.each $("#cms-pages li ol:visible"), ->
        li = $(this).parent("li")
        state.push get_id(li.attr("id"))  if li.children("ol").children("li").length

      ajaxurl = $("#cms-pages").attr("data-sortable-link")
      $.post ajaxurl,
        order: order
        open: state
      , (data) ->
        $(ui.item).find("div").removeClass "loading"  if typeof ui != "undefined"
        prependToggle()

    prependToggle = (initializing) ->
      submenus = null
      $.each $("#cms-pages li"), ->
        children = $(this).children("ol").children("li")
        div = $(this).children("div")
        if children.length > 0
          submenus += 1
          li = div.parent("li")
          if li.children(".cms-toggle").length == 0
            li.append "<span class=\"cms-toggle\"></span>"
            li.addClass "cms-open" unless li.hasClass("cms-closed")

          if initializing
            li.children(".cms-toggle").click ->
              li = $(this).parent("li")
              
              if li.hasClass("cms-open")
                li.addClass "cms-closing"
              else
                li.addClass "cms-opening"

              li.children("ol").slideToggle 80, ->
                li.toggleClass "cms-open cms-closed"
                li.removeClass "cms-opening cms-closing"
                saveTree()
        else div.children(".cms-toggle").remove()  if children.length == 0 and div.children(".cms-toggle").length > 0
        count = div.children(".cms-count")
        count.text(children.length)  unless count.text() == children.length

    get_id = (str) ->
      str.substr 5
    cms =
      Expand_all: "Expand all"
      Collapse_all: "Collapse all"

    sortableOptions =
      tabSize: 16
      maxLevels: 0
      cursor: "move"
      forcePlaceholderSize: true
      handle: "div"
      items: "li"
      opacity: .6
      placeholder: "placeholder"
      revert: 80
      revertOnError: true
      tolerance: "pointer"
      toleranceElement: "> div"
      update: (event, ui) ->
        $(ui.item).find("div").addClass "loading"
        saveTree ui

    userAgent = navigator.userAgent.toLowerCase()

    if userAgent.match(/firefox/)
      sortableOptions["start"] = (event, ui) ->
        ui.item.css('margin-top', $(window).scrollTop() )
        debug.log ui.item
      sortableOptions["beforeStop"] = (event, ui) ->
        ui.item.css('margin-top', 0 )
        debug.log ui.item

    $(".cms-sortable").nestedSortable(sortableOptions)
    $(".cms-sortable").disableSelection()

    prependToggle(true)
