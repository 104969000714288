import $ from "jquery";
import _ from "underscore";

class App.Views.EditProductVariantSettingsView extends Backbone.View
  initialize: (options) ->
    @options = options
    @data = @options.data
    @$('.tax-scheme-id-subsection select')[0].selectize.setValue(@data.tax_scheme_id)
    _.bindAll(this, "changeChargeTaxes", "submitForm");
    UI.enableCheckedElement()

  events:
    "submit form"                     : "submitForm"
    "change #variant_custom_tax_rate" : "changeChargeTaxes"

  changeChargeTaxes: (e) ->
    @$('.tax-scheme-id-subsection').toggle()
    UI.resetModal()

  submitForm: (e) ->
    e.preventDefault()

    inverse = (trueFalse) ->
      if trueFalse == "false"
        "true"
      else
        "false"

    # get the value of the form
    modal = $(@el)

    variant_id        = modal.find('form').data('variant-id')
    no_shipping_value = inverse(modal.find('#variant_no_shipping').val())
    no_taxes_value    = inverse(modal.find('#variant_no_taxes').val())
    custom_tax_rate   = modal.find('#variant_custom_tax_rate').val()
    tax_scheme_id     = modal.find('#variant_tax_scheme_id').val()

    if custom_tax_rate == "false"
      no_taxes_value = false
    else if tax_scheme_id == "no_taxes"
      no_taxes_value = true
      tax_scheme_id = ""
    else
      no_taxes_value = false

    # store it in the real product form
    $("##{variant_id}").find('input.no-taxes').val(no_taxes_value)
    $("##{variant_id}").find('input.no-shipping').val(no_shipping_value)
    $("##{variant_id}").find('input.custom-tax-rate').val(custom_tax_rate)
    $("##{variant_id}").find('input.tax-scheme-id').val(tax_scheme_id)

    # close the modal window
    UI.closeModal()
