import $ from "jquery";
import _ from "underscore";
import unescapeHTML from "underscore.string/unescapeHTML";

class App.Views.ProductPricingSchemeView extends Backbone.View
  initialize: (options) ->
    @options = options
    view = this

    _.bindAll(this, "addPricePoint", "hideOrShowPricePointDestroyButtons", "destroyPricingScheme");
    UI.tooltips()

    @$(".price-points-list .price-point").each ->
      new App.Views.ProductPricingSchemePricePointView({el: $(this), parent: view})

    @$("select.selectizable:not(.selectized)").each ->
      $(this).selectize()


  events:
    "click .remove > a"                 : "destroyPricingScheme"
    "click .add-price-point"            : "addPricePoint"

  destroyPricingScheme: (e) ->
    $(this.el).addClass('hidden')
    link = e.target
    $(link).prev().find("input[type=hidden]").val("1")
    @options.parent.hideOrShowProductPricingSchemeDestroyButtons()

  addPricePoint: (e) ->
    link = e.target
    pricing_scheme = $(link.closest(".pricing-scheme"))
    pricing_scheme_id = pricing_scheme.data('pricing-scheme-id')
    tpl = $("#new-price-point-template").html()
    pp_list = pricing_scheme.find(".price-points-list")
    new_price_id = new Date().getTime()
    price_regexp = new RegExp("new_price_point", "g")
    scheme_id = pricing_scheme.data('child-index')
    scheme_regexp = new RegExp("new_pricing_scheme", "g")
    tpl = tpl.replace(price_regexp, new_price_id).replace(scheme_regexp, scheme_id)
    el = $(unescapeHTML(tpl)).appendTo(@$(pp_list))
    new App.Views.ProductPricingSchemePricePointView({el: $(el), parent: this})
    this.hideOrShowPricePointDestroyButtons()

  hideOrShowPricePointDestroyButtons: ->
    if @$(".price-points-list .price-point:not(.hidden)").length < 2
      @$(".price-points-list .price-point").each ->
        $(this).find('.remove-pp').hide()
    else
      @$(".price-points-list .price-point").each ->
        $(this).find('.remove-pp').show()
