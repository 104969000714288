import $ from "jquery";

class App.Views.CouponsEditView extends Backbone.View
  initialize: ->
    this.initPriceWatch()
    this.initCustomerSelector()
    this.initProductsSelector()

  events:
    "click input:radio[name='coupon[coupon_type]']"  : "changedCouponType"
    "click input:radio[name='coupon[lifespan]']"     : "changedCouponLifespan"
    "click input:radio[name='coupon[start_type]']"   : "changedCouponStart"
    "click input:radio[name='coupon[requirements]']" : "changedRequirements"
    "click input:radio[name='coupon[constraints]']"  : "changedConstraints"
    "click a.random-code"                            : "generateRandomCoupon"
    "click a.shortcut-lifespan-time"                 : "setLifespanTime"
    "keypress #coupon_code"                          : "checkTypedCharacter"

  initProductsSelector: ->
    ids = []
    options = []
    @$('#coupon_product_ids option').each (i, el) ->
      options.push($(el).data('data'))
      ids.push($(el).attr('value'))
    @$('#coupon_product_ids').selectize
      plugins: ['remove_button'],
      theme: "subproducts"
      valueField: "id"
      labelField: "name"
      searchField: ["name","description"]
      options: options
      create: false
      render:
        option: (item, escape) ->
          '<div class="sub-product-wrapper">' +
            ( if item.image? && item.image.url? then ('<img src="' + escape(item.image.url + '?filter=p_thumb') + '" alt="">') else ('<img src="' + escape(item.image) + '" alt="">')) +
            '<span class="title">' +
              '<span class="name">' + escape(item.name) + '</span>' +
            '</span>' +
            '<span class="description">' + item.description + '</span>' +
          '</div>';

      load: (query, callback) ->
        return callback()  unless query.length
        $.getJSON($('#coupon_product_ids').data('search-url'), search: query, (res) ->
            callback res
          )
    @$('#coupon_product_ids')[0].selectize.setValue(ids)

  checkTypedCharacter: (e) ->
    regex = new RegExp("^[a-zA-Z0-9]+$")
    str = String.fromCharCode((if not e.charCode then e.which else e.charCode))
    return true  if regex.test(str)
    e.preventDefault()
    return false

  setLifespanTime: (e) ->
    target = $(e.currentTarget)
    current = new Date();
    switch target.data('timespan')
      when '1week'
        current.setDate(current.getDate()+7);
      when '1month'
        current.setMonth(current.getMonth()+1);
      when '3months'
        current.setMonth(current.getMonth()+3);
      when '6months'
        current.setMonth(current.getMonth()+6);

    @$(".lifespan-time-sub-section input.date-field").val(current.strftime('%d-%m-%Y'))
    @$(".lifespan-time-sub-section input.time-field").val(current.strftime('%H:%M'))
    @$(".lifespan-time-sub-section input.time-field").trigger('change')

  generateRandomCoupon: (e) ->
    return if @$("#coupon_code").is(':disabled')

    code = ""
    code += Math.random().toString(36).substr(2) while code.length < 12
    code.substr 0, 12
    code = code.toUpperCase()
    @$("#coupon_code").val(code)

  changedCouponStart: (e) ->
    value = @$("input:radio[name='coupon[start_type]']:checked").val()

    switch value
      when "now"
        @$('.start-scheduled-sub-section').hide()
      when "scheduled"
        @$('.start-scheduled-sub-section').show()

  changedRequirements: (e) ->
    value = @$("input:radio[name='coupon[requirements]']:checked").val()

    switch value
      when "none"
        @$('.requirements-minimum-value-sub-section').hide()
        @$('.requirements-minimum-amount-sub-section').hide()
      when "minimum_value"
        @$('.requirements-minimum-value-sub-section').show()
        @$('.requirements-minimum-amount-sub-section').hide()
      when "minimum_amount"
        @$('.requirements-minimum-value-sub-section').hide()
        @$('.requirements-minimum-amount-sub-section').show()

  changedConstraints: (e) ->
    value = @$("input:radio[name='coupon[constraints]']:checked").val()

    switch value
      when "none"
        @$('.constraints-collection-sub-section').hide()
        @$('.constraints-product-sub-section').hide()
        @$('.constraints-product-type-sub-section').hide()
      when "collection"
        @$('.constraints-collection-sub-section').show()
        @$('.constraints-product-sub-section').hide()
        @$('.constraints-product-type-sub-section').hide()
      when "product_type"
        @$('.constraints-collection-sub-section').hide()
        @$('.constraints-product-sub-section').hide()
        @$('.constraints-product-type-sub-section').show()
      when "product"
        @$('.constraints-collection-sub-section').hide()
        @$('.constraints-product-sub-section').show()
        @$('.constraints-product-type-sub-section').hide()

  changedCouponLifespan: (e) ->
    value = @$("input:radio[name='coupon[lifespan]']:checked").val()

    switch value
      when "forever"
        @$('.lifespan-time-sub-section').hide()
        @$('.lifespan-amount-sub-section').hide()
      when "limited_time"
        @$('.lifespan-time-sub-section').show()
        @$('.lifespan-amount-sub-section').hide()
      when "limited_amount"
        @$('.lifespan-time-sub-section').hide()
        @$('.lifespan-amount-sub-section').show()

  changedCouponType: (e) ->
    value = @$("input:radio[name='coupon[coupon_type]']:checked").val()

    switch value
      when "fixed"
        @$('.customer-select-section').hide()
        @$('.coupon-percentage-section').hide()
        @$('.coupon-amount-section').show()
        @$('.coupon-code-wrapper').show()
        @$('.coupon_start_type').show()
        @$('.coupon-free-quantity-section').hide()
        @$('.coupon-include-free-shipping').show()
      when "percentage"
        @$('.customer-select-section').hide()
        @$('.coupon-percentage-section').show()
        @$('.coupon-amount-section').hide()
        @$('.coupon-code-wrapper').show()
        @$('.coupon_start_type').show()
        @$('.coupon-free-quantity-section').hide()
        @$('.coupon-include-free-shipping').show()
      when "credits"
        @$('.customer-select-section').show()
        @$('.coupon-percentage-section').hide()
        @$('.coupon-amount-section').show()
        @$('.coupon-code-wrapper').hide()
        @$('.coupon_start_type').hide()
        @$('.coupon-free-quantity-section').hide()
        @$('.coupon-include-free-shipping').hide()
      when "free_shipping"
        @$('.customer-select-section').hide()
        @$('.coupon-percentage-section').hide()
        @$('.coupon-amount-section').hide()
        @$('.coupon-code-wrapper').show()
        @$('.coupon_start_type').show()
        @$('.coupon-free-quantity-section').hide()
        @$('.coupon-include-free-shipping').hide()
      when "free_quantity"
        @$('.customer-select-section').hide()
        @$('.coupon-percentage-section').hide()
        @$('.coupon-amount-section').hide()
        @$('.coupon-code-wrapper').show()
        @$('.coupon_start_type').show()
        @$('.coupon-free-quantity-section').show()
        @$('.coupon-include-free-shipping').show()

  initPriceWatch: ->
    $(document).on "change", "input.price", ->
      Nimbu.formatPriceField($(this))

  initCustomerSelector: ->
    options = []
    ids = []

    @$('#coupon_customer_id option').each (i, el) ->
      options.push($(el).data('data'))

    @$('#coupon_customer_id').selectize
      theme: "customers"
      valueField: "id"
      labelField: "name"
      searchField: ["name","email"]
      options: options
      create: false

      render:
        option: (item, escape) ->
          '<div class="customer-wrapper" data-json=\'' + JSON.stringify(item) + '\'>' +
            '<span class="title">' +
              '<span class="name">' + escape(item.name) + '</span>' +
            '</span>' +
            '<span class="email">' + item.email + '</span>' +
          '</div>';

      load: (query, callback) ->
        return callback()  unless query.length
        $.getJSON($('#coupon_customer_id').data('search-url'), search: query, (res) ->
            callback res
          )
